p:not([class]) {
  // margin: 1em 0;
  margin-bottom: 1em;
}
/* --- */
/* SVG */
/* --- */
// Test
@mixin svg($width) {
  width: #{$width}px;
  max-width: calc(#{$width} * 100vw / 1920);

  @media screen and (max-width: 768px) {
    min-width: calc(#{strip-unit($width / 1.5px)}px);
    max-width: calc(#{$width} * 100vw / 768);
  }
}

/* ------ */
/* Center */
/* ------ */
@mixin center($position) {
  @if $position == 'vertical' {
    top: 50%;
    transform: translateY(-50%);
  } @else if $position == 'horizontal' {
    left: 50%;
    transform: translate(-50%);
  } @else if $position == 'flex' {
    display: flex;
    justify-content: center;
    align-items: center;
  } @else if $position == 'both' {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* --- */
/* IMG */
/* --- */
@mixin img($min, $max, $type: width) {
  $min-vw: 320px;
  $max-vw: 1920px;

  & {
    #{$type}: $min;

    @media screen and (min-width: $min-vw) {
      #{$type}: calc(
        #{$min} + #{strip-unit($max - $min)} *
          ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
      );
    }

    @media screen and (min-width: 1920px) {
      #{$type}: $max;
    }
  }
}

/* ------ */
/* Pseudo */
/* ------ */
@mixin pseudo($pos: absolute, $content: '') {
  content: $content;
  position: $pos;
}

/* ------ */
/* Button */
/* ------ */
button,
input,
a {
  &:focus {
    outline: none !important;
  }
}

/* ------ */
/* +1920px */
/* ------ */
.max-screen {
  @media screen and (min-width: 1920px) {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* -------- */
/* Blow-svg */
/* -------- */
.blob-svg {
  position: absolute;
  top: 150px;
  right: -25px;
  width: 100%;
  z-index: -1;

  @media screen and (min-width: 414px) {
    right: -50px;
    width: 70%;
  }

  @media screen and (min-width: 992px) {
    top: -50px;
    right: 200px;
    width: 60vw;
  }

  &-content {
    width: 100%;
    height: 100%;
    fill: #fff3ed;
    transform: scale(1.3);

    @media screen and (min-width: 1920px) {
      max-width: 1300px;
    }
  }
}

/* ------ */
/* Swiper */
/* ------ */
// Buttons
.swiper-button-prev,
.swiper-button-next {
  background-color: transparent;
  width: 110px;
  height: 70px;
  z-index: 8;

  span {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url('../../images/svg/right-arrow-big.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: opacity 0.6s;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    opacity: 0;
    background-image: url('../../images/svg/right-arrow-big--cream.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: opacity 0.6s;
  }

  // &::after {
  //   display: none;
  // }

  &:focus {
    outline: none !important;
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    span {
      opacity: 0;

      // width: 100%;
      // height: 100%;
      // background-image: url('../../images/svg/right-arrow-big--cream.svg');
      // background-repeat: no-repeat;
      // background-position: center;
      // background-size: contain;
    }
  }
}

.swiper-button-prev {
  span,
  &::after {
    transform: rotate(180deg) translateZ(0);
  }
}

//Pagination
.swiper-pagination {
  display: flex;

  &-bullet {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
    position: relative;
    opacity: 1;
    margin-right: 5px;
    background: url('../../images/svg/pagination.svg');
    background-size: cover;
    background-repeat: no-repeat;

    &:nth-child(3n) {
      transform: rotate(-45deg);
    }

    &:nth-child(3n + 1) {
      transform: rotate(30deg);
    }

    &:focus {
      outline: none !important;
    }

    &::after {
      @include pseudo();
      @include center('both');

      width: 10px;
      height: 10px;
      opacity: 0;
      background: url('../../images/svg/pagination-active.svg');
      background-size: contain;
      background-repeat: no-repeat;

      transition: opacity 0.3s ease-in;
    }

    &-active {
      &::after {
        opacity: 1;
      }
    }
  }
}
