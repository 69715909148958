.recomend {
  overflow: hidden;

  &__text {
    @include flex-column-center;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 30px;

    @include mq(bp-992, max) {
      row-gap: 50px;
    }

    @include mq(bp-768, max) {
      max-width: 500px;
      margin-right: auto;
      margin-left: auto;
    }

    @include mq(bp-768) {
      column-gap: 40px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq(bp-992) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mq(bp-1366) {
      column-gap: 60px;
    }
  }

  &__box {
    @include mq(bp-768, max) {
      @include flex-column-center;
    }

    &-picture {
      display: flex;
      max-height: 300px;
      height: 100%;
      width: 100%;

      @include mq(bp-1366) {
        max-height: 320px;
      }
    }

    &-image {
      width: 100%;
      object-fit: cover;
      padding: 0 !important;
    }

    &-title {
      @include fontr(24px, 30px, 700);
      @include margin(20, 30, 'bottom');

      font-family: $base-font;
      line-height: 1.2;
      color: $black;
      text-align: left;
      margin-top: 15px;
      padding: 0 !important;

      @include mq(bp-768, max) {
        text-align: center;
        max-width: 80%;
      }
    }
  }
}
