/*****************/
/* ~*~ Form ~*~ */
/****************/
.application-form {
    @include padding(50, 70, "top");
    @include padding(50, 70, "bottom");
    @include margin(0, 0, "bottom");

    background-color: $gray;

    .small-container {
        .form-wrapper {
            padding-right: 0;
        }

        .application-form__bg {
            @include mq(bp-992) {
                transform: translate(20%, -55%);
            }

            @include mq(bp-1366) {
                transform: translate(40%, -50%);
            }

            @include mq(bp-1536) {
                transform: translate(50%, -50%);
            }
        }
    }

    &__text {
        @include flex-column-center;
    }

    &__wrapper {
        @include margin(20, 25, "top");

        position: relative;

        @include mq(bp-992, max) {
            max-width: 700px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    &__bg {
        position: absolute;
        display: none;
        right: 0;
        top: 0;
        max-width: 250px;

        @include mq(bp-992) {
            display: flex;
            transform: translate(15%, -15%);
        }

        @include mq(bp-1366) {
            max-width: 280px;
            transform: translate(20%, -20%);
        }

        @include mq(bp-1536) {
            max-width: 320px;
            transform: translate(25%, -25%);
        }

        svg {
            width: 100%;
            height: auto;
        }
    }
}
