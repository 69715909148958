.contact-form {
    //Form
    &-row {
        @include flex-column-center;
    }

    &-column {
        @include margin(30, 40, bottom);

        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;

        @include mq(bp-1366) {
            grid-gap: 30px;
        }
    }

    &__sent {
        @include flex-column-center;
        @include padding(15, 30, left);
        @include padding(15, 30, right);

        color: $black;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $gray;
        z-index: 3;
        visibility: hidden;
        opacity: 0;
        transform: translateY(-100%);
        transition: opacity 1s, transform 0.6s;

        &-title {
            text-align: center !important;
        }

        &-description {
            @include margin(10, 15, top);

            text-align: center !important;
            line-height: 1.5;
            letter-spacing: 2px !important;

            @include mq(bp-414, max) {
                max-width: 200px;
            }
        }

        &.active {
            // top: 0;
            opacity: 1;
            transform: translateY(0);
            visibility: visible;
        }
    }
}

.form {
    position: relative;

    .form-wrapper {
        display: grid;
        grid-row-gap: 20px;

        @include mq(bp-992) {
            grid-template-columns: 1.7fr 1fr 1fr;
            padding-right: 10%;
            grid-column-gap: 20px;
            grid-row-gap: initial;
        }

        @include mq(bp-1366) {
            padding-right: 20%;
            grid-column-gap: 30px;
        }
    }

    .checkbox-group {
        @include letter-spacing(15);

        line-height: 20px;
        display: flex;
        align-items: flex-start;
        // color: rgba($color: #737780, $alpha: 0.5);

        * {
            cursor: pointer;
        }

        .wpcf7-not-valid-tip {
            position: unset;
            padding-left: 30px;
            cursor: text;

            @include mq(bp-414, max) {
                padding-right: 30px;
                text-align: center;
            }
        }
    }

    .wpcf7 form.sent .wpcf7-response-output {
        display: none !important;
        // border: none;
        // background-color: $blue;
        // text-align: center;
        // color: $white;
        // font-weight: 400;
        // padding: 15px 20px;

        // @include mq(bp-992) {
        //   margin: 0;
        //   width: 100%;
        //   margin-top: 20px;
        // }
    }

    input[type="email"]:focus,
    input[type="text"]:focus,
    input[type="tel"]:focus,
    textarea:focus {
        outline: none;
        box-shadow: 0 0 3px $cream;
        outline-offset: 0;
        transition: box-shadow 0.6s;
    }

    .wpcf7-list-item-label {
        white-space: pre-wrap;
    }

    .wpcf7 form.invalid .wpcf7-response-output,
    .wpcf7 form.unaccepted .wpcf7-response-output {
        background-color: $blue;
        border: none;
        color: $white;
        text-align: center;
        color: $white;
        font-weight: 400;
        padding: 15px 20px;

        @include mq(bp-992) {
            margin: 0;
            width: 100%;
            margin-top: 20px;
        }
    }

    .wpcf7 form .wpcf7-response-output {
        @include margin(20, 30, top);
        @include fontr(12px, 14px, 700);

        margin-left: 0;
        margin-right: 0;
        padding: 15px 20px;
        text-align: center;
    }

    .wpcf7 {
        font-family: $second-font;
        opacity: 1 !important;
        visibility: visible !important;
        position: relative;

        br,
        p {
            // color: red;
            // display: none;
        }

        &-form-control-wrap {
            position: unset;
        }
        &-textarea {
            min-height: 250px;
            resize: none;
            max-height: 300px;
        }

        &-not-valid-tip {
            // @include padding(12, 20, left);
            // @include padding(12, 20, right);
            // @include fontr(10px, 12px);
            // @include lineh(12px, 16px);

            // padding-top: 1px;
            // padding-bottom: 1px;
            // bottom: 0;
            // left: 0;
            // width: 100%;
            // background-color: $navyblue;
            // color: $base-white;

            display: none;
        }

        .wpcf7-not-valid {
            box-shadow: 0 0 3px red;

            &.wpcf7-acceptance {
                box-shadow: none;

                .wpcf7-list-item-label::before {
                    box-shadow: 0 0 3px red;
                }
            }
        }

        &-list-item {
            label {
                display: flex;
            }

            &-label {
                @include fontr(11px, 13px, 400);

                margin-left: 8px;
                color: #8b8b8b;

                &::before {
                    @include pseudo();

                    display: block;
                    height: 20px;
                    width: 20px;
                    left: 0;
                    top: 3px;
                    background-color: #fff;
                }

                &::after {
                    @include pseudo();

                    display: block;
                    height: 6px;
                    width: 6px;
                    top: 10px;
                    left: 7px;
                    visibility: hidden;
                    opacity: 0;
                    font-size: 16px;
                    background-color: $cream;
                    transition: opacity 0.3s ease;
                }
            }
        }

        &-form input[type="checkbox"] {
            position: relative;
            visibility: hidden;

            &:checked + span::after {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .form-group {
        position: relative;

        &--border {
            display: inline-flex;

            .select-training {
                @include mq(bp-992, max) {
                    width: 100%;
                }
            }

            .form-input {
                text-overflow: ellipsis;

                &--filled {
                    box-shadow: none;
                    color: #8b8b8b;
                }

                &:focus {
                    box-shadow: none;
                }
            }

            label {
                opacity: 0;
            }

            .form-input {
                background-image: none !important;
                background: transparent;
                border: 1px solid $white;
            }
        }
    }

    .form-label {
        @include fontr(14px, 18px, 400);
        @include lineh(18px, 22px);
        @include letter-spacing(50);

        position: absolute;
        padding: 0 5px;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        background-color: inherit;
        color: #8b8b8b;
        cursor: text;
        z-index: 2;
        transition: all 0.3s;
        will-change: all;
        pointer-events: none;

        @media not all and (min-resolution: 0.001dpcm) {
            @supports (-webkit-appearance: none) and (stroke-color: transparent) {
                transition: top 150ms ease-out;
            }
        }

        @supports (-moz-appearance: none) {
            transition: top 150ms ease-out;
        }

        @include mq(bp-768, max) {
            left: 10px;
        }

        &--focused {
            @include fontr(12px, 13px, 400);

            top: 0;
            transform: translateY(-50%);
            background-color: $cream;
            color: #fff;
        }

        &.message-label {
            @include margin(8, 17, top);

            top: 0;
            transform: translateY(0);
            transition: all 0.3s;
            will-change: all;

            @media not all and (min-resolution: 0.001dpcm) {
                @supports (-webkit-appearance: none) and
                    (stroke-color: transparent) {
                    transition: margin 150ms ease-out;
                }
            }

            @supports (-moz-appearance: none) {
                transition: margin 150ms ease-out;
            }

            &.form-label--focused {
                margin: 0;
                transform: translateY(-50%);
            }
        }
    }

    .form-input {
        @include fontr(14px, 18px, 400);
        @include lineh(27px, 30px);

        font-family: $second-font;
        padding: 13px 20px;
        width: 100%;
        height: auto;
        border: 0;
        background-color: $white;
        color: #8b8b8b;

        &--filled {
            box-shadow: 0 0 3px $cream;
            color: $cream;
        }
    }

    textarea.form-input {
        height: auto;
    }

    span.wpcf7-list-item {
        display: inline-block;
        margin: 0 0 0 2em;
    }

    .input__submit {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        outline: none !important;
        background-color: transparent;
        color: transparent;
        z-index: 1;
        cursor: pointer;
    }

    div.wpcf7 .contact-form__button .ajax-loader {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        padding: 0;
        margin-left: 10px;
        background-image: none;

        &::before {
            @include pseudo();

            width: 8px;
            height: 8px;
            top: 0;
            left: 0;
            transform: translate(0, 0);
            background-color: $blue;
            animation: loader-animation 1.5s linear infinite;
        }

        &::after {
            @include pseudo();

            width: 8px;
            height: 8px;
            top: 0;
            left: 0;
            transform: translate(0, 0);
            background-color: $cream;
            animation: loader-animation 1.5s 0.75s linear infinite;
        }
    }
}
