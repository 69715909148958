@font-face{
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 400;
  src: url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_qiTXt_A_Q.eot?#) format("eot"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_qiTXtHA-Q.woff2) format("woff2"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_qiTXt_A_w.woff) format("woff");
}
@font-face{
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 500;
  src: url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_pqTXt_A_Q.eot?#) format("eot"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_pqTXtHA-Q.woff2) format("woff2"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_pqTXt_A_w.woff) format("woff");
}
@font-face{
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 600;
  src: url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_naUXt_A_Q.eot?#) format("eot"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_naUXtHA-Q.woff2) format("woff2"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_naUXt_A_w.woff) format("woff");
}
@font-face{
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 700;
  src: url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_k-UXt_A_Q.eot?#) format("eot"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_k-UXtHA-Q.woff2) format("woff2"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_k-UXt_A_w.woff) format("woff");
}
@font-face{
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 800;
  src: url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_iiUXt_A_Q.eot?#) format("eot"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_iiUXtHA-Q.woff2) format("woff2"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_iiUXt_A_w.woff) format("woff");
}
@font-face{
  font-family: "Playfair Display";
  font-style: italic;
  font-weight: 900;
  src: url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_gGUXt_A_Q.eot?#) format("eot"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_gGUXtHA-Q.woff2) format("woff2"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_gGUXt_A_w.woff) format("woff");
}
@font-face{
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDZbtc.eot?#) format("eot"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDXbtM.woff2) format("woff2"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvXDZbtU.woff) format("woff");
}
@font-face{
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKd3vXDZbtc.eot?#) format("eot"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKd3vXDXbtM.woff2) format("woff2"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKd3vXDZbtU.woff) format("woff");
}
@font-face{
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 600;
  src: url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebunDZbtc.eot?#) format("eot"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebunDXbtM.woff2) format("woff2"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebunDZbtU.woff) format("woff");
}
@font-face{
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKeiunDZbtc.eot?#) format("eot"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKeiunDXbtM.woff2) format("woff2"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKeiunDZbtU.woff) format("woff");
}
@font-face{
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 800;
  src: url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFunDZbtc.eot?#) format("eot"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFunDXbtM.woff2) format("woff2"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFunDZbtU.woff) format("woff");
}
@font-face{
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 900;
  src: url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfsunDZbtc.eot?#) format("eot"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfsunDXbtM.woff2) format("woff2"),url(//fonts.gstatic.com/s/playfairdisplay/v36/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfsunDZbtU.woff) format("woff");
}
@font-face{
  font-family: Lato;
  font-style: italic;
  font-weight: 100;
  src: url(//fonts.gstatic.com/s/lato/v24/S6u-w4BMUTPHjxsIPx-mPCY.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v24/S6u-w4BMUTPHjxsIPx-oPCI.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v24/S6u-w4BMUTPHjxsIPx-mPCQ.woff) format("woff");
}
@font-face{
  font-family: Lato;
  font-style: italic;
  font-weight: 300;
  src: url(//fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI9w2_FQfp.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI9w2_Gwft.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI9w2_FQfr.woff) format("woff");
}
@font-face{
  font-family: Lato;
  font-style: italic;
  font-weight: 400;
  src: url(//fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-u.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-q.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-s.woff) format("woff");
}
@font-face{
  font-family: Lato;
  font-style: italic;
  font-weight: 700;
  src: url(//fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQfp.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwft.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQfr.woff) format("woff");
}
@font-face{
  font-family: Lato;
  font-style: italic;
  font-weight: 900;
  src: url(//fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI3wi_FQfp.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI3wi_Gwft.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI3wi_FQfr.woff) format("woff");
}
@font-face{
  font-family: Lato;
  font-style: normal;
  font-weight: 100;
  src: url(//fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHh30AUi-u.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHh30AXC-q.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHh30AUi-s.woff) format("woff");
}
@font-face{
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  src: url(//fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh7USSwaPHQ.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh7USSwiPGQ.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh7USSwaPHw.woff) format("woff");
}
@font-face{
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  src: url(//fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwWg.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXg.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwWA.woff) format("woff");
}
@font-face{
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPHQ.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPHw.woff) format("woff");
}
@font-face{
  font-family: Lato;
  font-style: normal;
  font-weight: 900;
  src: url(//fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPHQ.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPHw.woff) format("woff");
}
/******************/
/* ~*~ Config ~*~ */
/******************/
@import "~tippy.js/dist/tippy.css";
@import "~mapbox-gl/dist/mapbox-gl.css";
@import "~swiper/swiper-bundle.css";
@import "~aos/dist/aos.css";
@import "/wp-includes/css/dist/block-library/style.css";
*,
*::before,
*::after {
  box-sizing: border-box;
  background-repeat: no-repeat;
  /* Safari */
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}

*::-moz-selection {
  color: #fff;
  background-color: #e6bca5;
}

*::selection {
  color: #fff;
  background-color: #e6bca5;
}

html {
  font-size: 62.5%;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
}

body {
  margin: 0;
  overflow: hidden;
  text-rendering: optimizeSpeed;
}

main {
  display: block;
}

p,
table,
blockquote,
address,
pre,
iframe,
form,
figure,
dl {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

iframe {
  border: 0;
}

address {
  font-style: inherit;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
}

button {
  border: none;
  background-color: initial;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

/******************/
/* ~*~ NPM ~*~ */
/******************/
/******************/
/* ~*~ Global ~*~ */
/******************/
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/******************/
/* ~*~ Mixins ~*~ */
/******************/
p:not([class]) {
  margin-bottom: 1em;
}

/* --- */
/* SVG */
/* --- */
/* ------ */
/* Center */
/* ------ */
/* --- */
/* IMG */
/* --- */
/* ------ */
/* Pseudo */
/* ------ */
/* ------ */
/* Button */
/* ------ */
button:focus,
input:focus,
a:focus {
  outline: none !important;
}

/* ------ */
/* +1920px */
/* ------ */
@media screen and (min-width: 1920px) {
  .max-screen {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* -------- */
/* Blow-svg */
/* -------- */
.blob-svg {
  position: absolute;
  top: 150px;
  right: -25px;
  width: 100%;
  z-index: -1;
}
@media screen and (min-width: 414px) {
  .blob-svg {
    right: -50px;
    width: 70%;
  }
}
@media screen and (min-width: 992px) {
  .blob-svg {
    top: -50px;
    right: 200px;
    width: 60vw;
  }
}
.blob-svg-content {
  width: 100%;
  height: 100%;
  fill: #fff3ed;
  transform: scale(1.3);
}
@media screen and (min-width: 1920px) {
  .blob-svg-content {
    max-width: 1300px;
  }
}

/* ------ */
/* Swiper */
/* ------ */
.swiper-button-prev,
.swiper-button-next {
  background-color: transparent;
  width: 110px;
  height: 70px;
  z-index: 8;
}
.swiper-button-prev span,
.swiper-button-next span {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../images/svg/right-arrow-big.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: opacity 0.6s;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  opacity: 0;
  background-image: url("../../images/svg/right-arrow-big--cream.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: opacity 0.6s;
}
.swiper-button-prev:focus,
.swiper-button-next:focus {
  outline: none !important;
}
.swiper-button-prev:hover::after,
.swiper-button-next:hover::after {
  opacity: 1;
}
.swiper-button-prev:hover span,
.swiper-button-next:hover span {
  opacity: 0;
}

.swiper-button-prev span, .swiper-button-prev::after {
  transform: rotate(180deg) translateZ(0);
}

.swiper-pagination {
  display: flex;
}
.swiper-pagination-bullet {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  position: relative;
  opacity: 1;
  margin-right: 5px;
  background: url("../../images/svg/pagination.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.swiper-pagination-bullet:nth-child(3n) {
  transform: rotate(-45deg);
}
.swiper-pagination-bullet:nth-child(3n+1) {
  transform: rotate(30deg);
}
.swiper-pagination-bullet:focus {
  outline: none !important;
}
.swiper-pagination-bullet::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  opacity: 0;
  background: url("../../images/svg/pagination-active.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transition: opacity 0.3s ease-in;
}
.swiper-pagination-bullet-active::after {
  opacity: 1;
}

/**********************************************************/
.row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}
.row-reverse {
  flex-direction: row-reverse;
}

.col {
  flex: 0 0 auto;
}

.col-xs-1 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-xs-2 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-xs-5 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-xs-8 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-xs-11 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

@media only screen and (min-width: 576px) {
  .col-sm-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media only screen and (min-width: 992px) {
  .col-lg-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media only screen and (min-width: 1366px) {
  .col-xl-1 {
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex-basis: 100%;
    max-width: 100%;
  }
}
/**********************/
/* ~*~ Font size ~*~ */
/*********************/
/***********************/
/* ~*~ Line height ~*~ */
/***********************/
/*********************/
/* ~*~ Tracking ~*~ */
/********************/
.container, .regular-text h1,
.regular-text h2,
.regular-text h3,
.regular-text h4,
.regular-text h5,
.regular-text h6,
.regular-text p,
.regular-text pre,
.regular-text ul,
.regular-text ol,
.regular-text img,
.regular-text .wp-block-quote,
.regular-text .wp-block-columns,
.regular-text .wp-block-group {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container, .regular-text h1,
.regular-text h2,
.regular-text h3,
.regular-text h4,
.regular-text h5,
.regular-text h6,
.regular-text p,
.regular-text pre,
.regular-text ul,
.regular-text ol,
.regular-text img,
.regular-text .wp-block-quote,
.regular-text .wp-block-columns,
.regular-text .wp-block-group {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media (min-width: 992px) {
  .container, .regular-text h1,
.regular-text h2,
.regular-text h3,
.regular-text h4,
.regular-text h5,
.regular-text h6,
.regular-text p,
.regular-text pre,
.regular-text ul,
.regular-text ol,
.regular-text img,
.regular-text .wp-block-quote,
.regular-text .wp-block-columns,
.regular-text .wp-block-group {
    max-width: 960px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .container, .regular-text h1,
.regular-text h2,
.regular-text h3,
.regular-text h4,
.regular-text h5,
.regular-text h6,
.regular-text p,
.regular-text pre,
.regular-text ul,
.regular-text ol,
.regular-text img,
.regular-text .wp-block-quote,
.regular-text .wp-block-columns,
.regular-text .wp-block-group {
    max-width: 1140px;
  }
}
@media (min-width: 1336px) {
  .container, .regular-text h1,
.regular-text h2,
.regular-text h3,
.regular-text h4,
.regular-text h5,
.regular-text h6,
.regular-text p,
.regular-text pre,
.regular-text ul,
.regular-text ol,
.regular-text img,
.regular-text .wp-block-quote,
.regular-text .wp-block-columns,
.regular-text .wp-block-group {
    max-width: 1200px;
  }
}
@media (min-width: 1440px) {
  .container, .regular-text h1,
.regular-text h2,
.regular-text h3,
.regular-text h4,
.regular-text h5,
.regular-text h6,
.regular-text p,
.regular-text pre,
.regular-text ul,
.regular-text ol,
.regular-text img,
.regular-text .wp-block-quote,
.regular-text .wp-block-columns,
.regular-text .wp-block-group {
    max-width: 1366px;
  }
}
@media (min-width: 1900px) {
  .container, .regular-text h1,
.regular-text h2,
.regular-text h3,
.regular-text h4,
.regular-text h5,
.regular-text h6,
.regular-text p,
.regular-text pre,
.regular-text ul,
.regular-text ol,
.regular-text img,
.regular-text .wp-block-quote,
.regular-text .wp-block-columns,
.regular-text .wp-block-group {
    max-width: 1400px;
  }
}

.container-xs {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container-xs {
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media (min-width: 992px) {
  .container-xs {
    max-width: 960px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1336px) {
  .container-xs {
    max-width: 1000px;
  }
}
@media (min-width: 1440px) {
  .container-xs {
    max-width: 1100px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-fluid {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-fluid {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-fluid {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-fluid {
    max-width: 1140px;
  }
}
@media (min-width: 1336px) {
  .container-fluid {
    max-width: 1280px;
  }
}
@media (min-width: 1440px) {
  .container-fluid {
    max-width: 1380px;
  }
}
@media (min-width: 1900px) {
  .container-fluid {
    max-width: 1640px;
  }
}

.padding-xl {
  padding-bottom: 80px;
}
@media screen and (min-width: 320px) {
  .padding-xl {
    padding-bottom: calc( 80px + (170 - 80) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .padding-xl {
    padding-bottom: 170px;
  }
}

.padding-md {
  padding-bottom: 70px;
}
@media screen and (min-width: 320px) {
  .padding-md {
    padding-bottom: calc( 70px + (140 - 70) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .padding-md {
    padding-bottom: 140px;
  }
}

.padding-xs {
  padding-bottom: 50px;
}
@media screen and (min-width: 320px) {
  .padding-xs {
    padding-bottom: calc( 50px + (110 - 50) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .padding-xs {
    padding-bottom: 110px;
  }
}

.global-list ul {
  text-align: left;
}
.global-list ul li {
  line-height: 1.4;
  position: relative;
  color: #8b8b8b;
  font-family: "Lato", sans-serif;
}
.global-list ul li {
  font-size: 13px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .global-list ul li {
    font-size: calc( 13px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .global-list ul li {
    font-size: 15px;
  }
}
.global-list ul li {
  margin-bottom: 5px;
}
@media screen and (min-width: 320px) {
  .global-list ul li {
    margin-bottom: calc( 5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .global-list ul li {
    margin-bottom: 10px;
  }
}
.global-list ul li {
  padding-left: 16px;
}
@media screen and (min-width: 320px) {
  .global-list ul li {
    padding-left: calc( 16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .global-list ul li {
    padding-left: 18px;
  }
}
.global-list ul li::before {
  content: "";
  position: absolute;
  top: 0.4em;
  left: 0;
  width: 9px;
  height: 9px;
  background: url("../../images/svg/list-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* ----- */
/* Bradcrumbs  */
/* ----- */
.main-breadcrumbs {
  letter-spacing: 0.05em;
  font-size: 13px;
  text-align: center;
  color: #000;
  z-index: 101;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: "Lato", sans-serif;
}
@media screen and (max-width: 991px) {
  .main-breadcrumbs {
    display: none;
  }
}
.main-breadcrumbs a {
  display: inline-flex;
  align-items: center;
  margin-right: 15px;
  transition: color 0.3s;
}
.main-breadcrumbs a:hover {
  color: #aec4d4;
}
.main-breadcrumbs a::after {
  content: "";
  display: inline-flex;
  width: 10px;
  height: 10px;
  margin-left: 15px;
  background-image: url("../../images/svg/separator.svg");
  background-size: contain;
  background-position: center;
}
.main-breadcrumbs .current-item {
  opacity: 0.7;
}

/* ------------ */
/* Global title */
/* ----------- */
.global__title {
  text-align: center !important;
  display: flex;
  justify-content: center;
}
.global__title {
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .global__title {
    margin-top: calc( 30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .global__title {
    margin-top: 50px;
  }
}

.contact-form-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact-form-column {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.contact-form-column {
  margin-bottom: 30px;
}
@media screen and (min-width: 320px) {
  .contact-form-column {
    margin-bottom: calc( 30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .contact-form-column {
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1366px) {
  .contact-form-column {
    grid-gap: 30px;
  }
}
.contact-form__sent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #dde6ed;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 1s, transform 0.6s;
}
.contact-form__sent {
  padding-left: 15px;
}
@media screen and (min-width: 320px) {
  .contact-form__sent {
    padding-left: calc( 15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .contact-form__sent {
    padding-left: 30px;
  }
}
.contact-form__sent {
  padding-right: 15px;
}
@media screen and (min-width: 320px) {
  .contact-form__sent {
    padding-right: calc( 15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .contact-form__sent {
    padding-right: 30px;
  }
}
.contact-form__sent-title {
  text-align: center !important;
}
.contact-form__sent-description {
  text-align: center !important;
  line-height: 1.5;
  letter-spacing: 2px !important;
}
.contact-form__sent-description {
  margin-top: 10px;
}
@media screen and (min-width: 320px) {
  .contact-form__sent-description {
    margin-top: calc( 10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .contact-form__sent-description {
    margin-top: 15px;
  }
}
@media screen and (max-width: 413px) {
  .contact-form__sent-description {
    max-width: 200px;
  }
}
.contact-form__sent.active {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.form {
  position: relative;
}
.form .form-wrapper {
  display: grid;
  grid-row-gap: 20px;
}
@media screen and (min-width: 992px) {
  .form .form-wrapper {
    grid-template-columns: 1.7fr 1fr 1fr;
    padding-right: 10%;
    grid-column-gap: 20px;
    grid-row-gap: initial;
  }
}
@media screen and (min-width: 1366px) {
  .form .form-wrapper {
    padding-right: 20%;
    grid-column-gap: 30px;
  }
}
.form .checkbox-group {
  letter-spacing: 0.015em;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
}
.form .checkbox-group * {
  cursor: pointer;
}
.form .checkbox-group .wpcf7-not-valid-tip {
  position: unset;
  padding-left: 30px;
  cursor: text;
}
@media screen and (max-width: 413px) {
  .form .checkbox-group .wpcf7-not-valid-tip {
    padding-right: 30px;
    text-align: center;
  }
}
.form .wpcf7 form.sent .wpcf7-response-output {
  display: none !important;
}
.form input[type=email]:focus,
.form input[type=text]:focus,
.form input[type=tel]:focus,
.form textarea:focus {
  outline: none;
  box-shadow: 0 0 3px #f0cbbb;
  outline-offset: 0;
  transition: box-shadow 0.6s;
}
.form .wpcf7-list-item-label {
  white-space: pre-wrap;
}
.form .wpcf7 form.invalid .wpcf7-response-output,
.form .wpcf7 form.unaccepted .wpcf7-response-output {
  background-color: #243c54;
  border: none;
  color: #fff;
  text-align: center;
  color: #fff;
  font-weight: 400;
  padding: 15px 20px;
}
@media screen and (min-width: 992px) {
  .form .wpcf7 form.invalid .wpcf7-response-output,
.form .wpcf7 form.unaccepted .wpcf7-response-output {
    margin: 0;
    width: 100%;
    margin-top: 20px;
  }
}
.form .wpcf7 form .wpcf7-response-output {
  margin-left: 0;
  margin-right: 0;
  padding: 15px 20px;
  text-align: center;
}
.form .wpcf7 form .wpcf7-response-output {
  margin-top: 20px;
}
@media screen and (min-width: 320px) {
  .form .wpcf7 form .wpcf7-response-output {
    margin-top: calc( 20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .form .wpcf7 form .wpcf7-response-output {
    margin-top: 30px;
  }
}
.form .wpcf7 form .wpcf7-response-output {
  font-size: 12px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .form .wpcf7 form .wpcf7-response-output {
    font-size: calc( 12px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .form .wpcf7 form .wpcf7-response-output {
    font-size: 14px;
  }
}
.form .wpcf7 {
  font-family: "Lato", sans-serif;
  opacity: 1 !important;
  visibility: visible !important;
  position: relative;
}
.form .wpcf7-form-control-wrap {
  position: unset;
}
.form .wpcf7-textarea {
  min-height: 250px;
  resize: none;
  max-height: 300px;
}
.form .wpcf7-not-valid-tip {
  display: none;
}
.form .wpcf7 .wpcf7-not-valid {
  box-shadow: 0 0 3px red;
}
.form .wpcf7 .wpcf7-not-valid.wpcf7-acceptance {
  box-shadow: none;
}
.form .wpcf7 .wpcf7-not-valid.wpcf7-acceptance .wpcf7-list-item-label::before {
  box-shadow: 0 0 3px red;
}
.form .wpcf7-list-item label {
  display: flex;
}
.form .wpcf7-list-item-label {
  margin-left: 8px;
  color: #8b8b8b;
}
.form .wpcf7-list-item-label {
  font-size: 11px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .form .wpcf7-list-item-label {
    font-size: calc( 11px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .form .wpcf7-list-item-label {
    font-size: 13px;
  }
}
.form .wpcf7-list-item-label::before {
  content: "";
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  left: 0;
  top: 3px;
  background-color: #fff;
}
.form .wpcf7-list-item-label::after {
  content: "";
  position: absolute;
  display: block;
  height: 6px;
  width: 6px;
  top: 10px;
  left: 7px;
  visibility: hidden;
  opacity: 0;
  font-size: 16px;
  background-color: #f0cbbb;
  transition: opacity 0.3s ease;
}
.form .wpcf7-form input[type=checkbox] {
  position: relative;
  visibility: hidden;
}
.form .wpcf7-form input[type=checkbox]:checked + span::after {
  visibility: visible;
  opacity: 1;
}
.form .form-group {
  position: relative;
}
.form .form-group--border {
  display: inline-flex;
}
@media screen and (max-width: 991px) {
  .form .form-group--border .select-training {
    width: 100%;
  }
}
.form .form-group--border .form-input {
  text-overflow: ellipsis;
}
.form .form-group--border .form-input--filled {
  box-shadow: none;
  color: #8b8b8b;
}
.form .form-group--border .form-input:focus {
  box-shadow: none;
}
.form .form-group--border label {
  opacity: 0;
}
.form .form-group--border .form-input {
  background-image: none !important;
  background: transparent;
  border: 1px solid #fff;
}
.form .form-label {
  letter-spacing: 0.05em;
  position: absolute;
  padding: 0 5px;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  background-color: inherit;
  color: #8b8b8b;
  cursor: text;
  z-index: 2;
  transition: all 0.3s;
  will-change: all;
  pointer-events: none;
}
.form .form-label {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .form .form-label {
    font-size: calc( 14px + 4 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .form .form-label {
    font-size: 18px;
  }
}
.form .form-label {
  line-height: 18px;
}
@media screen and (min-width: 320px) {
  .form .form-label {
    line-height: calc( 18px + 4 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .form .form-label {
    line-height: 22px;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .form .form-label {
      transition: top 150ms ease-out;
    }
  }
}
@supports (-moz-appearance: none) {
  .form .form-label {
    transition: top 150ms ease-out;
  }
}
@media screen and (max-width: 767px) {
  .form .form-label {
    left: 10px;
  }
}
.form .form-label--focused {
  top: 0;
  transform: translateY(-50%);
  background-color: #f0cbbb;
  color: #fff;
}
.form .form-label--focused {
  font-size: 12px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .form .form-label--focused {
    font-size: calc( 12px + 1 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .form .form-label--focused {
    font-size: 13px;
  }
}
.form .form-label.message-label {
  top: 0;
  transform: translateY(0);
  transition: all 0.3s;
  will-change: all;
}
.form .form-label.message-label {
  margin-top: 8px;
}
@media screen and (min-width: 320px) {
  .form .form-label.message-label {
    margin-top: calc( 8px + (17 - 8) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .form .form-label.message-label {
    margin-top: 17px;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .form .form-label.message-label {
      transition: margin 150ms ease-out;
    }
  }
}
@supports (-moz-appearance: none) {
  .form .form-label.message-label {
    transition: margin 150ms ease-out;
  }
}
.form .form-label.message-label.form-label--focused {
  margin: 0;
  transform: translateY(-50%);
}
.form .form-input {
  font-family: "Lato", sans-serif;
  padding: 13px 20px;
  width: 100%;
  height: auto;
  border: 0;
  background-color: #fff;
  color: #8b8b8b;
}
.form .form-input {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .form .form-input {
    font-size: calc( 14px + 4 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .form .form-input {
    font-size: 18px;
  }
}
.form .form-input {
  line-height: 27px;
}
@media screen and (min-width: 320px) {
  .form .form-input {
    line-height: calc( 27px + 3 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .form .form-input {
    line-height: 30px;
  }
}
.form .form-input--filled {
  box-shadow: 0 0 3px #f0cbbb;
  color: #f0cbbb;
}
.form textarea.form-input {
  height: auto;
}
.form span.wpcf7-list-item {
  display: inline-block;
  margin: 0 0 0 2em;
}
.form .input__submit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  outline: none !important;
  background-color: transparent;
  color: transparent;
  z-index: 1;
  cursor: pointer;
}
.form div.wpcf7 .contact-form__button .ajax-loader {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  padding: 0;
  margin-left: 10px;
  background-image: none;
}
.form div.wpcf7 .contact-form__button .ajax-loader::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  background-color: #243c54;
  animation: loader-animation 1.5s linear infinite;
}
.form div.wpcf7 .contact-form__button .ajax-loader::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  background-color: #f0cbbb;
  animation: loader-animation 1.5s 0.75s linear infinite;
}

/**********************/
/* ~*~ Components ~*~ */
/**********************/
.navigation {
  /* Main nav */
}
@media screen and (max-width: 991px) {
  .navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    pointer-events: none;
  }
}
.navigation .mobile-menu {
  display: none;
}
@media screen and (max-width: 991px) {
  .navigation .mobile-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    z-index: 1;
    width: 100%;
    padding: 12px 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    pointer-events: auto;
  }
  .navigation .mobile-menu__logo {
    max-width: 110px;
    width: 100%;
  }
}
.navigation .nav {
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: transparent;
}
@media screen and (max-width: 991px) {
  .navigation .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background-color: #fff;
    clip-path: circle(30px at calc(100% - 45px)-15px);
    visibility: hidden;
    pointer-events: auto;
    transition: clip-path 500ms ease-in-out, visibility 500ms ease-in-out;
  }
  .navigation .nav::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    transform: scale(2.5);
    background-image: url("../../images/svg/mobile-menu-bg.svg");
    background-size: cover;
    background-position: bottom left;
    transition: transform 0.6s;
    transition-delay: 0.2s;
  }
  .navigation .nav.is-active {
    visibility: visible;
    clip-path: circle(100%);
  }
  .navigation .nav.is-active::before {
    transform: scale(1);
  }
  .navigation .nav.is-active .nav__list-item {
    opacity: 1;
    transform: translateX(0);
  }
  .navigation .nav.is-active .call-us {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.4s;
  }
  .navigation .nav.is-active .nav__top-langs {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.4s;
  }
}
.navigation .nav__top {
  margin-bottom: 20px;
}
.navigation .nav__top {
  padding-left: 15px;
}
@media screen and (min-width: 320px) {
  .navigation .nav__top {
    padding-left: calc( 15px + (60 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .navigation .nav__top {
    padding-left: 60px;
  }
}
.navigation .nav__top {
  padding-right: 15px;
}
@media screen and (min-width: 320px) {
  .navigation .nav__top {
    padding-right: calc( 15px + (60 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .navigation .nav__top {
    padding-right: 60px;
  }
}
@media screen and (max-width: 991px) {
  .navigation .nav__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 992px) {
  .navigation .nav__top {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}
.navigation .nav__top .call-us {
  display: flex;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 991px) {
  .navigation .nav__top .call-us {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.5s, opacity 0.3s;
  }
}
.navigation .nav__top .call-us:hover .call-us__logo {
  background-color: #f0cbbb;
}
.navigation .nav__top .call-us__logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 7px;
  margin-right: 15px;
  background-color: #000;
  transition: background-color 0.3s;
}
@media screen and (min-width: 992px) {
  .navigation .nav__top .call-us__logo {
    width: 36px;
    height: 36px;
    padding: 5px;
  }
}
.navigation .nav__top .call-us__box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.navigation .nav__top .call-us .call {
  letter-spacing: 0.05em;
  color: #000;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
}
.navigation .nav__top .call-us .call {
  font-size: 13px;
  font-weight: 900;
}
@media screen and (min-width: 320px) {
  .navigation .nav__top .call-us .call {
    font-size: calc( 13px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .navigation .nav__top .call-us .call {
    font-size: 15px;
  }
}
.navigation .nav__top .call-us .number {
  color: #000;
}
.navigation .nav__top .call-us .number {
  font-size: 17px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .navigation .nav__top .call-us .number {
    font-size: calc( 17px + 7 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .navigation .nav__top .call-us .number {
    font-size: 24px;
  }
}
.navigation .nav__top-logo {
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
}
@media screen and (max-width: 991px) {
  .navigation .nav__top-logo {
    display: none;
  }
}
.navigation .nav__top-langs {
  display: flex;
}
@media screen and (max-width: 991px) {
  .navigation .nav__top-langs {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.5s, opacity 0.3s;
    order: -1;
  }
}
@media screen and (min-width: 992px) {
  .navigation .nav__top-langs {
    margin-left: auto;
  }
}
.navigation .nav__top-lang {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media screen and (min-width: 992px) {
  .navigation .nav__top-lang {
    margin-left: 5px;
  }
}
.navigation .nav__top-lang span {
  letter-spacing: 0.1em;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 15px;
  font-family: "Lato", sans-serif;
  padding: 10px;
  transition: color 0.3s;
}
@media screen and (max-width: 991px) {
  .navigation .nav__top-lang span {
    padding: 8px;
    font-size: 14px;
  }
}
.navigation .nav__top-lang:hover span {
  color: #f0cbbb;
}
.navigation .nav__top-lang::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../images/svg/lang-bg.svg");
  background-size: contain;
  background-position: center;
}
.navigation .nav__top-lang--active {
  pointer-events: none;
}
.navigation .nav__top-lang--active::before {
  opacity: 0.2;
}
.navigation .nav__bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.navigation .nav__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
}
@media screen and (max-width: 991px) {
  .navigation .nav__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 991px) {
  .navigation .nav__list-item {
    opacity: 0;
    transition: transform 0.5s, opacity 0.3s;
    transition-delay: 0.2s;
  }
  .navigation .nav__list-item:nth-child(odd) {
    transform: translateX(30%);
  }
  .navigation .nav__list-item:nth-child(even) {
    transform: translateX(-30%);
  }
}
@media screen and (min-width: 992px) {
  .navigation .nav__list-item:nth-child(1) .nav__list-itemlink {
    padding-left: 0;
  }
  .navigation .nav__list-item:nth-last-child(2) .nav__list-itemlink {
    padding-right: 0;
  }
}
.navigation .nav__list-itemlink {
  letter-spacing: 0.05em;
  display: inline-flex;
  color: #e6bca5;
  position: relative;
  outline: none;
  text-transform: uppercase;
  transform: translateZ(0);
  white-space: nowrap;
}
.navigation .nav__list-itemlink {
  font-size: 16px;
  font-weight: 900;
}
@media screen and (min-width: 320px) {
  .navigation .nav__list-itemlink {
    font-size: calc( 16px + 0 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .navigation .nav__list-itemlink {
    font-size: 16px;
  }
}
.navigation .nav__list-itemlink {
  padding-top: 15px;
}
@media screen and (min-width: 320px) {
  .navigation .nav__list-itemlink {
    padding-top: calc( 15px + (15 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .navigation .nav__list-itemlink {
    padding-top: 15px;
  }
}
.navigation .nav__list-itemlink {
  padding-bottom: 15px;
}
@media screen and (min-width: 320px) {
  .navigation .nav__list-itemlink {
    padding-bottom: calc( 15px + (15 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .navigation .nav__list-itemlink {
    padding-bottom: 15px;
  }
}
.navigation .nav__list-itemlink {
  padding-left: 5px;
}
@media screen and (min-width: 320px) {
  .navigation .nav__list-itemlink {
    padding-left: calc( 5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .navigation .nav__list-itemlink {
    padding-left: 15px;
  }
}
.navigation .nav__list-itemlink {
  padding-right: 5px;
}
@media screen and (min-width: 320px) {
  .navigation .nav__list-itemlink {
    padding-right: calc( 5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .navigation .nav__list-itemlink {
    padding-right: 15px;
  }
}
@media screen and (min-width: 992px) {
  .navigation .nav__list-itemlink {
    padding-top: 10px;
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .navigation .nav__list-itemlink {
    padding-top: calc( 10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .navigation .nav__list-itemlink {
    padding-top: 15px;
  }
}
@media screen and (min-width: 992px) {
  .navigation .nav__list-itemlink {
    padding-bottom: 10px;
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .navigation .nav__list-itemlink {
    padding-bottom: calc( 10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .navigation .nav__list-itemlink {
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 992px) {
  .navigation .nav__list-itemlink {
    font-size: 13px;
    font-weight: 900;
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .navigation .nav__list-itemlink {
    font-size: calc( 13px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .navigation .nav__list-itemlink {
    font-size: 15px;
  }
}
@media screen and (min-width: 1280px) {
  .navigation .nav__list-itemlink {
    font-size: 14px;
    font-weight: 900;
  }
}
@media screen and (min-width: 1280px) and (min-width: 320px) {
  .navigation .nav__list-itemlink {
    font-size: calc( 14px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1280px) and (min-width: 1920px) {
  .navigation .nav__list-itemlink {
    font-size: 16px;
  }
}
@media screen and (min-width: 1366px) {
  .navigation .nav__list-itemlink {
    padding-left: 10px;
  }
}
@media screen and (min-width: 1366px) and (min-width: 320px) {
  .navigation .nav__list-itemlink {
    padding-left: calc( 10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1366px) and (min-width: 1920px) {
  .navigation .nav__list-itemlink {
    padding-left: 30px;
  }
}
@media screen and (min-width: 1366px) {
  .navigation .nav__list-itemlink {
    padding-right: 10px;
  }
}
@media screen and (min-width: 1366px) and (min-width: 320px) {
  .navigation .nav__list-itemlink {
    padding-right: calc( 10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1366px) and (min-width: 1920px) {
  .navigation .nav__list-itemlink {
    padding-right: 30px;
  }
}
.navigation .nav__list-itemlink::before {
  color: #000;
  position: absolute;
  content: attr(data-hover);
  transform: translateZ(0);
  transition: transform 0.3s, opacity 0.3s;
}
.navigation .nav__list-itemlink:hover, .navigation .nav__list-itemlink:focus {
  outline: none;
}
.navigation .nav__list-itemlink:hover::before, .navigation .nav__list-itemlink:focus::before {
  transform: scale(0.9);
  opacity: 0;
}
.navigation .hamburger {
  padding: 0px 0px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  height: 40px !important;
  width: 40px !important;
  position: relative;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.navigation .hamburger::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../images/svg/hamburger-bg.svg");
  background-size: cover;
  z-index: 2;
}
.navigation .hamburger:hover {
  opacity: 0.9;
}
.navigation .hamburger.is-active:hover {
  opacity: 0.9;
}
.navigation .hamburger.is-active .hamburger-inner,
.navigation .hamburger.is-active .hamburger-inner::before,
.navigation .hamburger.is-active .hamburger-inner::after {
  background-color: #000;
}
.navigation .hamburger.is-active .hamburger-inner {
  width: 13.3333333333px;
}
.navigation .hamburger-box {
  width: 20px;
  height: 17px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
}
.navigation .hamburger-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  width: 20px;
  height: 3px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.navigation .hamburger-inner::before, .navigation .hamburger-inner::after {
  width: 20px;
  height: 3px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.navigation .hamburger-inner::before, .navigation .hamburger-inner::after {
  content: "";
  display: block;
}
.navigation .hamburger-inner::before {
  width: 13.3333333333px;
  top: -7px;
}
.navigation .hamburger-inner::after {
  bottom: -7px;
}

/*
 * Slider
 */
.hamburger--slider .hamburger-inner {
  top: 1.5px;
}
.hamburger--slider .hamburger-inner::before {
  top: 7px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider .hamburger-inner::after {
  top: 14px;
}
.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 7px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-2.8571428571px, -4px, 0);
  opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -14px, 0) rotate(-90deg);
}

.title {
  font-family: "Playfair Display", serif;
  font-style: italic;
  line-height: 1.2;
  color: #000;
  text-align: left;
}
.title {
  font-size: 33px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .title {
    font-size: calc( 33px + 13 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .title {
    font-size: 46px;
  }
}
.title {
  margin-bottom: 15px;
}
@media screen and (min-width: 320px) {
  .title {
    margin-bottom: calc( 15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .title {
    margin-bottom: 25px;
  }
}
.title--lg {
  font-style: normal;
  line-height: 1.05;
}
.title--lg {
  font-size: 43px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .title--lg {
    font-size: calc( 43px + 17 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .title--lg {
    font-size: 60px;
  }
}
.title--lg {
  margin-bottom: 30px;
}
@media screen and (min-width: 320px) {
  .title--lg {
    margin-bottom: calc( 30px + (45 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .title--lg {
    margin-bottom: 45px;
  }
}
.title--center {
  text-align: center;
  margin-left: 0;
  margin-right: 0;
}
.title--right {
  text-align: right;
}
.title--margin {
  margin-bottom: 15px;
}
@media screen and (min-width: 320px) {
  .title--margin {
    margin-bottom: calc( 15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .title--margin {
    margin-bottom: 20px;
  }
}

.subtitle {
  font-family: "Playfair Display", serif;
  color: #000;
  text-align: center;
  line-height: 1.2;
}
.subtitle {
  font-size: 20px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .subtitle {
    font-size: calc( 20px + 4 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .subtitle {
    font-size: 24px;
  }
}
.subtitle--italic {
  font-style: italic;
}

.heading {
  letter-spacing: 0.25em;
  color: #000;
  font-family: "Lato", sans-serif;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.heading {
  font-size: 14px;
  font-weight: 900;
}
@media screen and (min-width: 320px) {
  .heading {
    font-size: calc( 14px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .heading {
    font-size: 16px;
  }
}
.heading--center {
  text-align: center;
}
.heading--cream {
  color: #e6bca5;
}
.heading--big {
  font-size: 18px;
  font-weight: 900;
}
@media screen and (min-width: 320px) {
  .heading--big {
    font-size: calc( 18px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .heading--big {
    font-size: 20px;
  }
}

.description {
  color: #8b8b8b;
  font-family: "Lato", sans-serif;
  line-height: 1.4;
}
.description {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .description {
    font-size: calc( 14px + 0 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .description {
    font-size: 14px;
  }
}
.description--center {
  text-align: center;
}
.description--big {
  font-size: 15px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .description--big {
    font-size: calc( 15px + 5 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .description--big {
    font-size: 20px;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  font-family: "Playfair Display", serif;
}
.btn {
  font-size: 14px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .btn {
    font-size: calc( 14px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .btn {
    font-size: 16px;
  }
}
.btn {
  padding-top: 13px;
}
@media screen and (min-width: 320px) {
  .btn {
    padding-top: calc( 13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn {
    padding-top: 15px;
  }
}
.btn {
  padding-bottom: 13px;
}
@media screen and (min-width: 320px) {
  .btn {
    padding-bottom: calc( 13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn {
    padding-bottom: 15px;
  }
}
.btn {
  padding-left: 20px;
}
@media screen and (min-width: 320px) {
  .btn {
    padding-left: calc( 20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn {
    padding-left: 32px;
  }
}
.btn {
  padding-right: 20px;
}
@media screen and (min-width: 320px) {
  .btn {
    padding-right: calc( 20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn {
    padding-right: 32px;
  }
}
.btn--big-font {
  font-size: 15px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .btn--big-font {
    font-size: calc( 15px + 9 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .btn--big-font {
    font-size: 24px;
  }
}
.btn--big {
  padding-top: 14px;
}
@media screen and (min-width: 320px) {
  .btn--big {
    padding-top: calc( 14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn--big {
    padding-top: 16px;
  }
}
.btn--big {
  padding-bottom: 14px;
}
@media screen and (min-width: 320px) {
  .btn--big {
    padding-bottom: calc( 14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn--big {
    padding-bottom: 16px;
  }
}
.btn--big {
  padding-left: 30px;
}
@media screen and (min-width: 320px) {
  .btn--big {
    padding-left: calc( 30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn--big {
    padding-left: 70px;
  }
}
.btn--big {
  padding-right: 30px;
}
@media screen and (min-width: 320px) {
  .btn--big {
    padding-right: calc( 30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn--big {
    padding-right: 70px;
  }
}
.btn--black {
  color: #fff;
  background-color: #000;
}
.btn--black::after {
  box-shadow: 0px 0px 30px #000;
}
.btn--white {
  color: #000;
  background-color: #fff;
}
.btn--white::after {
  box-shadow: 0px 0px 30px #fff;
}
.btn--cream {
  color: #000;
  background-color: #fff3ed;
}
.btn--white, .btn--cream, .btn--black {
  transform: translateZ(0);
  transition: all 0.6s, color 0.3s 0.3s;
  overflow: hidden;
}
.btn--white::before, .btn--cream::before, .btn--black::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  width: 100%;
  background-color: #e6bca5;
  transform: translateX(-100%) translateZ(0);
  opacity: 0;
  z-index: -1;
  transition: all 0.5s;
}
.btn--white:hover::before, .btn--cream:hover::before, .btn--black:hover::before {
  opacity: 1;
  transform: translateX(0) translateZ(0);
}
.btn__outline {
  letter-spacing: 0.05em;
  color: #fff;
  border: 1px solid #f0cbbb;
  overflow: hidden;
  z-index: 1;
  transform: translateZ(0);
  outline: 1px solid transparent;
  transition: color 0.3s;
}
.btn__outline {
  padding-top: 13px;
}
@media screen and (min-width: 320px) {
  .btn__outline {
    padding-top: calc( 13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn__outline {
    padding-top: 15px;
  }
}
.btn__outline {
  padding-bottom: 13px;
}
@media screen and (min-width: 320px) {
  .btn__outline {
    padding-bottom: calc( 13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn__outline {
    padding-bottom: 15px;
  }
}
.btn__outline {
  padding-left: 40px;
}
@media screen and (min-width: 320px) {
  .btn__outline {
    padding-left: calc( 40px + (60 - 40) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn__outline {
    padding-left: 60px;
  }
}
.btn__outline {
  padding-right: 40px;
}
@media screen and (min-width: 320px) {
  .btn__outline {
    padding-right: calc( 40px + (60 - 40) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn__outline {
    padding-right: 60px;
  }
}
.btn__outline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  background-color: #f0cbbb;
  transform: translateX(-100%);
  transition: transform 0.5s, opacity 0.5s;
}
.btn__outline:hover {
  color: #243c54;
  transition-delay: 0.2s;
}
.btn__outline:hover::before {
  opacity: 1;
  transform: translateX(0);
}
.btn__with-icon {
  color: #fff;
  background-color: #000;
  transition: color 0.4s;
}
.btn__with-icon svg {
  width: 16px;
  height: 16px;
  transition: transform 0.5s;
}
.btn__with-icon svg {
  margin-left: 15px;
}
@media screen and (min-width: 320px) {
  .btn__with-icon svg {
    margin-left: calc( 15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn__with-icon svg {
    margin-left: 25px;
  }
}
.btn__with-icon svg * {
  transition: fill 0.5s;
}
.btn__with-icon:hover {
  color: #f0cbbb;
}
.btn__with-icon:hover svg {
  transform: translateX(5px);
}
@media screen and (min-width: 768px) {
  .btn__with-icon:hover svg {
    transform: translateX(8px);
  }
}
.btn__with-icon:hover svg * {
  fill: #f0cbbb;
}
.btn__black-square {
  letter-spacing: 0.02em;
  color: #000;
  transition: padding 0.3s;
}
.btn__black-square {
  padding-top: 20px;
}
@media screen and (min-width: 320px) {
  .btn__black-square {
    padding-top: calc( 20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn__black-square {
    padding-top: 30px;
  }
}
.btn__black-square {
  padding-bottom: 20px;
}
@media screen and (min-width: 320px) {
  .btn__black-square {
    padding-bottom: calc( 20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn__black-square {
    padding-bottom: 30px;
  }
}
.btn__black-square {
  padding-left: 25px;
}
@media screen and (min-width: 320px) {
  .btn__black-square {
    padding-left: calc( 25px + (36 - 25) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn__black-square {
    padding-left: 36px;
  }
}
.btn__black-square {
  padding-right: 25px;
}
@media screen and (min-width: 320px) {
  .btn__black-square {
    padding-right: calc( 25px + (46 - 25) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn__black-square {
    padding-right: 46px;
  }
}
.btn__black-square--dark-cream {
  background-color: #e6bca5;
}
.btn__black-square--white {
  background-color: #fff;
}
.btn__black-square--gray {
  background-color: #f7f9fa;
}
.btn__black-square span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  padding: 15px 25px;
  background-color: #000;
  transition: transform 0.4s, opacity 0.5s;
}
.btn__black-square:hover {
  padding-left: 30px;
}
@media screen and (min-width: 320px) {
  .btn__black-square:hover {
    padding-left: calc( 30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn__black-square:hover {
    padding-left: 40px;
  }
}
.btn__black-square:hover {
  padding-right: 20px;
}
@media screen and (min-width: 320px) {
  .btn__black-square:hover {
    padding-right: calc( 20px + (42 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .btn__black-square:hover {
    padding-right: 42px;
  }
}
.btn__black-square:hover span {
  opacity: 0;
  transform: translate(100%, -50%);
}

/* Cookie */
.cookie-box {
  position: fixed;
  z-index: 1040;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #aec4d4;
  color: #fff;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(0px);
}
@media screen and (max-width: 767px) {
  .cookie-box {
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .cookie-box {
    flex-direction: row;
    max-width: 1230px;
  }
}
.cookie-box .cookie__column-left {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
  padding: 20px;
  line-height: 1.3;
  max-width: 550px;
}
.cookie-box .cookie__column-left {
  font-size: 12px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .cookie-box .cookie__column-left {
    font-size: calc( 12px + 1 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .cookie-box .cookie__column-left {
    font-size: 13px;
  }
}
@media screen and (min-width: 768px) {
  .cookie-box .cookie__column-left {
    justify-content: flex-start;
  }
}
@media screen and (min-width: 992px) {
  .cookie-box .cookie__column-left {
    padding: 15px 0px 15px 40px;
  }
}
@media screen and (max-width: 767px) {
  .cookie-box .cookie__column-left span {
    text-align: center;
  }
}
.cookie-box .cookie__column-right {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .cookie-box .cookie__column-right {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 768px) {
  .cookie-box .cookie__column-right .btn {
    transform: translateX(50%);
  }
}
.cookie-box--hide {
  opacity: 0;
  transform: translateY(100%);
}

.footer {
  background-color: #243c54;
}
.footer {
  padding-top: 50px;
}
@media screen and (min-width: 320px) {
  .footer {
    padding-top: calc( 50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .footer {
    padding-top: 60px;
  }
}
.footer {
  padding-bottom: 50px;
}
@media screen and (min-width: 320px) {
  .footer {
    padding-bottom: calc( 50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .footer {
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 991px) {
  .footer {
    padding-bottom: 30px;
  }
}
.footer__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer__container {
  padding-left: 15px;
}
@media screen and (min-width: 320px) {
  .footer__container {
    padding-left: calc( 15px + (140 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .footer__container {
    padding-left: 140px;
  }
}
.footer__container {
  padding-right: 15px;
}
@media screen and (min-width: 320px) {
  .footer__container {
    padding-right: calc( 15px + (140 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .footer__container {
    padding-right: 140px;
  }
}
@media screen and (max-width: 991px) {
  .footer__column {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.footer__row-top {
  margin-bottom: 50px;
}
@media screen and (min-width: 320px) {
  .footer__row-top {
    margin-bottom: calc( 50px + (150 - 50) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .footer__row-top {
    margin-bottom: 150px;
  }
}
@media screen and (max-width: 991px) {
  .footer__row-top {
    margin-bottom: 30px;
  }
}
.footer__contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer__contact {
  margin-bottom: 50px;
}
@media screen and (min-width: 320px) {
  .footer__contact {
    margin-bottom: calc( 50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .footer__contact {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 991px) {
  .footer__contact {
    align-items: center;
  }
}
.footer__contact-title {
  font-style: italic;
  line-height: 1.2;
  color: #fff;
  text-align: left;
}
.footer__contact-title {
  font-size: 24px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .footer__contact-title {
    font-size: calc( 24px + 8 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .footer__contact-title {
    font-size: 32px;
  }
}
.footer__contact-title {
  margin-bottom: 10px;
}
@media screen and (min-width: 320px) {
  .footer__contact-title {
    margin-bottom: calc( 10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .footer__contact-title {
    margin-bottom: 15px;
  }
}
.footer__contact-phone, .footer__contact-email {
  color: #fff3ed;
  transition: color 0.3s;
}
.footer__contact-phone, .footer__contact-email {
  font-size: 18px;
  font-weight: 300;
}
@media screen and (min-width: 320px) {
  .footer__contact-phone, .footer__contact-email {
    font-size: calc( 18px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .footer__contact-phone, .footer__contact-email {
    font-size: 20px;
  }
}
.footer__contact-phone:hover, .footer__contact-email:hover {
  color: #f0cbbb;
}
.footer__contact-phone {
  margin-bottom: 5px;
}
@media screen and (min-width: 320px) {
  .footer__contact-phone {
    margin-bottom: calc( 5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .footer__contact-phone {
    margin-bottom: 8px;
  }
}
.footer__buttons {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  margin-bottom: 30px;
}
@media screen and (min-width: 992px) {
  .footer__buttons {
    max-width: 310px;
    margin-bottom: 0;
  }
}
.footer__nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 991px) {
  .footer__nav {
    display: inline-flex;
  }
}
.footer__nav-item {
  display: inline-flex;
  color: #fff;
  transition: color 0.3s;
}
.footer__nav-item {
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .footer__nav-item {
    font-size: calc( 16px + 0 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .footer__nav-item {
    font-size: 16px;
  }
}
.footer__nav-item {
  padding-top: 11px;
}
@media screen and (min-width: 320px) {
  .footer__nav-item {
    padding-top: calc( 11px + (10 - 11) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .footer__nav-item {
    padding-top: 10px;
  }
}
.footer__nav-item {
  padding-bottom: 11px;
}
@media screen and (min-width: 320px) {
  .footer__nav-item {
    padding-bottom: calc( 11px + (10 - 11) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .footer__nav-item {
    padding-bottom: 10px;
  }
}
.footer__nav-item:hover {
  color: #f0cbbb;
}
.footer-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(55%, 10%);
  max-width: 300px;
  pointer-events: none;
}
@media screen and (min-width: 992px) {
  .footer-bg {
    transform: translate(30%, 10%);
  }
}
@media screen and (min-width: 1920px) {
  .footer-bg {
    transform: translate(10%, 10%);
  }
}
.footer-bg svg {
  width: 100%;
  height: auto;
}
.footer__sm {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media screen and (max-width: 991px) {
  .footer__sm {
    justify-content: center;
    margin-top: 50px;
  }
}
.footer__sm li {
  margin: 0 7.5px;
}
@media screen and (min-width: 992px) {
  .footer__sm li:nth-last-child(1) {
    margin-right: 0;
  }
}
.footer__sm-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff3ed;
  transition: background-color 0.3s;
}
.footer__sm-item:hover {
  background-color: #f0cbbb;
}
.footer .copyright-column {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .footer .copyright-column {
    align-items: flex-end;
  }
}
.footer .copyright {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .footer .copyright {
    transform: rotate(-90deg) translateY(100%);
    transform-origin: bottom left;
  }
}
.footer .copyright__left {
  font-family: "Lato", sans-serif;
  color: #fff3ed;
  opacity: 0.6;
}
.footer .copyright__left {
  font-size: 12px;
  font-weight: 300;
}
@media screen and (min-width: 320px) {
  .footer .copyright__left {
    font-size: calc( 12px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .footer .copyright__left {
    font-size: 14px;
  }
}
.footer .copyright__right {
  letter-spacing: 0.05em;
  color: #fff3ed;
  padding-left: 15px;
}
.footer .copyright__right {
  font-size: 12px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .footer .copyright__right {
    font-size: calc( 12px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .footer .copyright__right {
    font-size: 14px;
  }
}
.footer__bottom-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #f0cbbb;
}
@media screen and (max-width: 991px) {
  .footer__bottom-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.footer__bottom-nav-item {
  display: inline-flex;
  padding: 15px;
  opacity: 0.7;
  transition: opacity 0.3s;
  font-family: "Lato", sans-serif;
  text-align: center;
}
.footer__bottom-nav-item {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .footer__bottom-nav-item {
    font-size: calc( 14px + 0 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .footer__bottom-nav-item {
    font-size: 14px;
  }
}
@media screen and (max-width: 991px) {
  .footer__bottom-nav-item {
    padding: 5px;
  }
}
.footer__bottom-nav-item:hover {
  opacity: 1;
}
.footer .created-column {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .footer .created-column {
    align-items: flex-end;
    justify-content: flex-end;
  }
}
.footer-created {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: flex-end;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .footer-created {
    transform-origin: bottom right;
    transform: rotate(-90deg) translateX(100%);
  }
}
.footer-created:hover .footer-created__left {
  opacity: 1;
  color: #f0cbbb;
}
.footer-created:hover svg * {
  fill: #f0cbbb;
}
.footer-created svg * {
  transition: fill 0.3s;
}
.footer-created__left {
  font-family: "Lato", sans-serif;
  color: #fff3ed;
  opacity: 0.6;
  margin-right: 10px;
  transition: opacity 0.3s, color 0.3s;
}
.footer-created__left {
  font-size: 12px;
  font-weight: 300;
}
@media screen and (min-width: 320px) {
  .footer-created__left {
    font-size: calc( 12px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .footer-created__left {
    font-size: 14px;
  }
}

.preloader {
  z-index: 1041;
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1s;
}
.preloader svg {
  position: absolute;
  height: 0;
  width: 0;
}
.preloader__wrapper {
  width: 100%;
  position: relative;
}
.preloader__box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: show ease-in-out 2s infinite alternate;
}
.preloader__box:after {
  content: "";
  clear: both;
}
.preloader__box span {
  color: #e6bca5;
  display: block;
  float: left;
}
.preloader__box span {
  font-size: 45px;
  font-weight: 600;
}
@media screen and (min-width: 320px) {
  .preloader__box span {
    font-size: calc( 45px + 27 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .preloader__box span {
    font-size: 72px;
  }
}
.preloader__box span:nth-child(3) {
  animation: blob-anim-left3 ease-in-out 2s infinite 0.05s alternate;
}
.preloader__box span:nth-child(2) {
  animation: blob-anim-left2 ease-in-out 2s infinite 0.025s alternate;
}
.preloader__box span:nth-child(1) {
  animation: blob-anim-left1 ease-in-out 2s infinite alternate;
}
@supports (-webkit-backdrop-filter: blur(1px)) {
  .preloader__box span:nth-child(4) {
    animation: blob-anim-left4 ease-in-out 2s infinite alternate;
  }
}
.preloader__box span:nth-child(5) {
  animation: blob-anim-left5 ease-in-out 2s infinite 0.05s alternate;
}
.preloader__box span:nth-child(6) {
  animation: blob-anim-left6 ease-in-out 2s infinite 0.025s alternate;
}
.preloader__box span:nth-child(7) {
  animation: blob-anim-left7 ease-in-out 2s infinite alternate;
}
@keyframes show {
  0% {
    filter: none;
  }
  41% {
    filter: none;
  }
  42% {
    filter: url(#goo);
  }
  100% {
    filter: url(#goo);
  }
}
@keyframes blob-anim-left7 {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-230%) translateX(-50px);
  }
  65% {
    transform: translateX(-230%);
  }
  75% {
    transform: translateX(-230%) translateX(5px);
  }
  85% {
    transform: translateX(-230%);
  }
  100% {
    transform: translateX(-230%);
    opacity: 0;
  }
}
@keyframes blob-anim-left6 {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-110%) translateX(-50px);
  }
  65% {
    transform: translateX(-110%);
  }
  75% {
    transform: translateX(-110%) translateX(5px);
  }
  85% {
    transform: translateX(-110%);
  }
  100% {
    transform: translateX(-110%);
    opacity: 0;
  }
}
@keyframes blob-anim-left5 {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-100%) translateX(-50px);
  }
  65% {
    transform: translateX(-100%);
  }
  75% {
    transform: translateX(-100%) translateX(5px);
  }
  85% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
@keyframes blob-anim-left1 {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(305%) translateX(20px);
  }
  65% {
    transform: translateX(305%);
  }
  75% {
    transform: translateX(305%) translateX(-5px);
  }
  85% {
    transform: translateX(305%);
  }
  100% {
    transform: translateX(305%);
    opacity: 0;
  }
}
@keyframes blob-anim-left2 {
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(190%) translateX(50px);
  }
  65% {
    transform: translateX(190%);
  }
  75% {
    transform: translateX(190%) translateX(-5px);
  }
  85% {
    transform: translateX(190%);
  }
  100% {
    transform: translateX(190%);
    opacity: 0;
  }
}
@keyframes blob-anim-left3 {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(100%) translateX(50px);
  }
  65% {
    transform: translateX(100%);
  }
  75% {
    transform: translateX(100%) translateX(-5px);
  }
  85% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
@keyframes blob-anim-left4 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 0;
  }
}

/******************/
/* ~*~ Shared ~*~ */
/******************/
.offer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.offer__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.offer__list {
  display: grid;
  width: 100%;
  grid-row-gap: 60px;
}
@media screen and (min-width: 768px) {
  .offer__list {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: initial;
  }
}
@media screen and (min-width: 992px) {
  .offer__list {
    grid-template-columns: repeat(3, 1fr);
    margin-top: -100px;
  }
}
.offer__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .offer__box:nth-child(2n+2) {
    margin-top: 100px;
    margin-bottom: -50px;
  }
}
@media screen and (min-width: 992px) {
  .offer__box:nth-child(2n+2) {
    margin-top: 50px;
  }
}
@media screen and (min-width: 992px) {
  .offer__box:nth-child(3n+2) {
    margin-top: 200px;
    margin-bottom: -100px;
  }
}
.offer__box:hover .offer__box-bg-color::after {
  transform: translateY(-10px);
}
.offer__box:hover .offer__box-title {
  transform: translateX(-10px);
}
.offer__box:hover .offer__box-desc {
  transform: translateX(10px);
}
.offer__box-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.offer__box-bg-color {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 413px) {
  .offer__box-bg-color {
    max-width: 180px;
  }
}
.offer__box-bg-color svg * {
  fill: var(--bg-color);
}
.offer__box-bg-color::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 50px;
  bottom: -25px;
  left: 50%;
  transform: translateX(50%);
  background-image: url("../../../images/svg/dotted-line.svg");
  background-size: cover;
  transition: transform 0.3s;
}
.offer__box-bg-img {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.offer__box-bg-img svg * {
  fill: transparent;
}
.offer__box-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.offer__box-title {
  margin-top: 35px;
  max-width: 320px;
  transform: translateX(0);
  transition: transform 0.3s;
}
.offer__box-desc {
  margin-top: 10px;
  text-align: center;
  max-width: 360px;
  transform: translateX(0);
  transition: transform 0.3s;
}
.offer__boxes {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-gap: 15px;
}
.offer__boxes {
  margin-top: 70px;
}
@media screen and (min-width: 320px) {
  .offer__boxes {
    margin-top: calc( 70px + (200 - 70) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .offer__boxes {
    margin-top: 200px;
  }
}
@media screen and (min-width: 992px) {
  .offer__boxes {
    grid-template-columns: 1fr 1fr;
  }
}
.offer__item {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 413px) {
  .offer__item {
    flex-direction: column;
    margin-bottom: 70px;
  }
}
@media screen and (min-width: 414px) {
  .offer__item {
    overflow: hidden;
  }
}
.offer__item:nth-last-of-type(1) {
  margin-bottom: 0;
}
.offer__item:nth-of-type(even) {
  justify-content: flex-start;
}
.offer__item:nth-of-type(even) .offer__item-bg {
  order: -1;
}
@media screen and (min-width: 414px) {
  .offer__item:nth-of-type(even) .offer__item-bg {
    margin-left: 0;
    margin-right: 15px;
  }
}
@media screen and (min-width: 768px) {
  .offer__item:nth-of-type(even) .offer__item-bg {
    margin-right: 30px;
  }
}
@media screen and (min-width: 414px) {
  .offer__item:nth-of-type(even) .offer__item-texts {
    align-items: flex-start;
  }
}
@media screen and (min-width: 414px) {
  .offer__item:nth-of-type(even) .offer__item-title {
    text-align: left;
  }
}
.offer__item-bg {
  position: relative;
  display: flex;
  order: -1;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 30px;
}
@media screen and (min-width: 414px) {
  .offer__item-bg {
    margin-left: 15px;
    width: 50%;
    order: initial;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .offer__item-bg {
    margin-left: 30px;
  }
}
@media screen and (min-width: 992px) {
  .offer__item-bg {
    width: 60%;
    max-width: 380px;
  }
}
@media screen and (min-width: 1920px) {
  .offer__item-bg {
    width: 65%;
  }
}
.offer__item-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(174, 196, 212, 0.19);
}
.offer__item-bg-img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.offer__item-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 414px) {
  .offer__item-texts {
    width: 50%;
    align-items: flex-end;
  }
}
@media screen and (min-width: 992px) {
  .offer__item-texts {
    width: 40%;
  }
}
@media screen and (min-width: 1920px) {
  .offer__item-texts {
    width: 35%;
  }
}
.offer__item-title {
  text-align: center;
}
@media screen and (min-width: 414px) {
  .offer__item-title {
    text-align: right;
  }
}

.item__svg {
  position: relative;
  display: block;
  height: auto;
  margin: 0 auto;
  overflow: inherit;
}

/* ----- */
/* Slider */
/* ----- */
.header__container {
  position: relative;
}
@media screen and (min-width: 414px) {
  .header__container {
    margin-top: 30px;
  }
}
.header__title {
  text-align: center;
  margin-top: 30px;
}
.header__bg {
  position: absolute;
  bottom: -250px;
  right: 50%;
  transform: translateX(50%);
  max-width: 350px;
  width: 20vw;
  min-width: 200px;
  display: flex;
}
@media screen and (max-width: 1279px) {
  .header__bg {
    display: none;
  }
}
@media screen and (min-width: 2560px) {
  .header__bg {
    transform: translateX(25%);
  }
}
.header__slide {
  display: flex;
  overflow: hidden;
  padding: 0 110px;
}
@media screen and (max-width: 991px) {
  .header__slide {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}
@media screen and (min-width: 1280px) {
  .header__slide {
    padding: 0 90px;
  }
}
@media screen and (min-width: 1440px) {
  .header__slide {
    padding: 0 150px;
  }
}
@media screen and (min-width: 1920px) {
  .header__slide {
    padding: 0;
  }
}
.header .swiper-slide-active .slide__content {
  opacity: 1;
}
.header .swiper-slide-active .slide__above-title {
  opacity: 1;
  transform: none;
  transition-delay: 0.2s;
}
.header .swiper-slide-active .slide__title {
  opacity: 1;
  transform: none;
  transition-delay: 0.3s;
}
.header .swiper-slide-active .slide__desc {
  opacity: 1;
  transform: none;
  transition-delay: 0.4s;
}
@media screen and (max-width: 1279px) {
  .header .swiper-slide-active .slide__btn {
    opacity: 1;
    transform: none;
    transition-delay: 0.5s;
  }
}
@media screen and (max-width: 413px) {
  .header .swiper-homepage {
    padding-left: 0;
    padding-right: 0;
  }
}
.header .slide__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}
@media screen and (max-width: 413px) {
  .header .slide__wrapper {
    padding: 0 20px;
  }
}
@media screen and (min-width: 768px) {
  .header .slide__wrapper {
    width: 60%;
  }
}
@media screen and (min-width: 992px) {
  .header .slide__wrapper {
    align-items: flex-end;
    margin-top: 0;
    margin-right: 50px;
  }
}
@media screen and (min-width: 1280px) {
  .header .slide__wrapper {
    margin-right: 0;
    flex-direction: row;
    align-items: center;
  }
}
.header .slide__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s;
}
@media screen and (min-width: 992px) {
  .header .slide__content {
    align-items: flex-end;
  }
}
.header .slide__picture {
  width: 33vw;
  height: 30vw;
  max-width: 700px;
  max-height: 600px;
  display: flex;
}
@media screen and (max-width: 991px) {
  .header .slide__picture {
    order: -1;
    height: 350px;
    width: 100%;
  }
}
@media screen and (max-width: 413px) {
  .header .slide__picture {
    height: 300px;
  }
}
.header .slide__image {
  width: 1100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.header .slide__btn {
  z-index: 2;
  transform: translateY(85px) translateZ(0);
}
@media screen and (max-width: 1279px) {
  .header .slide__btn {
    opacity: 0;
    transition: transform 0.9s, opacity 0.4s;
  }
}
@media screen and (min-width: 992px) {
  .header .slide__btn {
    transform: translateX(-105px) translateZ(0);
  }
}
@media screen and (min-width: 1280px) {
  .header .slide__btn {
    transform: translateX(50%);
    margin-left: -50px;
  }
}
@media screen and (min-width: 1920px) {
  .header .slide__btn {
    margin-right: -100px;
  }
}
@media screen and (min-width: 2560px) {
  .header .slide__btn {
    margin-right: 0;
  }
}
.header .slide__above-title {
  letter-spacing: 0.25em;
  color: #e6bca5;
  font-family: "Lato", sans-serif;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 5px;
  text-align: right;
  transform: translateY(25px);
}
.header .slide__above-title {
  font-size: 13px;
  font-weight: 900;
}
@media screen and (min-width: 320px) {
  .header .slide__above-title {
    font-size: calc( 13px + 1 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .header .slide__above-title {
    font-size: 14px;
  }
}
@media screen and (min-width: 992px) {
  .header .slide__above-title {
    transform: translateX(-45px);
  }
}
.header .slide__title {
  text-align: center;
  transform: translateY(45px);
}
@media screen and (min-width: 992px) {
  .header .slide__title {
    text-align: right;
    transform: translateX(-65px);
  }
}
.header .slide__desc {
  text-align: center;
  max-width: 400px;
  transform: translateY(65px);
  margin-bottom: 20px;
}
@media screen and (min-width: 992px) {
  .header .slide__desc {
    transform: translateX(-85px);
    text-align: right;
  }
}
@media screen and (min-width: 1280px) {
  .header .slide__desc {
    margin-bottom: 0;
  }
}
.header .slide__above-title,
.header .slide__title,
.header .slide__desc {
  opacity: 0;
  transition: transform 0.9s, opacity 0.4s;
}
.header-button-next, .header-button-prev {
  margin-top: 0;
  width: 70px;
}
@media screen and (max-width: 991px) {
  .header-button-next, .header-button-prev {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  .header-button-next, .header-button-prev {
    transform: translateY(-50%);
  }
}
@media screen and (min-width: 1280px) {
  .header-button-next, .header-button-prev {
    width: 90px;
  }
}
@media screen and (min-width: 1920px) {
  .header-button-next, .header-button-prev {
    width: 110px;
  }
}
.header-button-next {
  right: 30px;
}
@media screen and (min-width: 1920px) {
  .header-button-next {
    right: 50px;
  }
}
.header-button-prev {
  left: 30px;
}
@media screen and (min-width: 1920px) {
  .header-button-prev {
    left: 50px;
  }
}
.header-button-prev::before {
  content: "";
  position: absolute;
  width: 10vw;
  max-width: 240px;
  min-width: 130px;
  height: 15vw;
  min-height: 200px;
  max-height: 360px;
  max-width: 240px;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url("../../../images/svg/header-gray-blob.svg");
  background-size: contain;
}
@media screen and (max-width: 991px) {
  .header-button-prev::before {
    display: none;
  }
}
@media screen and (min-width: 1920px) {
  .header-button-prev::before {
    left: -50px;
  }
}
.header-pagination {
  right: 50%;
  left: initial !important;
  bottom: initial !important;
  top: 270px;
  width: auto !important;
  transform: translateX(50%);
}
@media screen and (min-width: 414px) {
  .header-pagination {
    top: 320px;
  }
}
@media screen and (min-width: 992px) {
  .header-pagination {
    top: 50%;
    right: 130px;
    transform: translateY(-50%);
  }
}
@media screen and (min-width: 1280px) {
  .header-pagination {
    right: 150px;
  }
}
@media screen and (min-width: 1440px) {
  .header-pagination {
    right: 170px;
  }
}
@media screen and (min-width: 1536px) {
  .header-pagination {
    right: 200px;
  }
}

.small-header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 991px) {
  .small-header__container {
    margin-top: 30px;
  }
}
@media screen and (max-width: 991px) and (min-width: 320px) {
  .small-header__container {
    margin-top: calc( 30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (max-width: 991px) and (min-width: 1920px) {
  .small-header__container {
    margin-top: 50px;
  }
}
.small-header__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #aec4d4;
}
.small-header__row {
  padding-top: 40px;
}
@media screen and (min-width: 320px) {
  .small-header__row {
    padding-top: calc( 40px + (80 - 40) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .small-header__row {
    padding-top: 80px;
  }
}
.small-header__row {
  padding-bottom: 40px;
}
@media screen and (min-width: 320px) {
  .small-header__row {
    padding-bottom: calc( 40px + (80 - 40) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .small-header__row {
    padding-bottom: 80px;
  }
}
.small-header__row {
  padding-left: 10px;
}
@media screen and (min-width: 320px) {
  .small-header__row {
    padding-left: calc( 10px + (75 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .small-header__row {
    padding-left: 75px;
  }
}
.small-header__row {
  padding-right: 10px;
}
@media screen and (min-width: 320px) {
  .small-header__row {
    padding-right: calc( 10px + (75 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .small-header__row {
    padding-right: 75px;
  }
}
@media screen and (max-width: 413px) {
  .small-header__row {
    margin-right: -20px;
    margin-left: -20px;
  }
}
@media screen and (min-width: 992px) {
  .small-header__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 0;
    margin-right: 50px;
  }
}
.small-header__name {
  color: #fff;
  font-style: initial;
}
@media screen and (max-width: 991px) {
  .small-header__name {
    text-align: center;
  }
}
.small-header__desc {
  color: #fff;
  max-width: 500px;
}
@media screen and (max-width: 991px) {
  .small-header__desc {
    margin-bottom: 30px;
    text-align: center;
  }
}
@media screen and (min-width: 992px) {
  .small-header__desc {
    margin-left: 30px;
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .small-header__desc {
    margin-left: calc( 30px + (75 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .small-header__desc {
    margin-left: 75px;
  }
}
@media screen and (min-width: 992px) {
  .small-header__desc {
    margin-right: 30px;
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .small-header__desc {
    margin-right: calc( 30px + (75 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .small-header__desc {
    margin-right: 75px;
  }
}
@media screen and (min-width: 992px) {
  .small-header__btn {
    margin-right: -50px;
  }
}

.who-treatment__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.who-treatment__row {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;
  padding-right: 30px;
  padding-left: 30px;
}
.who-treatment__row {
  margin-top: 20px;
}
@media screen and (min-width: 320px) {
  .who-treatment__row {
    margin-top: calc( 20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .who-treatment__row {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .who-treatment__row {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 414px) {
  .who-treatment__row {
    padding: 0;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
  }
}
@media screen and (min-width: 768px) {
  .who-treatment__row {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;
  }
}
@media screen and (min-width: 992px) {
  .who-treatment__row {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 1280px) {
  .who-treatment__row {
    grid-column-gap: 7.5%;
  }
}
.who-treatment__box:hover:nth-child(odd) .who-treatment__box-bg {
  transform: scale(1.08) translateZ(0) perspective(2px) rotate(-2deg);
}
.who-treatment__box:hover:nth-child(even) .who-treatment__box-bg {
  transform: scale(1.08) translateZ(0) perspective(2px) rotate(2deg);
}
.who-treatment__box-img {
  max-height: 260px;
  overflow: hidden;
  margin-bottom: 15px;
}
.who-treatment__box-bg {
  width: 100%;
  max-height: 260px;
  -o-object-fit: cover;
     object-fit: cover;
  backface-visibility: hidden;
  transition: transform 0.7s;
}
.who-treatment__box-title {
  color: #000;
  font-style: italic;
}
.who-treatment__box-title {
  font-size: 14px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .who-treatment__box-title {
    font-size: calc( 14px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .who-treatment__box-title {
    font-size: 16px;
  }
}
.who-treatment__box-name {
  color: #000;
  font-style: italic;
  margin-top: 3px;
  margin-bottom: 10px;
}
.who-treatment__box-name {
  font-size: 18px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .who-treatment__box-name {
    font-size: calc( 18px + 6 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .who-treatment__box-name {
    font-size: 24px;
  }
}
.who-treatment__box-position {
  font-family: "Lato", sans-serif;
  font-style: italic;
  color: #8b8b8b;
}
.who-treatment__box-position {
  font-size: 13px;
  font-weight: 500;
}
@media screen and (min-width: 320px) {
  .who-treatment__box-position {
    font-size: calc( 13px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .who-treatment__box-position {
    font-size: 15px;
  }
}

.media__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.media__row {
  display: flex;
}
.media__row {
  margin-bottom: 20px;
}
@media screen and (min-width: 320px) {
  .media__row {
    margin-bottom: calc( 20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .media__row {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 991px) {
  .media__row {
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 413px) {
  .media__row {
    margin-right: -20px;
    margin-left: -20px;
  }
}
.media-video__content {
  display: flex;
  position: relative;
  margin-bottom: 20px;
  height: 300px;
}
@media screen and (min-width: 992px) {
  .media-video__content {
    height: auto;
    margin: 10px;
  }
}
@media screen and (min-width: 1366px) {
  .media-video__content {
    margin: 15px;
  }
}
.media-video__content iframe,
.media-video__content object,
.media-video__content embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.media-video__play-btn {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  height: 100%;
  transition: transform 0.9s, opacity 0.6s;
  cursor: pointer;
  overflow: hidden;
}
.media-video__play-btn.is-disable {
  opacity: 0;
  pointer-events: none;
}
.media-video__play-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(240, 203, 187, 0.73);
}
.media-video__play-btn:hover .media-video__play-bg {
  filter: blur(2px);
}
.media-video__play-btn:hover .media-video__play-icon {
  opacity: 0.7;
}
.media-video__play-btn:hover .media-video__play-icon svg .cls-1 {
  stroke: #000;
}
.media-video__play-btn:hover .media-video__play-icon svg .cls-2 {
  fill: #000;
}
.media-video__play-icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
}
.media-video__play-icon {
  height: 50px;
}
@media screen and (min-width: 320px) {
  .media-video__play-icon {
    height: calc( 50px + 5 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .media-video__play-icon {
    height: 55px;
  }
}
.media-video__play-icon {
  width: 50px;
}
@media screen and (min-width: 320px) {
  .media-video__play-icon {
    width: calc( 50px + 5 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .media-video__play-icon {
    width: 55px;
  }
}
.media-video__play-icon svg .cls-1 {
  transition: stroke 0.3s;
}
.media-video__play-icon svg .cls-2 {
  transition: fill 0.3s;
}
.media-video__play-bg {
  display: flex;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: filter 0.3s;
}
.media__col-center {
  display: grid;
  order: -1;
}
@media screen and (max-width: 991px) {
  .media__col-center {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 992px) {
  .media__col-center {
    grid-template-rows: repeat(2, 1fr);
    width: 50%;
    order: initial;
  }
}
@media screen and (max-width: 991px) {
  .media__col-left, .media__col-right {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 992px) {
  .media__col-left, .media__col-right {
    width: 25%;
  }
}
.media__col-left {
  display: grid;
  grid-template-columns: 0.6fr 0.4fr;
  grid-template-rows: 200px;
  grid-column-gap: 20px;
}
@media screen and (min-width: 992px) {
  .media__col-left {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 80px);
    grid-column-gap: initial;
  }
}
@media screen and (min-width: 1366px) {
  .media__col-left {
    grid-template-rows: repeat(6, 95px);
  }
}
@media screen and (min-width: 1536px) {
  .media__col-left {
    grid-template-rows: repeat(6, 110px);
  }
}
.media__bg {
  display: flex;
}
@media screen and (min-width: 992px) {
  .media__bg {
    padding: 10px;
  }
}
@media screen and (min-width: 1366px) {
  .media__bg {
    padding: 15px;
  }
}
.media__bg-img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .media__bg-smallest {
    grid-row: 2/3;
    grid-column: 3/4;
  }
}
@media screen and (min-width: 992px) {
  .media__bg-small {
    grid-row: 3/6;
    grid-column: 1/4;
  }
}
@media screen and (min-width: 992px) {
  .media__bg-medium {
    grid-row: 1/4;
  }
}
@media screen and (min-width: 992px) {
  .media__bg-big {
    grid-row: 1/3;
  }
}
.media__bg-large {
  height: 100%;
}
@media screen and (max-width: 991px) {
  .media__bg-large {
    max-height: 350px;
  }
}
.media__box {
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  grid-template-rows: 200px;
  grid-column-gap: 20px;
}
@media screen and (min-width: 992px) {
  .media__box {
    grid-template-rows: repeat(3, 80px);
    grid-column-gap: initial;
  }
}
@media screen and (min-width: 1366px) {
  .media__box {
    grid-template-rows: repeat(3, 95px);
  }
}
@media screen and (min-width: 1536px) {
  .media__box {
    grid-template-rows: repeat(3, 110px);
  }
}
.media__texts {
  display: grid;
  grid-template-columns: 1fr;
}
@media screen and (max-width: 991px) {
  .media__texts {
    max-width: 470px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 992px) {
  .media__texts {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
  }
}
@media screen and (max-width: 991px) {
  .media__desc {
    text-align: center;
  }
}
.media__desc-left {
  line-height: 1.4;
}
.media__desc-left {
  font-size: 18px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .media__desc-left {
    font-size: calc( 18px + 6 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .media__desc-left {
    font-size: 24px;
  }
}

.picture-header__container {
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .picture-header__container {
    margin-top: calc( 30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .picture-header__container {
    margin-top: 50px;
  }
}
.picture-header__title {
  text-align: center;
}
.picture-header__row {
  display: flex;
  position: relative;
  flex-direction: column;
}
@media screen and (min-width: 768px) {
  .picture-header__row {
    flex-direction: row;
  }
}
.picture-header__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  position: relative;
  z-index: 1;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .picture-header__box {
    align-items: flex-start;
  }
  .picture-header__box {
    padding-top: 70px;
  }
}
@media screen and (min-width: 768px) and (min-width: 320px) {
  .picture-header__box {
    padding-top: calc( 70px + (100 - 70) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 768px) and (min-width: 1920px) {
  .picture-header__box {
    padding-top: 100px;
  }
}
@media screen and (min-width: 768px) {
  .picture-header__box {
    padding-bottom: 110px;
  }
}
@media screen and (min-width: 768px) and (min-width: 320px) {
  .picture-header__box {
    padding-bottom: calc( 110px + (160 - 110) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 768px) and (min-width: 1920px) {
  .picture-header__box {
    padding-bottom: 160px;
  }
}
@media screen and (min-width: 1280px) {
  .picture-header__box {
    align-items: center;
    background: linear-gradient(90deg, #fff 50%, transparent 50%);
  }
}
.picture-header__name {
  max-width: 530px;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .picture-header__name {
    text-align: center;
  }
}
.picture-header__btn {
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .picture-header__btn {
    margin-top: calc( 30px + (45 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .picture-header__btn {
    margin-top: 45px;
  }
}
.picture-header__picture {
  display: flex;
}
@media screen and (max-width: 767px) {
  .picture-header__picture {
    order: -1;
    max-height: 360px;
    max-width: 500px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 413px) {
  .picture-header__picture {
    margin-right: -20px;
    margin-left: -20px;
  }
}
@media screen and (min-width: 768px) {
  .picture-header__picture {
    position: absolute;
    right: 0;
    top: 0;
    width: 85%;
    height: 100%;
  }
}
.picture-header__image {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.regular-text h1,
.regular-text h2,
.regular-text h3,
.regular-text h4,
.regular-text h5,
.regular-text h6 {
  font-size: initial;
  margin-bottom: 15px;
}
@media screen and (max-width: 991px) {
  .regular-text h1,
.regular-text h2,
.regular-text h3,
.regular-text h4,
.regular-text h5,
.regular-text h6 {
    text-align: center !important;
  }
}
@media screen and (min-width: 992px) {
  .regular-text h1,
.regular-text h2,
.regular-text h3,
.regular-text h4,
.regular-text h5,
.regular-text h6 {
    margin-bottom: 30px;
  }
}
.regular-text h1 {
  font-size: 44px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .regular-text h1 {
    font-size: calc( 44px + 36 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .regular-text h1 {
    font-size: 80px;
  }
}
.regular-text h2 {
  font-size: 35px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .regular-text h2 {
    font-size: calc( 35px + 13 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .regular-text h2 {
    font-size: 48px;
  }
}
.regular-text h3 {
  font-size: 24px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .regular-text h3 {
    font-size: calc( 24px + 11 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .regular-text h3 {
    font-size: 35px;
  }
}
.regular-text h4 {
  font-size: 18px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .regular-text h4 {
    font-size: calc( 18px + 7 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .regular-text h4 {
    font-size: 25px;
  }
}
.regular-text h5 {
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .regular-text h5 {
    font-size: calc( 16px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .regular-text h5 {
    font-size: 18px;
  }
}
.regular-text h6 {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .regular-text h6 {
    font-size: calc( 14px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .regular-text h6 {
    font-size: 16px;
  }
}
.regular-text p:not([class]),
.regular-text pre {
  color: #8b8b8b;
  line-height: 1.4;
  font-style: normal;
  font-family: "Lato", sans-serif;
}
.regular-text p:not([class]),
.regular-text pre {
  font-size: 13px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .regular-text p:not([class]),
.regular-text pre {
    font-size: calc( 13px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .regular-text p:not([class]),
.regular-text pre {
    font-size: 15px;
  }
}
@media screen and (max-width: 991px) {
  .regular-text p:not([class]),
.regular-text pre {
    text-align: center !important;
  }
}
.regular-text .wp-block-column {
  margin-right: 40px;
}
@media screen and (max-width: 767px) {
  .regular-text .wp-block-column {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.regular-text .wp-block-quote {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 130px !important;
  padding-bottom: 100px !important;
}
@media screen and (max-width: 991px) {
  .regular-text .wp-block-quote {
    padding-top: 100px !important;
    padding-bottom: 50px !important;
  }
}
@media screen and (max-width: 413px) {
  .regular-text .wp-block-quote {
    margin-right: -15px;
    margin-left: -15px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
.regular-text .wp-block-quote p {
  font-family: "Playfair Display", serif;
  color: #f0cbbb;
  font-style: initial !important;
  max-width: 600px;
  line-height: 1.3 !important;
  text-align: center;
}
.regular-text .wp-block-quote p {
  font-size: 18px;
  font-weight: 600;
}
@media screen and (min-width: 320px) {
  .regular-text .wp-block-quote p {
    font-size: calc( 18px + 6 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .regular-text .wp-block-quote p {
    font-size: 24px;
  }
}
.regular-text .wp-block-quote p:nth-child(1) {
  position: relative;
}
.regular-text .wp-block-quote p:nth-child(1)::after {
  content: "";
  position: absolute;
  width: 55px;
  height: 55px;
  top: -60px;
  left: -40px;
  background-image: url("../../../images/svg/quotate.svg");
  background-size: contain;
}
@media screen and (max-width: 991px) {
  .regular-text .wp-block-quote p:nth-child(1)::after {
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (min-width: 1536px) {
  .regular-text .wp-block-quote p:nth-child(1)::after {
    top: -70px;
    left: -70px;
    width: 80px;
    height: 80px;
  }
}
.regular-text .wp-block-quote cite {
  position: relative;
  z-index: 1;
  font-style: initial;
  font-family: "Lato", sans-serif;
  color: #8b8b8b;
  max-width: 600px;
  width: 100%;
  text-align: right;
}
.regular-text .wp-block-quote cite {
  font-size: 13px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .regular-text .wp-block-quote cite {
    font-size: calc( 13px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .regular-text .wp-block-quote cite {
    font-size: 15px;
  }
}
@media screen and (max-width: 991px) {
  .regular-text .wp-block-quote cite {
    text-align: center;
  }
}
.regular-text ol,
.regular-text ul {
  margin-left: 10px;
}
@media screen and (min-width: 992px) {
  .regular-text ol,
.regular-text ul {
    margin-left: 15px;
  }
}
.regular-text .wp-block-media-text__content {
  padding-left: 0 !important;
}

/*****************/
/* ~*~ Form ~*~ */
/****************/
.application-form {
  background-color: #dde6ed;
}
.application-form {
  padding-top: 50px;
}
@media screen and (min-width: 320px) {
  .application-form {
    padding-top: calc( 50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .application-form {
    padding-top: 70px;
  }
}
.application-form {
  padding-bottom: 50px;
}
@media screen and (min-width: 320px) {
  .application-form {
    padding-bottom: calc( 50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .application-form {
    padding-bottom: 70px;
  }
}
.application-form {
  margin-bottom: 0px;
}
@media screen and (min-width: 320px) {
  .application-form {
    margin-bottom: calc( 0px + (0 - 0) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .application-form {
    margin-bottom: 0px;
  }
}
.application-form .small-container .form-wrapper {
  padding-right: 0;
}
@media screen and (min-width: 992px) {
  .application-form .small-container .application-form__bg {
    transform: translate(20%, -55%);
  }
}
@media screen and (min-width: 1366px) {
  .application-form .small-container .application-form__bg {
    transform: translate(40%, -50%);
  }
}
@media screen and (min-width: 1536px) {
  .application-form .small-container .application-form__bg {
    transform: translate(50%, -50%);
  }
}
.application-form__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.application-form__wrapper {
  position: relative;
}
.application-form__wrapper {
  margin-top: 20px;
}
@media screen and (min-width: 320px) {
  .application-form__wrapper {
    margin-top: calc( 20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .application-form__wrapper {
    margin-top: 25px;
  }
}
@media screen and (max-width: 991px) {
  .application-form__wrapper {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
  }
}
.application-form__bg {
  position: absolute;
  display: none;
  right: 0;
  top: 0;
  max-width: 250px;
}
@media screen and (min-width: 992px) {
  .application-form__bg {
    display: flex;
    transform: translate(15%, -15%);
  }
}
@media screen and (min-width: 1366px) {
  .application-form__bg {
    max-width: 280px;
    transform: translate(20%, -20%);
  }
}
@media screen and (min-width: 1536px) {
  .application-form__bg {
    max-width: 320px;
    transform: translate(25%, -25%);
  }
}
.application-form__bg svg {
  width: 100%;
  height: auto;
}

.recomend {
  overflow: hidden;
}
.recomend__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.recomend__row {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 30px;
}
@media screen and (max-width: 991px) {
  .recomend__row {
    row-gap: 50px;
  }
}
@media screen and (max-width: 767px) {
  .recomend__row {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media screen and (min-width: 768px) {
  .recomend__row {
    -moz-column-gap: 40px;
         column-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 992px) {
  .recomend__row {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1366px) {
  .recomend__row {
    -moz-column-gap: 60px;
         column-gap: 60px;
  }
}
@media screen and (max-width: 767px) {
  .recomend__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.recomend__box-picture {
  display: flex;
  max-height: 300px;
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 1366px) {
  .recomend__box-picture {
    max-height: 320px;
  }
}
.recomend__box-image {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  padding: 0 !important;
}
.recomend__box-title {
  font-family: "Playfair Display", serif;
  line-height: 1.2;
  color: #000;
  text-align: left;
  margin-top: 15px;
  padding: 0 !important;
}
.recomend__box-title {
  font-size: 24px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .recomend__box-title {
    font-size: calc( 24px + 6 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .recomend__box-title {
    font-size: 30px;
  }
}
.recomend__box-title {
  margin-bottom: 20px;
}
@media screen and (min-width: 320px) {
  .recomend__box-title {
    margin-bottom: calc( 20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .recomend__box-title {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .recomend__box-title {
    text-align: center;
    max-width: 80%;
  }
}

/******************/
/* ~*~ Pages ~*~ */
/******************/
/* ----- */
/* Sale */
/* ----- */
.home-sale__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.home-sale__container {
  padding-top: 40px;
}
@media screen and (min-width: 320px) {
  .home-sale__container {
    padding-top: calc( 40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .home-sale__container {
    padding-top: 50px;
  }
}
@media screen and (max-width: 413px) {
  .home-sale__container {
    padding-right: 0;
    padding-left: 0;
  }
}
.home-sale__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.home-sale__bg {
  display: flex;
  width: 100%;
  height: 350px;
}
@media screen and (min-width: 768px) {
  .home-sale__bg {
    height: 50vw;
  }
}
@media screen and (min-width: 992px) {
  .home-sale__bg {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
  }
}
.home-sale__bg-img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  display: flex;
}
.home-sale__row {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 10px;
  justify-content: flex-start;
  width: 100%;
  z-index: 1;
}
.home-sale__row {
  margin-bottom: 40px;
}
@media screen and (min-width: 320px) {
  .home-sale__row {
    margin-bottom: calc( 40px + (55 - 40) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .home-sale__row {
    margin-bottom: 55px;
  }
}
@media screen and (min-width: 414px) {
  .home-sale__row {
    grid-gap: 15px;
    grid-template-columns: minmax(1fr, 400px);
  }
  .home-sale__row {
    margin-top: 40px;
  }
}
@media screen and (min-width: 414px) and (min-width: 320px) {
  .home-sale__row {
    margin-top: calc( 40px + (55 - 40) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 414px) and (min-width: 1920px) {
  .home-sale__row {
    margin-top: 55px;
  }
}
@media screen and (min-width: 768px) {
  .home-sale__row {
    grid-template-columns: repeat(auto-fit, calc(50% - 10px));
  }
}
@media screen and (min-width: 992px) {
  .home-sale__row {
    grid-template-columns: repeat(auto-fit, calc(28% - 15px));
    margin-right: -12.5%;
  }
}
@media screen and (min-width: 1366px) {
  .home-sale__row {
    grid-template-columns: repeat(auto-fit, calc(25% - 15px));
  }
}
.home-sale__box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-basis: 25%;
  padding-top: 60px;
  padding-bottom: 60px;
}
.home-sale__box {
  padding-left: 45px;
}
@media screen and (min-width: 320px) {
  .home-sale__box {
    padding-left: calc( 45px + (50 - 45) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .home-sale__box {
    padding-left: 50px;
  }
}
.home-sale__box {
  padding-right: 45px;
}
@media screen and (min-width: 320px) {
  .home-sale__box {
    padding-right: calc( 45px + (50 - 45) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .home-sale__box {
    padding-right: 50px;
  }
}
@media screen and (min-width: 414px) {
  .home-sale__box {
    padding-top: 35px;
  }
}
@media screen and (min-width: 414px) and (min-width: 320px) {
  .home-sale__box {
    padding-top: calc( 35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 414px) and (min-width: 1920px) {
  .home-sale__box {
    padding-top: 50px;
  }
}
@media screen and (min-width: 414px) {
  .home-sale__box {
    padding-bottom: 35px;
  }
}
@media screen and (min-width: 414px) and (min-width: 320px) {
  .home-sale__box {
    padding-bottom: calc( 35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 414px) and (min-width: 1920px) {
  .home-sale__box {
    padding-bottom: 50px;
  }
}
.home-sale__box--gray {
  background-color: #dde6ed;
}
.home-sale__box--gray .home-sale__box-desc {
  color: #8b8b8b;
}
.home-sale__box--cream {
  background-color: #f0cbbb;
}
.home-sale__box--cream .home-sale__box-btn::before {
  background-color: #dde6ed;
}
.home-sale__box--dark-gray {
  background-color: #aec4d4;
}
.home-sale__box-title {
  flex-basis: 40%;
  text-align: center;
  margin-bottom: 10px;
}
.home-sale__box-title {
  font-size: 12px;
  font-weight: 900;
}
@media screen and (min-width: 320px) {
  .home-sale__box-title {
    font-size: calc( 12px + 1 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .home-sale__box-title {
    font-size: 13px;
  }
}
.home-sale__box-desc {
  color: #fff;
  font-style: italic;
  text-align: center;
  flex-basis: 30%;
  margin-bottom: 30px;
}
.home-sale__box-desc {
  font-size: 33px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .home-sale__box-desc {
    font-size: calc( 33px + 7 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .home-sale__box-desc {
    font-size: 40px;
  }
}
.home-sale__box-desc p {
  margin-bottom: 0;
}
.home-sale__box-link {
  flex-basis: 30%;
  width: 100%;
}
.home-sale__box-btn {
  width: 100%;
}
.home-sale__btn {
  position: relative;
  z-index: 1;
}

/* ---------- */
/* Instagram */
/* --------- */
.instagram__container {
  padding: 15px;
}
@media screen and (max-width: 413px) {
  .instagram__container {
    padding: 0;
  }
  .instagram__container {
    margin-right: -6px;
  }
}
@media screen and (max-width: 413px) and (min-width: 320px) {
  .instagram__container {
    margin-right: calc( -6px + (-20 - -6) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (max-width: 413px) and (min-width: 1920px) {
  .instagram__container {
    margin-right: -20px;
  }
}
@media screen and (max-width: 413px) {
  .instagram__container {
    margin-left: -6px;
  }
}
@media screen and (max-width: 413px) and (min-width: 320px) {
  .instagram__container {
    margin-left: calc( -6px + (-20 - -6) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (max-width: 413px) and (min-width: 1920px) {
  .instagram__container {
    margin-left: -20px;
  }
}
@media screen and (min-width: 992px) {
  .instagram__container {
    padding-left: 15px;
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .instagram__container {
    padding-left: calc( 15px + (140 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .instagram__container {
    padding-left: 140px;
  }
}
@media screen and (min-width: 992px) {
  .instagram__container {
    padding-right: 15px;
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .instagram__container {
    padding-right: calc( 15px + (140 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .instagram__container {
    padding-right: 140px;
  }
}
.instagram__row {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 991px) {
  .instagram__row {
    flex-wrap: wrap;
    max-width: 500px;
    padding: 0;
  }
}
.instagram__column {
  padding-right: 6px;
}
@media screen and (min-width: 320px) {
  .instagram__column {
    padding-right: calc( 6px + (20 - 6) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .instagram__column {
    padding-right: 20px;
  }
}
.instagram__column {
  padding-left: 6px;
}
@media screen and (min-width: 320px) {
  .instagram__column {
    padding-left: calc( 6px + (20 - 6) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .instagram__column {
    padding-left: 20px;
  }
}
.instagram__column {
  padding-top: 6px;
}
@media screen and (min-width: 320px) {
  .instagram__column {
    padding-top: calc( 6px + (20 - 6) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .instagram__column {
    padding-top: 20px;
  }
}
.instagram__column {
  padding-bottom: 6px;
}
@media screen and (min-width: 320px) {
  .instagram__column {
    padding-bottom: calc( 6px + (20 - 6) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .instagram__column {
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 992px) {
  .instagram__column {
    padding-bottom: 0;
    padding-top: 0;
  }
  .instagram__column:nth-of-type(1) {
    padding-left: 0;
  }
  .instagram__column:nth-last-of-type(1) {
    padding-right: 0;
  }
}
.instagram__bg-img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.instagram__box {
  position: relative;
}
.instagram__box-btn {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
@media screen and (max-width: 413px) {
  .instagram__box-btn {
    left: 0;
    transform: translateX(50%);
  }
}
.instagram__column--small {
  width: 30%;
  align-self: flex-end;
}
@media screen and (min-width: 992px) {
  .instagram__column--small {
    width: 22%;
  }
}
.instagram__column--big {
  width: 70%;
}
@media screen and (min-width: 992px) {
  .instagram__column--big {
    width: 40%;
  }
}
.instagram__column--medium {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  min-height: 100%;
  width: 40%;
}
@media screen and (min-width: 992px) {
  .instagram__column--medium {
    width: 28%;
  }
}
.instagram__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 25%;
}
@media screen and (max-width: 991px) {
  .instagram__text--desktop {
    display: none;
  }
}
.instagram__text--mobile {
  margin-bottom: 25px;
}
@media screen and (min-width: 992px) {
  .instagram__text--mobile {
    display: none;
  }
}
.instagram__title {
  margin-bottom: 0;
}
.instagram__bg-medium {
  display: flex;
  min-height: 75%;
}
.instagram__column--smaller {
  width: 20%;
}
@media screen and (min-width: 992px) {
  .instagram__column--smaller {
    width: 10%;
  }
}
.instagram__bg-smaller {
  display: flex;
  height: 25%;
}
@media screen and (min-width: 992px) {
  .instagram__bg-smaller {
    transform: translateX(-50%);
  }
}

/* ------- */
/* Famous */
/* ------ */
.famous {
  background-color: #fff3ed;
}
.famous {
  padding-top: 60px;
}
@media screen and (min-width: 320px) {
  .famous {
    padding-top: calc( 60px + (85 - 60) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .famous {
    padding-top: 85px;
  }
}
.famous {
  padding-bottom: 45px;
}
@media screen and (min-width: 320px) {
  .famous {
    padding-bottom: calc( 45px + (140 - 45) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .famous {
    padding-bottom: 140px;
  }
}
@media screen and (max-width: 413px) {
  .famous {
    padding-top: 90px;
  }
}
@media screen and (max-width: 413px) {
  .famous__container {
    padding: 0;
  }
}
.famous__row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .famous__row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .famous__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 413px) {
  .famous__column-left {
    width: 100%;
  }
  .famous__column-right {
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .famous__column {
    width: 50%;
  }
  .famous__column-left {
    padding-right: 50px;
  }
  .famous__column-right {
    padding-left: 50px;
  }
}
@media screen and (max-width: 413px) {
  .famous__txt {
    position: relative;
  }
}
.famous__subtitle {
  display: inline-flex;
  align-items: flex-end;
}
@media screen and (max-width: 413px) {
  .famous__subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.famous__subtitle::after {
  content: "";
  width: 45px;
  height: 45px;
  margin-left: 5px;
  background-image: url("../../images/svg/star.svg");
  background-size: contain;
  transform: translateY(30%);
}
@media screen and (max-width: 413px) {
  .famous__subtitle::after {
    width: 40px;
    height: 40px;
    margin-left: 0;
    position: absolute;
    top: -40px;
    right: -10px;
    transform: none;
  }
}
.famous__widget {
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 500px;
  background-color: #243c54;
}
.famous__widget {
  padding-left: 15px;
}
@media screen and (min-width: 320px) {
  .famous__widget {
    padding-left: calc( 15px + (35 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .famous__widget {
    padding-left: 35px;
  }
}
.famous__widget {
  padding-right: 15px;
}
@media screen and (min-width: 320px) {
  .famous__widget {
    padding-right: calc( 15px + (35 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .famous__widget {
    padding-right: 35px;
  }
}
@media screen and (max-width: 767px) {
  .famous__widget {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 413px) {
  .famous__widget {
    width: 100%;
    margin-bottom: 90px;
  }
}
.famous__widget-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.famous__widget-percent {
  letter-spacing: 0.05em;
  font-family: "Lato", sans-serif;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 414px) {
  .famous__widget-btn {
    transform: translateX(50%);
  }
}
.famous__widget-desc {
  letter-spacing: 0.05em;
  color: #fff;
  font-family: "Lato", sans-serif;
}
.famous__widget-desc {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .famous__widget-desc {
    font-size: calc( 14px + 6 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .famous__widget-desc {
    font-size: 20px;
  }
}
.famous__widget-line {
  margin: 15px 0;
  border: none;
  outline: none;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.famous__widget-logo {
  display: flex;
  margin-left: auto;
  width: 145px;
}
@media screen and (max-width: 413px) {
  .famous__patient {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
}
.famous__patient-subtitle {
  display: inline-flex;
  align-items: flex-end;
}
@media screen and (max-width: 413px) {
  .famous__patient-subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.famous__patient-subtitle::after {
  content: "";
  width: 60px;
  height: 45px;
  margin-left: 15px;
  background-image: url("../../images/svg/quotate-brown.svg");
  background-size: contain;
  transform: translateY(30%);
}
@media screen and (max-width: 413px) {
  .famous__patient-subtitle::after {
    width: 43px;
    height: 33px;
    margin-left: 0;
    position: absolute;
    top: -40px;
    right: -10px;
    transform: none;
  }
}
@media screen and (max-width: 767px) {
  .famous__swiper {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .famous__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.famous__slide-desc {
  line-height: 1.4;
  color: #000;
}
.famous__slide-desc {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .famous__slide-desc {
    font-size: calc( 14px + 4 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .famous__slide-desc {
    font-size: 18px;
  }
}
.famous__slide-desc {
  margin-bottom: 10px;
}
@media screen and (min-width: 320px) {
  .famous__slide-desc {
    margin-bottom: calc( 10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .famous__slide-desc {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .famous__slide-desc {
    margin-right: 25px;
    margin-left: 25px;
    text-align: center;
  }
}
.famous__slide-name {
  color: #d0c9c2;
  font-family: "Lato", sans-serif;
}
.famous__slide-name {
  font-size: 13px;
  font-weight: 500;
}
@media screen and (min-width: 320px) {
  .famous__slide-name {
    font-size: calc( 13px + 3 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .famous__slide-name {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .famous__slide-name {
    text-align: center;
  }
}
.famous__opinions {
  position: relative;
  max-width: 560px;
}
@media screen and (max-width: 767px) {
  .famous__opinions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
.famous__controls {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.famous-pagination, .famous-button-next, .famous-button-prev {
  position: relative;
}
.famous-button-next, .famous-button-prev {
  width: 40px;
  right: initial;
  left: initial;
  height: 16px;
  margin-top: 0;
}
.famous-pagination {
  margin-right: 30px;
  margin-left: 30px;
}
.famous-pagination .swiper-pagination-bullet {
  width: 13px;
  height: 13px;
}
.famous-pagination .swiper-pagination-bullet::after {
  width: 7px;
  height: 7px;
}

.video__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.video__content {
  margin-top: 10px;
  display: flex;
  position: relative;
  width: calc(100% + 40px);
  height: 200px;
  margin-left: -20px;
  margin-right: -20px;
}
@media screen and (min-width: 414px) {
  .video__content {
    height: 37vw;
    max-height: 750px;
    min-height: 300px;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .video__content {
    min-height: 500px;
  }
}
.video__play-btn {
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  height: 100%;
  transition: transform 0.9s, opacity 0.6s;
  cursor: pointer;
  overflow: hidden;
}
.video__play-btn.is-disable {
  opacity: 0;
  pointer-events: none;
}
.video__play-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgba(174, 196, 212, 0.73);
}
.video__play-btn:hover .video__play-bg {
  filter: blur(2px);
}
.video__play-btn:hover .video__play-icon {
  opacity: 0.7;
}
.video__play-btn:hover .video__play-icon svg .cls-1 {
  stroke: #f0cbbb;
}
.video__play-btn:hover .video__play-icon svg .cls-2 {
  fill: #f0cbbb;
}
.video__play-icon {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
}
.video__play-icon {
  height: 40px;
}
@media screen and (min-width: 320px) {
  .video__play-icon {
    height: calc( 40px + 60 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .video__play-icon {
    height: 100px;
  }
}
.video__play-icon {
  width: 40px;
}
@media screen and (min-width: 320px) {
  .video__play-icon {
    width: calc( 40px + 60 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .video__play-icon {
    width: 100px;
  }
}
.video__play-icon svg .cls-1 {
  transition: stroke 0.3s;
}
.video__play-icon svg .cls-2 {
  transition: fill 0.3s;
}
.video__play-bg {
  display: flex;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: filter 0.3s;
}
.video iframe,
.video object,
.video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/***********************/
/* ~*~ Two columns ~*~ */
/**********************/
.two-columns {
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .two-columns {
    margin-top: calc( 30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .two-columns {
    margin-top: 50px;
  }
}
.two-columns__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.two-columns__row {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 70px;
}
@media screen and (min-width: 992px) {
  .two-columns__row {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 60px;
    margin-bottom: 100px;
  }
  .two-columns__row:nth-of-type(even) .two-columns__column:nth-of-type(1) {
    order: 1;
  }
}
@media screen and (max-width: 991px) {
  .two-columns__column:nth-of-type(2) {
    margin-right: -20px;
    margin-left: -20px;
    order: -1;
    margin-bottom: 30px;
  }
}
.two-columns__column-texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 992px) {
  .two-columns__column-texts {
    align-items: start;
    justify-content: flex-start;
  }
}
.two-columns__column-desc, .two-columns__column-txt {
  text-align: center;
}
@media screen and (min-width: 992px) {
  .two-columns__column-desc, .two-columns__column-txt {
    text-align: left;
  }
}
.two-columns__column-picture {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 300px;
}
@media screen and (min-width: 414px) {
  .two-columns__column-picture {
    max-height: 400px;
  }
}
@media screen and (min-width: 992px) {
  .two-columns__column-picture {
    max-height: 600px;
  }
}
.two-columns__column-img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

/********************/
/* ~*~ Gallery ~*~ */
/*******************/
.gallery__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.gallery__description {
  max-width: 800px;
}
.gallery__wrapper {
  display: grid;
  grid-template-rows: repeat(8, minmax(100px, 130px));
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-left: -20px;
  margin-right: -20px;
}
.gallery__wrapper {
  margin-top: 20px;
}
@media screen and (min-width: 320px) {
  .gallery__wrapper {
    margin-top: calc( 20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .gallery__wrapper {
    margin-top: 60px;
  }
}
@media screen and (min-width: 414px) {
  .gallery__wrapper {
    grid-template-rows: repeat(8, minmax(120px, 150px));
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 768px) {
  .gallery__wrapper {
    grid-template-rows: repeat(4, minmax(100px, 130px));
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
  }
}
@media screen and (min-width: 992px) {
  .gallery__wrapper {
    grid-template-rows: repeat(4, minmax(180px, 200px));
    grid-gap: 20px;
  }
}
@media screen and (min-width: 1440px) {
  .gallery__wrapper {
    grid-template-rows: repeat(4, minmax(200px, 250px));
    grid-gap: 40px;
  }
}
.gallery__picture {
  display: flex;
}
.gallery__picture:nth-child(1) {
  grid-area: 1/1/3/3;
}
@media screen and (min-width: 768px) {
  .gallery__picture:nth-child(1) {
    grid-area: 1/1/3/3;
  }
}
.gallery__picture:nth-child(2) {
  grid-area: 3/1/4/3;
}
@media screen and (min-width: 768px) {
  .gallery__picture:nth-child(2) {
    grid-area: 1/3/2/5;
  }
}
.gallery__picture:nth-child(3) {
  grid-area: 4/1/5/3;
}
@media screen and (min-width: 768px) {
  .gallery__picture:nth-child(3) {
    grid-area: 2/3/3/5;
  }
}
.gallery__picture:nth-child(4) {
  grid-area: 5/1/7/3;
}
@media screen and (min-width: 768px) {
  .gallery__picture:nth-child(4) {
    grid-area: 3/1/5/3;
  }
}
.gallery__picture:nth-child(5) {
  grid-area: 7/1/9/2;
}
@media screen and (min-width: 768px) {
  .gallery__picture:nth-child(5) {
    grid-area: 3/3/5/4;
  }
}
.gallery__picture:nth-child(6) {
  grid-area: 7/2/9/3;
}
@media screen and (min-width: 768px) {
  .gallery__picture:nth-child(6) {
    grid-area: 3/4/5/5;
  }
}
.gallery__img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

.pricelist {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
.pricelist {
  padding-top: 30px;
}
@media screen and (min-width: 320px) {
  .pricelist {
    padding-top: calc( 30px + (95 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .pricelist {
    padding-top: 95px;
  }
}
.pricelist {
  padding-bottom: 30px;
}
@media screen and (min-width: 320px) {
  .pricelist {
    padding-bottom: calc( 30px + (95 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .pricelist {
    padding-bottom: 95px;
  }
}
.pricelist--sm {
  padding-top: 10px;
}
@media screen and (min-width: 320px) {
  .pricelist--sm {
    padding-top: calc( 10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .pricelist--sm {
    padding-top: 15px;
  }
}
.pricelist--sm {
  padding-bottom: 10px;
}
@media screen and (min-width: 320px) {
  .pricelist--sm {
    padding-bottom: calc( 10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .pricelist--sm {
    padding-bottom: 15px;
  }
}
.pricelist--cream {
  background-color: #f0cbbb;
}
.pricelist--cream .pricelist__btn::before {
  background-color: #aec4d4;
}
.pricelist--gray {
  background-color: #dde6ed;
}
.pricelist-bg--mobile {
  display: flex;
}
@media screen and (min-width: 992px) {
  .pricelist-bg--mobile {
    display: none;
  }
}
.pricelist-bg--desktop {
  display: none;
}
@media screen and (min-width: 992px) {
  .pricelist-bg--desktop {
    display: flex;
  }
}
.pricelist-bg svg {
  width: 100%;
}
.pricelist__btn {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.sale__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sale__container {
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .sale__container {
    margin-top: calc( 30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .sale__container {
    margin-top: 50px;
  }
}
.sale__description {
  text-align: center;
  max-width: 800px;
}
.sale__list {
  display: grid;
  grid-template-columns: minmax(1fr, 400px);
  grid-auto-rows: 1fr;
  grid-gap: 15px;
  justify-content: center;
  width: 100%;
}
.sale__list {
  margin-top: 60px;
}
@media screen and (min-width: 320px) {
  .sale__list {
    margin-top: calc( 60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .sale__list {
    margin-top: 80px;
  }
}
@media screen and (min-width: 768px) {
  .sale__list {
    grid-template-columns: repeat(auto-fit, calc(50% - 15px));
  }
}
@media screen and (min-width: 992px) {
  .sale__list {
    grid-template-columns: repeat(auto-fit, calc(33.33% - 15px));
  }
}
@media screen and (min-width: 1366px) {
  .sale__list {
    grid-template-columns: repeat(auto-fit, calc(25% - 15px));
  }
}
.sale__box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-basis: 25%;
}
.sale__box {
  padding-left: 45px;
}
@media screen and (min-width: 320px) {
  .sale__box {
    padding-left: calc( 45px + (50 - 45) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .sale__box {
    padding-left: 50px;
  }
}
.sale__box {
  padding-right: 45px;
}
@media screen and (min-width: 320px) {
  .sale__box {
    padding-right: calc( 45px + (50 - 45) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .sale__box {
    padding-right: 50px;
  }
}
.sale__box {
  padding-top: 35px;
}
@media screen and (min-width: 320px) {
  .sale__box {
    padding-top: calc( 35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .sale__box {
    padding-top: 50px;
  }
}
.sale__box {
  padding-bottom: 35px;
}
@media screen and (min-width: 320px) {
  .sale__box {
    padding-bottom: calc( 35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .sale__box {
    padding-bottom: 50px;
  }
}
.sale__box--gray {
  background-color: #dde6ed;
}
.sale__box--gray .sale__box-desc {
  color: #8b8b8b;
}
.sale__box--cream {
  background-color: #f0cbbb;
}
.sale__box--cream .sale__box-btn::before {
  background-color: #dde6ed;
}
.sale__box--dark-gray {
  background-color: #aec4d4;
}
.sale__box-title {
  flex-basis: 40%;
  text-align: center;
  margin-bottom: 10px;
}
.sale__box-title {
  font-size: 12px;
  font-weight: 900;
}
@media screen and (min-width: 320px) {
  .sale__box-title {
    font-size: calc( 12px + 1 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .sale__box-title {
    font-size: 13px;
  }
}
.sale__box-desc {
  color: #fff;
  font-style: italic;
  text-align: center;
  flex-basis: 30%;
  margin-bottom: 30px;
}
.sale__box-desc {
  font-size: 33px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .sale__box-desc {
    font-size: calc( 33px + 7 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .sale__box-desc {
    font-size: 40px;
  }
}
.sale__box-desc p {
  margin-bottom: 0;
}
.sale__box-link {
  flex-basis: 30%;
  width: 100%;
}
.sale__box-btn {
  width: 100%;
}

.team__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.team__container {
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .team__container {
    margin-top: calc( 30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .team__container {
    margin-top: 50px;
  }
}
@media screen and (max-width: 413px) {
  .team__container {
    padding-left: 0;
    padding-right: 0;
  }
}
.team__categories {
  display: flex;
  align-items: center;
  font-family: "Lato", sans-serif;
}
.team__categories-txt {
  color: #000;
  opacity: 0.5;
  margin-right: 25px;
}
.team__categories-txt {
  font-size: 15px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .team__categories-txt {
    font-size: calc( 15px + 0 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .team__categories-txt {
    font-size: 15px;
  }
}
.team__category {
  letter-spacing: 0.05em;
  padding: 8px 20px;
  color: #000;
  cursor: pointer;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}
.team__category {
  font-size: 15px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .team__category {
    font-size: calc( 15px + 0 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .team__category {
    font-size: 15px;
  }
}
.team__category.mixitup-control-active {
  color: #fff;
  background-color: #f0cbbb;
}
.team__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  position: relative;
}
.team__list {
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .team__list {
    margin-top: calc( 30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .team__list {
    margin-top: 40px;
  }
}
@media screen and (max-width: 991px) {
  .team__list {
    max-width: 550px;
    justify-content: space-between;
  }
}
.team__list-item {
  width: 100%;
}
@media screen and (min-width: 414px) {
  .team__list-item {
    width: 50%;
  }
}
@media screen and (min-width: 992px) {
  .team__list-item {
    width: 33%;
  }
}
.team__box {
  cursor: pointer;
  margin: 20px 70px;
  transition: transform 0.7s;
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 414px) {
  .team__box {
    margin: 20px;
  }
}
@media screen and (min-width: 768px) {
  .team__box {
    margin: 20px 40px;
  }
}
@media screen and (min-width: 1280px) {
  .team__box {
    margin: 20px 60px;
  }
}
@media screen and (min-width: 1366px) {
  .team__box {
    margin: 20px 100px;
  }
}
@media screen and (min-width: 414px) {
  .team__box.is-active {
    transform: translateY(180px);
  }
}
.team__box.is-active .team__box-name,
.team__box.is-active .team__box-position {
  color: #fff;
}
.team__box.is-active .team__box-title {
  color: #fff;
}
.team__box-bg {
  max-height: 260px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.team__box-title {
  display: flex;
  color: #000;
  transition: color 0.3s;
  margin-top: 15px;
}
.team__box-title {
  font-size: 14px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .team__box-title {
    font-size: calc( 14px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .team__box-title {
    font-size: 16px;
  }
}
.team__box-name {
  color: #000;
  margin-top: 3px;
  margin-bottom: 10px;
  transition: color 0.3s;
}
.team__box-name {
  font-size: 18px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .team__box-name {
    font-size: calc( 18px + 6 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .team__box-name {
    font-size: 24px;
  }
}
.team__box-position {
  display: flex;
  font-family: "Lato", sans-serif;
  font-style: italic;
  color: #8b8b8b;
  transition: color 0.3s;
}
.team__box-position {
  font-size: 13px;
  font-weight: 500;
}
@media screen and (min-width: 320px) {
  .team__box-position {
    font-size: calc( 13px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .team__box-position {
    font-size: 15px;
  }
}
.team__hide {
  color: #fff;
  background: #aec4d4;
}
@media screen and (max-width: 413px) {
  .team__hide {
    will-change: margin;
    transition: margin 0.8s;
  }
}
@media screen and (max-width: 413px) {
  .team__hide.is-active {
    margin-top: -180px;
  }
}
.team__hide.is-active .team__hide-big-txt,
.team__hide.is-active .team__hide-small-txt {
  opacity: 1;
}
.team__hide-container {
  padding: 190px 25px 50px;
}
.team__hide-big-txt, .team__hide-small-txt {
  font-family: "Lato", sans-serif;
  line-height: 1.4;
  opacity: 0;
  transition: 0.5s;
  transition-delay: 0.5s;
  white-space: pre-wrap;
}
.team__hide-big-txt {
  margin-bottom: 20px;
}
.team__hide-big-txt {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .team__hide-big-txt {
    font-size: calc( 14px + 3 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .team__hide-big-txt {
    font-size: 17px;
  }
}
.team__hide-small-txt {
  font-size: 13px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .team__hide-small-txt {
    font-size: calc( 13px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .team__hide-small-txt {
    font-size: 15px;
  }
}

/*******************/
/* ~*~ Header ~*~ */
/******************/
.cat-header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cat-header__container {
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .cat-header__container {
    margin-top: calc( 30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .cat-header__container {
    margin-top: 50px;
  }
}
.cat-header__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .cat-header__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .cat-header__col {
    width: 50%;
  }
}
.cat-header__col-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  order: 1;
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .cat-header__col-left {
    justify-content: flex-start;
    order: initial;
  }
  .cat-header__col-left {
    padding-right: 50px;
  }
}
@media screen and (min-width: 768px) and (min-width: 320px) {
  .cat-header__col-left {
    padding-right: calc( 50px + (150 - 50) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 768px) and (min-width: 1920px) {
  .cat-header__col-left {
    padding-right: 150px;
  }
}
@media screen and (max-width: 413px) {
  .cat-header__col-right {
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media screen and (max-width: 767px) {
  .cat-header__desc, .cat-header__name {
    text-align: center;
  }
}
.cat-header__picture {
  display: flex;
  max-width: 400px;
}
@media screen and (min-width: 768px) {
  .cat-header__picture {
    max-width: 650px;
  }
}
.cat-header__image {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

/***********************/
/* ~*~ Categories ~*~ */
/**********************/
.cat-list__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cat-list__row {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
}
.cat-list__row {
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .cat-list__row {
    margin-top: calc( 30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .cat-list__row {
    margin-top: 60px;
  }
}
@media screen and (max-width: 991px) {
  .cat-list__row {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 25px;
  }
}
@media screen and (max-width: 413px) {
  .cat-list__row {
    margin-left: -20px;
    grid-row-gap: 5px;
    padding-right: 30px;
  }
}
@media screen and (min-width: 992px) {
  .cat-list__row {
    grid-template-columns: 1fr 1fr;
    padding-right: 60px;
    grid-column-gap: 15%;
    grid-row-gap: 25px;
  }
}
@media screen and (min-width: 1366px) {
  .cat-list__row {
    grid-row-gap: 35px;
  }
}
.cat-list__btn {
  text-align: left;
  justify-content: flex-start;
  white-space: initial;
  width: 100%;
}

/************************/
/* ~*~ Offer detail ~*~ */
/***********************/
.offer-detail__row {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 50px;
}
@media screen and (min-width: 992px) {
  .offer-detail__row {
    grid-template-columns: 0.4fr 0.6fr;
    grid-gap: 30px;
  }
}
@media screen and (min-width: 1366px) {
  .offer-detail__row {
    grid-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 991px) {
  .offer-detail__box {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 991px) {
  .offer-detail__name {
    text-align: center;
  }
}
@media screen and (max-width: 991px) {
  .offer-detail__desc {
    text-align: center;
    max-width: 550px;
  }
}
.offer-detail__desc ul li,
.offer-detail__desc ol li {
  padding-left: 18px;
}
.offer-detail__desc ul li,
.offer-detail__desc ol li {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .offer-detail__desc ul li,
.offer-detail__desc ol li {
    font-size: calc( 14px + 0 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .offer-detail__desc ul li,
.offer-detail__desc ol li {
    font-size: 14px;
  }
}
.offer-detail__desc ul li,
.offer-detail__desc ol li {
  margin-bottom: 10px;
}
@media screen and (min-width: 320px) {
  .offer-detail__desc ul li,
.offer-detail__desc ol li {
    margin-bottom: calc( 10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .offer-detail__desc ul li,
.offer-detail__desc ol li {
    margin-bottom: 20px;
  }
}
.offer-detail__desc ul li::before {
  width: 11px;
  height: 11px;
}
.offer-detail__bg {
  display: flex;
  position: relative;
  margin-top: 50px;
}
@media screen and (max-width: 991px) {
  .offer-detail__bg {
    display: none;
  }
}
.offer-detail__bg svg {
  position: absolute;
  left: 0;
  top: 0;
}

/****************/
/* ~*~ Help~*~ */
/***************/
.help__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.help__row {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 50px;
}
.help__row {
  margin-top: 20px;
}
@media screen and (min-width: 320px) {
  .help__row {
    margin-top: calc( 20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .help__row {
    margin-top: 30px;
  }
}
@media screen and (min-width: 414px) {
  .help__row {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}
@media screen and (min-width: 768px) {
  .help__row {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
  }
}
@media screen and (min-width: 992px) {
  .help__row {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 1280px) {
  .help__row {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 50px;
  }
}
.help__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  justify-content: flex-start;
}
.help__box-bg-color {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  max-height: 180px;
  max-width: 180px;
}
.help__box-bg-color {
  margin-bottom: 15px;
}
@media screen and (min-width: 320px) {
  .help__box-bg-color {
    margin-bottom: calc( 15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .help__box-bg-color {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 1440px) {
  .help__box-bg-color {
    max-height: 200px;
    max-width: 200px;
  }
}
@media screen and (max-width: 413px) {
  .help__box-bg-color {
    max-width: 150px;
    max-height: 150px;
  }
}
.help__box-bg-color svg {
  width: 100%;
  height: auto;
  display: block;
}
.help__box-bg-color svg * {
  fill: var(--bg-color);
}
.help__box-bg-img {
  display: block;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.help__box-bg-img svg {
  max-height: 160px;
  max-width: 160px;
}
@media screen and (min-width: 1440px) {
  .help__box-bg-img svg {
    max-height: 180px;
    max-width: 180px;
  }
}
@media screen and (max-width: 413px) {
  .help__box-bg-img svg {
    max-width: 130px;
    max-height: 130px;
  }
}
.help__box-bg-img svg * {
  fill: transparent;
}
.help__box-title {
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 180px;
}
.help__box-title {
  font-size: 18px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .help__box-title {
    font-size: calc( 18px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .help__box-title {
    font-size: 20px;
  }
}

/********************/
/* ~*~ Details ~*~ */
/*******************/
.training-details__wrapper {
  display: grid;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 992px) {
  .training-details__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 991px) {
  .training-details__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.training-details__box--time, .training-details__box--level, .training-details__box--price {
  margin-bottom: 50px;
}
@media screen and (min-width: 320px) {
  .training-details__box--time, .training-details__box--level, .training-details__box--price {
    margin-bottom: calc( 50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .training-details__box--time, .training-details__box--level, .training-details__box--price {
    margin-bottom: 70px;
  }
}
.training-details__box-level {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 991px) {
  .training-details__box--desc {
    order: -1;
  }
  .training-details__box--desc {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 991px) and (min-width: 320px) {
  .training-details__box--desc {
    margin-bottom: calc( 50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (max-width: 991px) and (min-width: 1920px) {
  .training-details__box--desc {
    margin-bottom: 70px;
  }
}
.training-details__balls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 9px 18px;
  margin-right: 20px;
  background-color: #f0cbbb;
}
@media screen and (min-width: 992px) {
  .training-details__balls {
    margin-right: 25px;
    padding: 11px 22px;
  }
}
@media screen and (max-width: 991px) {
  .training-details__time {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.training-details__time-hours {
  letter-spacing: 0.02em;
  color: #8b8b8b;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.training-details__time-hours {
  font-size: 18px;
  font-weight: 900;
}
@media screen and (min-width: 320px) {
  .training-details__time-hours {
    font-size: calc( 18px + 12 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .training-details__time-hours {
    font-size: 30px;
  }
}
.training-details__time-subtitle {
  font-weight: 300;
}
@media screen and (max-width: 991px) {
  .training-details__time-subtitle {
    text-align: center;
  }
}
.training-details__level {
  text-transform: capitalize;
}
.training-details__price {
  letter-spacing: 0.02em;
  color: #8b8b8b;
  font-family: "Lato", sans-serif;
  margin-bottom: 10px;
}
.training-details__price {
  font-size: 18px;
  font-weight: 900;
}
@media screen and (min-width: 320px) {
  .training-details__price {
    font-size: calc( 18px + 12 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .training-details__price {
    font-size: 30px;
  }
}
.training-details__desc {
  max-width: 630px;
}
@media screen and (max-width: 991px) {
  .training-details__desc {
    text-align: center;
    max-width: 500px;
  }
}
.training-details__program {
  width: 100%;
  max-width: 500px;
}
.training-details__program {
  margin-bottom: 50px;
}
@media screen and (min-width: 320px) {
  .training-details__program {
    margin-bottom: calc( 50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .training-details__program {
    margin-bottom: 70px;
  }
}
@media screen and (min-width: 992px) {
  .training-details__program {
    -moz-column-count: 2;
         column-count: 2;
    -moz-column-gap: 30px;
         column-gap: 30px;
    max-width: initial;
  }
}
.training-details__methods {
  max-width: 630px;
  padding-bottom: 50px;
}
@media screen and (max-width: 991px) {
  .training-details__methods {
    text-align: center;
    padding-bottom: 70px;
    max-width: 500px;
  }
}
.training-details__bg {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 991px) {
  .training-details__bg {
    display: none;
  }
}
.training-details__bg svg {
  margin-top: 50px;
  margin-bottom: -50px;
  max-width: 350px;
  width: 18vw;
  height: auto;
}

/* ------ */
/* Header */
/* ----- */
.training-header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.training-header__container {
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .training-header__container {
    margin-top: calc( 30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .training-header__container {
    margin-top: 50px;
  }
}
.training-header__title {
  text-align: center;
}
.training-header__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
  background-color: #aec4d4;
}
.training-header__row {
  padding-top: 40px;
}
@media screen and (min-width: 320px) {
  .training-header__row {
    padding-top: calc( 40px + (80 - 40) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .training-header__row {
    padding-top: 80px;
  }
}
.training-header__row {
  padding-left: 10px;
}
@media screen and (min-width: 320px) {
  .training-header__row {
    padding-left: calc( 10px + (75 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .training-header__row {
    padding-left: 75px;
  }
}
.training-header__row {
  padding-right: 10px;
}
@media screen and (min-width: 320px) {
  .training-header__row {
    padding-right: calc( 10px + (125 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .training-header__row {
    padding-right: 125px;
  }
}
@media screen and (max-width: 413px) {
  .training-header__row {
    margin-right: -20px;
    margin-left: -20px;
  }
}
@media screen and (min-width: 992px) {
  .training-header__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .training-header__row {
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .training-header__row {
    padding-bottom: calc( 40px + (80 - 40) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .training-header__row {
    padding-bottom: 80px;
  }
}
.training-header__name {
  color: #fff;
  font-style: initial;
}
@media screen and (max-width: 991px) {
  .training-header__name {
    text-align: center;
  }
}
.training-header__desc {
  color: #fff;
  max-width: 500px;
}
@media screen and (max-width: 991px) {
  .training-header__desc {
    margin-bottom: 30px;
    text-align: center;
  }
}
@media screen and (min-width: 992px) {
  .training-header__desc {
    margin-left: 30px;
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .training-header__desc {
    margin-left: calc( 30px + (75 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .training-header__desc {
    margin-left: 75px;
  }
}
@media screen and (min-width: 992px) {
  .training-header__desc {
    margin-right: 30px;
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .training-header__desc {
    margin-right: calc( 30px + (75 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .training-header__desc {
    margin-right: 75px;
  }
}
.training-header__bg {
  position: absolute;
  bottom: -120px;
  right: 50%;
  max-width: 120px;
  display: flex;
  transform: translateX(50%);
}
@media screen and (min-width: 992px) {
  .training-header__bg {
    max-width: 11vw;
    bottom: -25%;
    right: 0;
  }
}
.training-header__bg svg {
  width: 100%;
}

/* ----- */
/* List */
/* ----- */
.training__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.training__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
@media screen and (min-width: 992px) {
  .training__list {
    margin-top: 120px;
  }
}
@media screen and (max-width: 991px) {
  .training__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}
@media screen and (max-width: 413px) {
  .training__box {
    width: 100%;
  }
}
.training__content {
  position: relative;
  display: inline-flex;
}
@media screen and (max-width: 1279px) {
  .training__content {
    flex-direction: column;
  }
}
@media screen and (max-width: 413px) {
  .training__content {
    width: 100%;
  }
}
.training__item {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.training__item {
  margin-bottom: 60px;
}
@media screen and (min-width: 320px) {
  .training__item {
    margin-bottom: calc( 60px + (150 - 60) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .training__item {
    margin-bottom: 150px;
  }
}
@media screen and (min-width: 992px) {
  .training__item {
    width: 50%;
  }
}
@media screen and (max-width: 1279px) {
  .training__item:nth-child(even) {
    padding-right: 20px;
  }
}
@media screen and (max-width: 991px) {
  .training__item:nth-child(even) {
    padding-right: 0;
  }
}
@media screen and (min-width: 992px) {
  .training__item:nth-child(even) {
    padding-left: 20px;
    align-items: flex-end;
  }
}
.training__item:nth-child(even) .training__content {
  display: flex;
}
@media screen and (max-width: 1279px) {
  .training__item:nth-child(odd) {
    padding-left: 20px;
    margin-top: -80px;
  }
}
@media screen and (max-width: 991px) {
  .training__item:nth-child(odd) {
    padding-left: 0;
    margin-top: 0;
  }
}
@media screen and (min-width: 992px) {
  .training__item:nth-child(odd) {
    margin-top: -100px;
    padding-right: 20px;
  }
}
.training__item-picture {
  display: inline-flex;
  max-width: 350px;
  max-height: 350px;
  position: relative;
}
@media screen and (max-width: 1279px) {
  .training__item-picture {
    order: -1;
  }
}
@media screen and (max-width: 413px) {
  .training__item-picture {
    margin-left: -20px;
    margin-right: -20px;
    max-height: 260px;
    max-width: initial;
  }
}
@media screen and (min-width: 1920px) {
  .training__item-picture {
    max-width: 400px;
    max-height: 400px;
  }
}
.training__item-image {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.training__item-info {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.training__item-info {
  margin-bottom: -45px;
}
@media screen and (min-width: 320px) {
  .training__item-info {
    margin-bottom: calc( -45px + (-50 - -45) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .training__item-info {
    margin-bottom: -50px;
  }
}
@media screen and (max-width: 1279px) {
  .training__item-info {
    margin-bottom: 20px;
    margin-top: 15px;
    align-items: center;
  }
}
.training__item-level {
  display: inline-flex;
  padding: 10px 25px;
  color: #000;
  font-family: "Lato", sans-serif;
  background-color: #aec4d4;
  transform: translateY(-50%);
}
.training__item-level {
  font-size: 13px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .training__item-level {
    font-size: calc( 13px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .training__item-level {
    font-size: 15px;
  }
}
@media screen and (min-width: 768px) {
  .training__item-level {
    padding: 10px 35px;
  }
}
@media screen and (max-width: 1279px) {
  .training__item-level {
    transform: initial;
    position: relative;
  }
}
.training__item-title {
  display: inline-flex;
  color: #000;
  top: 50%;
  right: 0;
  text-align: center;
  position: absolute;
  max-width: 300px;
  z-index: 2;
  transform: translate(50%, -50%);
}
.training__item-title {
  font-size: 28px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .training__item-title {
    font-size: calc( 28px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .training__item-title {
    font-size: 30px;
  }
}
@media screen and (max-width: 1279px) {
  .training__item-title {
    justify-content: flex-start;
    position: relative;
    transform: initial;
    top: initial;
    right: initial;
    margin-top: 15px;
    text-align: left;
  }
}
@media screen and (max-width: 991px) {
  .training__item-title {
    justify-content: center;
    text-align: center;
  }
}
@media screen and (max-width: 413px) {
  .training__item-desc {
    text-align: center;
  }
}
.training__item-buttons {
  display: inline-flex;
  padding-top: 10px;
}
@media screen and (max-width: 413px) {
  .training__item-buttons {
    flex-direction: column;
    margin: 0 auto;
  }
}
@media screen and (max-width: 413px) {
  .training__item-btn {
    width: 100%;
  }
}
.training__item-price {
  color: #000;
  font-family: "Lato", sans-serif;
  display: flex;
  align-items: center;
  min-height: 100%;
  padding-right: 35px;
  padding-left: 35px;
  margin-right: 15px;
  border: 1px solid #f0cbbb;
}
.training__item-price {
  font-size: 15px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .training__item-price {
    font-size: calc( 15px + 0 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .training__item-price {
    font-size: 15px;
  }
}
.training__item-price {
  padding-top: 13px;
}
@media screen and (min-width: 320px) {
  .training__item-price {
    padding-top: calc( 13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .training__item-price {
    padding-top: 15px;
  }
}
.training__item-price {
  padding-bottom: 13px;
}
@media screen and (min-width: 320px) {
  .training__item-price {
    padding-bottom: calc( 13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .training__item-price {
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 413px) {
  .training__item-price {
    margin-bottom: 15px;
    margin-right: 0;
    min-height: initial;
  }
}

.knowledge__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
@media screen and (min-width: 992px) {
  .knowledge__list {
    margin-top: 150px;
  }
}
.knowledge__box {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 413px) {
  .knowledge__box {
    flex-direction: column;
  }
}
.knowledge__item {
  margin-bottom: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media screen and (min-width: 992px) {
  .knowledge__item {
    width: 50%;
  }
  .knowledge__item {
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .knowledge__item {
    margin-bottom: calc( 60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .knowledge__item {
    margin-bottom: 80px;
  }
}
@media screen and (min-width: 992px) {
  .knowledge__item:nth-child(even) {
    padding-left: 20px;
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .knowledge__item:nth-child(even) {
    padding-left: calc( 20px + (80 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .knowledge__item:nth-child(even) {
    padding-left: 80px;
  }
}
.knowledge__item:nth-child(odd) {
  margin-top: -80px;
}
@media screen and (max-width: 991px) {
  .knowledge__item:nth-child(odd) {
    margin-top: 0;
  }
}
@media screen and (min-width: 992px) {
  .knowledge__item:nth-child(odd) {
    margin-top: -100px;
  }
  .knowledge__item:nth-child(odd) {
    padding-right: 20px;
  }
}
@media screen and (min-width: 992px) and (min-width: 320px) {
  .knowledge__item:nth-child(odd) {
    padding-right: calc( 20px + (80 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 992px) and (min-width: 1920px) {
  .knowledge__item:nth-child(odd) {
    padding-right: 80px;
  }
}
.knowledge__item-btn {
  margin-top: 10px;
}
.knowledge__item-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
}
.knowledge__item-info {
  padding-top: 20px;
}
@media screen and (min-width: 320px) {
  .knowledge__item-info {
    padding-top: calc( 20px + (90 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .knowledge__item-info {
    padding-top: 90px;
  }
}
.knowledge__item-info {
  padding-bottom: 20px;
}
@media screen and (min-width: 320px) {
  .knowledge__item-info {
    padding-bottom: calc( 20px + (90 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .knowledge__item-info {
    padding-bottom: 90px;
  }
}
@media screen and (min-width: 992px) {
  .knowledge__item-info {
    background: linear-gradient(90deg, transparent 50%, #fff 50%);
  }
}
.knowledge__item-title {
  font-family: "Playfair Display", serif;
  line-height: 1.2;
  color: #000;
  text-align: center;
  max-width: 380px;
}
.knowledge__item-title {
  font-size: 30px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .knowledge__item-title {
    font-size: calc( 30px + 10 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .knowledge__item-title {
    font-size: 40px;
  }
}
.knowledge__item-title {
  margin-bottom: 15px;
}
@media screen and (min-width: 320px) {
  .knowledge__item-title {
    margin-bottom: calc( 15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .knowledge__item-title {
    margin-bottom: 25px;
  }
}
.knowledge__item-picture {
  display: flex;
}
@media screen and (max-width: 413px) {
  .knowledge__item-picture {
    max-height: 300px;
  }
}
@media screen and (min-width: 414px) {
  .knowledge__item-picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.knowledge__item-image {
  -o-object-fit: cover;
     object-fit: cover;
}
@media screen and (min-width: 414px) {
  .knowledge__item-image {
    width: 70%;
  }
}

/*********************/
/* ~*~ Pricelist ~*~ */
/********************/
.tariff__title {
  text-align: center;
}
.tariff__title {
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .tariff__title {
    margin-top: calc( 30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__title {
    margin-top: 50px;
  }
}
.tariff .search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 650px;
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}
.tariff .search-input {
  letter-spacing: 0.05em;
  width: 100%;
  color: #000;
  font-family: "Lato", sans-serif;
  background-color: #fff;
  border-color: #000;
  margin-right: -5px;
  height: 100%;
}
.tariff .search-input {
  padding-top: 14px;
}
@media screen and (min-width: 320px) {
  .tariff .search-input {
    padding-top: calc( 14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff .search-input {
    padding-top: 17px;
  }
}
.tariff .search-input {
  padding-bottom: 14px;
}
@media screen and (min-width: 320px) {
  .tariff .search-input {
    padding-bottom: calc( 14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff .search-input {
    padding-bottom: 17px;
  }
}
.tariff .search-input {
  padding-right: 18px;
}
@media screen and (min-width: 320px) {
  .tariff .search-input {
    padding-right: calc( 18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff .search-input {
    padding-right: 20px;
  }
}
.tariff .search-input {
  padding-left: 18px;
}
@media screen and (min-width: 320px) {
  .tariff .search-input {
    padding-left: calc( 18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff .search-input {
    padding-left: 20px;
  }
}
.tariff .search-input {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .tariff .search-input {
    font-size: calc( 14px + 1 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff .search-input {
    font-size: 15px;
  }
}
.tariff .search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: stretch;
  background-color: #000;
  padding: 15px;
}
.tariff .search-container.is-active .close-icon {
  transform: translate(50%, -50%);
}
.tariff .search-container.is-active .search-icon {
  transform: translateY(200%);
}
.tariff .search .close-icon {
  position: absolute;
  right: 50%;
  top: 50%;
  cursor: pointer;
  transform: translate(50%, -200%);
  transition: transform 0.6s;
}
.tariff .search .search-icon {
  transition: transform 0.6s;
}
.tariff__filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}
.tariff__filters-title {
  letter-spacing: 0.05em;
  font-family: "Lato", sans-serif;
  color: #000;
  opacity: 0.5;
  margin-right: 15px;
}
.tariff__filters-title {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .tariff__filters-title {
    font-size: calc( 14px + 1 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__filters-title {
    font-size: 15px;
  }
}
.tariff__options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.tariff__filter {
  letter-spacing: 0.05em;
  font-family: "Lato", sans-serif;
  color: #000;
  padding: 7px 17px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  cursor: pointer;
}
.tariff__filter {
  font-size: 14px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .tariff__filter {
    font-size: calc( 14px + 1 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__filter {
    font-size: 15px;
  }
}
.tariff__filter.mixitup-control-active {
  font-weight: 500;
  color: #fff;
  background-color: #f0cbbb;
}
.tariff__list {
  background-color: #fff;
}
@media screen and (max-width: 767px) {
  .tariff__list {
    margin-right: -20px;
    margin-left: -20px;
  }
}
.tariff__item {
  margin-bottom: 3px;
}
.tariff__item--light-gray .tariff__item-display {
  background-color: #f7f9fa;
}
.tariff__item--light-gray .tariff__subitem {
  border-bottom: 1px solid rgba(247, 249, 250, 0.6);
}
.tariff__item--dark-cream .tariff__item-display {
  background-color: #f0cbbb;
}
.tariff__item--dark-cream .tariff__subitem {
  border-bottom: 1px solid rgba(240, 203, 187, 0.6);
}
.tariff__item--light-cream .tariff__item-display {
  background-color: #fff3ed;
}
.tariff__item--light-cream .tariff__subitem {
  border-bottom: 1px solid rgba(255, 243, 237, 0.6);
}
.tariff__item--gray .tariff__item-display {
  background-color: #dde6ed;
}
.tariff__item--gray .tariff__subitem {
  border-bottom: 1px solid rgba(221, 230, 237, 0.6);
}
.tariff__item-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.tariff__item-display {
  padding-top: 15px;
}
@media screen and (min-width: 320px) {
  .tariff__item-display {
    padding-top: calc( 15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__item-display {
    padding-top: 25px;
  }
}
.tariff__item-display {
  padding-bottom: 15px;
}
@media screen and (min-width: 320px) {
  .tariff__item-display {
    padding-bottom: calc( 15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__item-display {
    padding-bottom: 25px;
  }
}
.tariff__item-display {
  padding-right: 15px;
}
@media screen and (min-width: 320px) {
  .tariff__item-display {
    padding-right: calc( 15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__item-display {
    padding-right: 40px;
  }
}
.tariff__item-display {
  padding-left: 15px;
}
@media screen and (min-width: 320px) {
  .tariff__item-display {
    padding-left: calc( 15px + (60 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__item-display {
    padding-left: 60px;
  }
}
.tariff__item-display--disable {
  cursor: default;
}
.tariff__item-display.is-active .tariff__item-plus {
  opacity: 0;
}
.tariff__item-display.is-active .tariff__item-minus {
  opacity: 1;
}
.tariff__item-title {
  letter-spacing: 0.023em;
  color: #000;
  padding-right: 15px;
  text-align: left;
  line-height: 1.4;
}
.tariff__item-title {
  font-size: 14px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .tariff__item-title {
    font-size: calc( 14px + 6 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__item-title {
    font-size: 20px;
  }
}
.tariff__item-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .tariff__item-btn {
    width: 18px;
    height: 18px;
  }
}
.tariff__item-plus {
  opacity: 1;
  transition: opacity 0.4s;
}
.tariff__item-minus {
  position: absolute;
  top: 50%;
  right: 50%;
  opacity: 0;
  transform: translate(50%, -50%);
  transition: opacity 0.4s;
}
.tariff__subitem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
}
.tariff__subitem {
  padding-top: 10px;
}
@media screen and (min-width: 320px) {
  .tariff__subitem {
    padding-top: calc( 10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem {
    padding-top: 15px;
  }
}
.tariff__subitem {
  padding-bottom: 10px;
}
@media screen and (min-width: 320px) {
  .tariff__subitem {
    padding-bottom: calc( 10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem {
    padding-bottom: 15px;
  }
}
.tariff__subitem {
  padding-right: 15px;
}
@media screen and (min-width: 320px) {
  .tariff__subitem {
    padding-right: calc( 15px + (40 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem {
    padding-right: 40px;
  }
}
.tariff__subitem {
  padding-left: 15px;
}
@media screen and (min-width: 320px) {
  .tariff__subitem {
    padding-left: calc( 15px + (60 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem {
    padding-left: 60px;
  }
}
.tariff__subitem:nth-last-child(1) {
  border: 0;
}
.tariff__subitem--category {
  padding-left: 20px;
}
@media screen and (min-width: 320px) {
  .tariff__subitem--category {
    padding-left: calc( 20px + (100 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem--category {
    padding-left: 100px;
  }
}
.tariff__subitem-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .tariff__subitem-content {
    align-items: flex-start;
  }
}
.tariff__subitem-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .tariff__subitem-left {
    justify-content: flex-start;
    width: 60%;
  }
}
.tariff__subitem-title {
  letter-spacing: 0.023em;
  color: #000;
  padding-right: 15px;
}
.tariff__subitem-title {
  font-size: 13px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .tariff__subitem-title {
    font-size: calc( 13px + 3 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem-title {
    font-size: 16px;
  }
}
.tariff__subitem-desc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #000;
  position: relative;
  cursor: pointer;
  transition: opacity 0.3s;
}
.tariff__subitem-desc.is-active .tariff__subitem-plus {
  opacity: 0;
}
.tariff__subitem-desc.is-active .tariff__subitem-minus {
  opacity: 1;
}
.tariff__subitem-desc:hover {
  opacity: 0.7;
}
@media screen and (max-width: 767px) {
  .tariff__subitem-desc {
    order: -1;
    margin-right: 10px;
  }
}
.tariff__subitem-plus {
  opacity: 1;
  transition: opacity 0.3s;
}
.tariff__subitem-minus {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s;
}
@media screen and (min-width: 768px) {
  .tariff__subitem-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .tariff__subitem-right {
    display: grid;
    grid-template-columns: 35px max-content;
    width: 40%;
    justify-content: flex-end;
  }
}
.tariff__subitem-promotional {
  font-family: "Lato", sans-serif;
  color: #fff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 7px 20px;
  background-color: #f0cbbb;
  margin-right: 20px;
}
.tariff__subitem-promotional {
  font-size: 10px;
  font-weight: 500;
}
@media screen and (min-width: 320px) {
  .tariff__subitem-promotional {
    font-size: calc( 10px + 4 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem-promotional {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .tariff__subitem-promotional {
    margin-right: 0;
    margin-top: 5px;
    grid-column: 2/3;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
.tariff__subitem-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Lato", sans-serif;
  border-radius: 50%;
  background-color: #f0cbbb;
  padding: 5px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-right: 20px;
}
.tariff__subitem-info {
  font-size: 10px;
  font-weight: 500;
}
@media screen and (min-width: 320px) {
  .tariff__subitem-info {
    font-size: calc( 10px + 4 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem-info {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .tariff__subitem-info {
    order: -2;
  }
}
@media screen and (min-width: 992px) {
  .tariff__subitem-info {
    width: 28px;
    height: 28px;
  }
}
.tariff__subitem-tooltip {
  font-family: "Lato", sans-serif;
  padding: 7px;
  max-width: 250px;
}
.tariff__subitem-tooltip {
  font-size: 11px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .tariff__subitem-tooltip {
    font-size: calc( 11px + 4 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem-tooltip {
    font-size: 15px;
  }
}
.tariff__subitem-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .tariff__subitem-box {
    order: -1;
    grid-column: 2/3;
    justify-content: flex-end;
  }
}
.tariff__subitem-lower-price {
  letter-spacing: 0.025em;
  color: #000;
  text-decoration: line-through;
  margin-right: 15px;
  white-space: nowrap;
}
.tariff__subitem-lower-price {
  font-size: 13px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .tariff__subitem-lower-price {
    font-size: calc( 13px + 3 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem-lower-price {
    font-size: 16px;
  }
}
.tariff__subitem-price {
  letter-spacing: 0.025em;
  color: #000;
  white-space: nowrap;
}
.tariff__subitem-price {
  font-size: 13px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .tariff__subitem-price {
    font-size: calc( 13px + 3 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem-price {
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  .tariff__subitem-description {
    max-width: 90%;
  }
}
.tariff__subitem-text {
  line-height: 1.3;
  font-family: "Lato", sans-serif;
}
.tariff__subitem-text {
  padding-top: 15px;
}
@media screen and (min-width: 320px) {
  .tariff__subitem-text {
    padding-top: calc( 15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem-text {
    padding-top: 25px;
  }
}
.tariff__subitem-text {
  padding-bottom: 15px;
}
@media screen and (min-width: 320px) {
  .tariff__subitem-text {
    padding-bottom: calc( 15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem-text {
    padding-bottom: 25px;
  }
}
.tariff__subitem-text {
  font-size: 13px;
  font-weight: 400;
}
@media screen and (min-width: 320px) {
  .tariff__subitem-text {
    font-size: calc( 13px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .tariff__subitem-text {
    font-size: 15px;
  }
}
@media screen and (max-width: 413px) {
  .tariff__subitem-text {
    text-align: center;
  }
}

/********************/
/* ~*~ Payment ~*~ */
/*******************/
.pay__title {
  text-align: center;
}
.pay__list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 50px;
  max-width: 750px;
  margin-right: auto;
  margin-left: auto;
}
.pay__list {
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .pay__list {
    margin-top: calc( 30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .pay__list {
    margin-top: 60px;
  }
}
@media screen and (min-width: 414px) {
  .pay__list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}
@media screen and (min-width: 768px) {
  .pay__list {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
  }
}
.pay__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  justify-content: flex-start;
}
.pay__box-bg-color {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  max-height: 180px;
  max-width: 180px;
}
.pay__box-bg-color {
  margin-bottom: 15px;
}
@media screen and (min-width: 320px) {
  .pay__box-bg-color {
    margin-bottom: calc( 15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .pay__box-bg-color {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 1440px) {
  .pay__box-bg-color {
    max-height: 200px;
    max-width: 200px;
  }
}
@media screen and (max-width: 413px) {
  .pay__box-bg-color {
    max-width: 150px;
    max-height: 150px;
  }
}
.pay__box-bg-color svg {
  width: 100%;
  height: auto;
  display: block;
}
.pay__box-bg-color svg * {
  fill: #aec4d4;
}
.pay__box-bg-img {
  display: block;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pay__box-bg-img svg {
  max-height: 160px;
  max-width: 160px;
}
@media screen and (min-width: 1440px) {
  .pay__box-bg-img svg {
    max-height: 180px;
    max-width: 180px;
  }
}
@media screen and (max-width: 413px) {
  .pay__box-bg-img svg {
    max-width: 130px;
    max-height: 130px;
  }
}
.pay__box-bg-img svg * {
  fill: transparent;
}

/***************/
/* ~*~ Map ~*~ */
/***************/
.map {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .map {
    height: 100vh;
  }
}
.map__wrapper {
  position: relative;
}
@media screen and (max-width: 767px) {
  .map__wrapper {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 768px) {
  .map__wrapper {
    height: 100%;
  }
}
.map__title {
  text-align: center;
}
.map__title {
  margin-top: 30px;
}
@media screen and (min-width: 320px) {
  .map__title {
    margin-top: calc( 30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .map__title {
    margin-top: 50px;
  }
}
.map__container {
  position: relative;
  z-index: 2 !important;
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .map__container {
    height: 100%;
  }
}
.map__content {
  pointer-events: initial;
  z-index: 2;
  background-color: #fff;
}
.map__content {
  padding-right: 30px;
}
@media screen and (min-width: 320px) {
  .map__content {
    padding-right: calc( 30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .map__content {
    padding-right: 60px;
  }
}
.map__content {
  padding-left: 30px;
}
@media screen and (min-width: 320px) {
  .map__content {
    padding-left: calc( 30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .map__content {
    padding-left: 60px;
  }
}
.map__content {
  padding-top: 40px;
}
@media screen and (min-width: 320px) {
  .map__content {
    padding-top: calc( 40px + (70 - 40) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .map__content {
    padding-top: 70px;
  }
}
.map__content {
  padding-bottom: 40px;
}
@media screen and (min-width: 320px) {
  .map__content {
    padding-bottom: calc( 40px + (70 - 40) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .map__content {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 767px) {
  .map__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .map__content {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 767px) {
  .map__content-logo {
    display: none;
  }
}
.map__content-text {
  color: #000;
  line-height: 1.4;
}
.map__content-text {
  font-size: 16px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .map__content-text {
    font-size: calc( 16px + 5 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .map__content-text {
    font-size: 21px;
  }
}
.map__content-text {
  margin-top: 15px;
}
@media screen and (min-width: 320px) {
  .map__content-text {
    margin-top: calc( 15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .map__content-text {
    margin-top: 25px;
  }
}
@media screen and (max-width: 767px) {
  .map__content-text {
    text-align: center;
  }
}
.map__content-text a {
  transition: color 0.3s;
}
.map__content-text a:hover {
  color: #e6bca5;
}
.map__content-sm {
  display: flex;
}
.map__content-sm-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: #000;
  transition: opacity 0.3s;
}
.map__content-sm-item svg path {
  fill: #fff;
}
.map__content-sm-item:hover {
  opacity: 0.7;
}
@media screen and (max-width: 991px) {
  .map__content-btn {
    margin-top: 20px;
  }
}
@media screen and (min-width: 992px) {
  .map__content-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
  }
}
.map .map__box {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .map .map__box {
    height: 100%;
    order: -1;
    min-height: 450px;
  }
}
@media screen and (min-width: 768px) {
  .map .map__box {
    position: absolute;
    top: 0;
    bottom: 0;
  }
}
.map .mapboxgl-canvas:focus {
  outline: none;
}
.map .mapboxgl-marker {
  background-image: url("../../images/svg/location-marker.svg");
  background-size: cover;
  width: 40px;
  height: 40px;
  opacity: 0;
  animation: fadein 2s forwards;
  animation-delay: 2s;
}
.map .mapboxgl-marker svg {
  display: none;
}
.map__coords {
  display: none;
}

/*****************/
/* ~*~ Hours ~*~ */
/*****************/
.hours {
  margin-bottom: 30px;
  background-color: #f0cbbb;
  position: relative;
}
.hours {
  padding-top: 50px;
}
@media screen and (min-width: 320px) {
  .hours {
    padding-top: calc( 50px + (130 - 50) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .hours {
    padding-top: 130px;
  }
}
.hours {
  padding-bottom: 50px;
}
@media screen and (min-width: 320px) {
  .hours {
    padding-bottom: calc( 50px + (130 - 50) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .hours {
    padding-bottom: 130px;
  }
}
@media screen and (min-width: 992px) {
  .hours {
    margin-bottom: 40px;
  }
}
.hours__row {
  display: grid;
  align-items: flex-start;
  position: relative;
}
.hours__row {
  padding-left: 20px;
}
@media screen and (min-width: 320px) {
  .hours__row {
    padding-left: calc( 20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .hours__row {
    padding-left: 40px;
  }
}
.hours__row {
  padding-right: 20px;
}
@media screen and (min-width: 320px) {
  .hours__row {
    padding-right: calc( 20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .hours__row {
    padding-right: 40px;
  }
}
@media screen and (min-width: 1280px) {
  .hours__row {
    grid-template-columns: 0.3fr 0.7fr;
    grid-column-gap: 7%;
  }
}
@media screen and (min-width: 1366px) {
  .hours__row {
    grid-template-columns: 0.35fr 0.65fr;
  }
}
.hours__bg {
  position: absolute;
  left: 0;
  bottom: -5px;
}
@media screen and (max-width: 1535px) {
  .hours__bg {
    max-width: 350px;
    left: -100px;
  }
}
@media screen and (max-width: 1279px) {
  .hours__bg {
    left: -90px;
  }
}
@media screen and (max-width: 991px) {
  .hours__bg {
    max-width: 300px;
    left: -90px;
  }
}
@media screen and (max-width: 767px) {
  .hours__bg {
    display: none;
  }
}
.hours__bg svg {
  width: 100%;
  height: auto;
}
.hours__col-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .hours__col-right {
    flex-direction: column;
    justify-content: center;
  }
}
.hours__title {
  font-style: normal;
  margin-bottom: 30px;
  text-align: center;
}
@media screen and (min-width: 1280px) {
  .hours__title {
    margin-bottom: 0;
    text-align: left;
  }
}
.hours__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .hours__box {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 768px) {
  .hours__box {
    margin-left: 15px;
  }
}
@media screen and (min-width: 768px) and (min-width: 320px) {
  .hours__box {
    margin-left: calc( 15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 768px) and (min-width: 1920px) {
  .hours__box {
    margin-left: 25px;
  }
}
@media screen and (min-width: 768px) {
  .hours__box {
    margin-right: 15px;
  }
}
@media screen and (min-width: 768px) and (min-width: 320px) {
  .hours__box {
    margin-right: calc( 15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 768px) and (min-width: 1920px) {
  .hours__box {
    margin-right: 25px;
  }
}
.hours__box:nth-child(1) {
  margin-left: 0;
}
.hours__box:nth-last-child(1) {
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .hours__box:nth-last-child(1) {
    margin-bottom: 0;
  }
}
.hours__box-time {
  line-height: 1.2;
  color: #000;
  text-align: center;
}
.hours__box-time {
  font-size: 28px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .hours__box-time {
    font-size: calc( 28px + 10 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .hours__box-time {
    font-size: 38px;
  }
}
.hours__box-time {
  margin-bottom: 12px;
}
@media screen and (min-width: 320px) {
  .hours__box-time {
    margin-bottom: calc( 12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)) );
  }
}
@media screen and (min-width: 1920px) {
  .hours__box-time {
    margin-bottom: 20px;
  }
}
.hours__box-days {
  letter-spacing: 0.25em;
  display: flex;
  color: #8b8b8b;
  font-family: "Lato", sans-serif;
  text-align: center;
}
.hours__box-days {
  font-size: 18px;
  font-weight: 700;
}
@media screen and (min-width: 320px) {
  .hours__box-days {
    font-size: calc( 18px + 2 * ((100vw - 320px) / 1600) );
  }
}
@media screen and (min-width: 1920px) {
  .hours__box-days {
    font-size: 20px;
  }
}

.error-page {
  position: relative;
  height: 100vh;
}
.error-page__picture {
  display: flex;
  width: 100%;
  height: 100%;
}
.error-page__img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.error-page__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  bottom: 15%;
  right: 10%;
}
@media screen and (max-width: 991px) {
  .error-page__wrapper {
    right: 50%;
    width: 100%;
    bottom: 10%;
    padding-right: 20px;
    padding-left: 20px;
    transform: translateX(50%);
  }
}
.error-page__texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-page__title, .error-page__text {
  text-align: center;
}

body {
  font-family: "Playfair Display", serif;
  min-width: 320px;
  overflow-x: hidden;
  position: relative;
}

#content {
  position: relative;
}