$position: relative;
$media: bp-992;
$bg: #fff;
$menu--items--count: 8;
$transition--length: 0.8;
$font--size--calc: calc(2vw + 10px);
$transition--easing: cubic-bezier(0.77, 0, 0.175, 1);

// ================
// Hamburger types
// ================
$hamburger-types: (slider);
$hamburger-padding-x: 0px;
$hamburger-padding-y: 0px;
$hamburger-layer-width: 20px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-color: $black;
$hamburger-layer-border-radius: 4px;
$hamburger-hover-opacity: 0.9;
$hamburger-active-layer-color: $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(80%);
$hamburger-active-hover-filter: $hamburger-hover-filter;

.navigation {
    @include mq(bp-992, max) {
        @include flex-column-center;

        z-index: 99;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        pointer-events: none;
    }

    .mobile-menu {
        display: none;

        @include mq(bp-992, max) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: $white;
            z-index: 1;
            width: 100%;
            padding: 12px 15px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
            pointer-events: auto;

            &__logo {
                max-width: 110px;
                width: 100%;
            }
        }
    }

    /* Main nav */
    .nav {
        padding-top: 13px;
        padding-bottom: 13px;
        background-color: transparent;

        @include mq(bp-992, max) {
            @include flex-column-center;

            justify-content: flex-start;

            height: 100%;
            width: 100%;
            background-color: $white;
            // clip: circle(30px at calc(100% - 45px) - 15px);
            clip-path: circle(30px at calc(100% - 45px) - 15px);

            visibility: hidden;
            pointer-events: auto;
            // transition: visibility 0.5s, clip 0.6s;

            transition: clip-path 500ms ease-in-out,
                visibility 500ms ease-in-out;

            &::before {
                @include pseudo();

                width: 100%;
                height: 100%;
                bottom: 0;
                left: 0;
                transform: scale(2.5);
                background-image: url("../../images/svg/mobile-menu-bg.svg");
                background-size: cover;
                background-position: bottom left;
                transition: transform 0.6s;
                transition-delay: 0.2s;
            }

            &.is-active {
                visibility: visible;
                clip-path: circle(100%);

                &::before {
                    transform: scale(1);
                }

                .nav__list-item {
                    opacity: 1;
                    transform: translateX(0);
                }

                .call-us {
                    opacity: 1;
                    transform: translateX(0);
                    transition-delay: 0.4s;
                }

                .nav__top-langs {
                    opacity: 1;
                    transform: translateX(0);
                    transition-delay: 0.4s;
                }
            }
        }

        // TOP
        &__top {
            @include padding(15, 60, "left");
            @include padding(15, 60, "right");

            margin-bottom: 20px;

            @include mq(bp-992, max) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 60px;
            }

            @include mq(bp-992) {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
            }

            .call-us {
                display: flex;
                align-items: center;
                position: relative;

                @include mq(bp-992, max) {
                    opacity: 0;
                    transform: translateX(100%);
                    transition: transform 0.5s, opacity 0.3s;
                }

                &:hover {
                    .call-us__logo {
                        background-color: $cream;
                    }
                }

                &__logo {
                    @include flex-center;

                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    padding: 7px;
                    margin-right: 15px;
                    background-color: $black;
                    transition: background-color 0.3s;

                    @include mq(bp-992) {
                        width: 36px;
                        height: 36px;
                        padding: 5px;
                    }
                }

                &__box {
                    @include flex-column;

                    align-items: flex-start;
                }

                .call {
                    @include fontr(13px, 15px, 900);
                    @include tracking(50);

                    color: $black;
                    font-family: $second-font;
                    text-transform: uppercase;
                }

                .number {
                    @include fontr(17px, 24px, 700);

                    color: $black;
                }
            }

            &-logo {
                margin-left: auto;
                margin-right: auto;
                max-width: 200px;

                @include mq(bp-992, max) {
                    display: none;
                }
            }

            &-langs {
                display: flex;

                @include mq(bp-992, max) {
                    opacity: 0;
                    transform: translateX(-100%);
                    transition: transform 0.5s, opacity 0.3s;
                    order: -1;
                }

                @include mq(bp-992) {
                    margin-left: auto;
                }
            }

            &-lang {
                @include flex-center;

                position: relative;

                @include mq(bp-992) {
                    margin-left: 5px;
                }

                span {
                    @include tracking(100);

                    position: relative;
                    color: $white;
                    text-transform: uppercase;
                    font-weight: 900;
                    font-size: 15px;
                    font-family: $second-font;
                    padding: 10px;
                    transition: color 0.3s;

                    @include mq(bp-992, max) {
                        padding: 8px;
                        font-size: 14px;
                    }
                }

                &:hover span {
                    color: $cream;
                }

                &::before {
                    @include pseudo();

                    width: 100%;
                    height: 100%;
                    background-image: url("../../images/svg/lang-bg.svg");
                    background-size: contain;
                    background-position: center;
                }

                &--active {
                    pointer-events: none;

                    &::before {
                        opacity: 0.2;
                    }
                }
            }
        }

        // BOTTOM
        &__bottom {
            @include flex-center;

            @include mq(bp-992, max) {
                // order: -1;
            }
        }

        &__list {
            @include flex-center;

            justify-content: space-between;

            @include mq(bp-992, max) {
                @include flex-column-center;
            }

            &-item {
                @include mq(bp-992, max) {
                    opacity: 0;
                    transition: transform 0.5s, opacity 0.3s;
                    transition-delay: 0.2s;

                    &:nth-child(odd) {
                        transform: translateX(30%);
                    }
                    &:nth-child(even) {
                        transform: translateX(-30%);
                    }
                }

                @include mq(bp-992) {
                    &:nth-child(1) {
                        .nav__list-itemlink {
                            padding-left: 0;
                        }
                    }

                    &:nth-last-child(2) {
                        .nav__list-itemlink {
                            padding-right: 0;
                        }
                    }
                }
            }

            &-itemlink {
                @include fontr(16px, 16px, 900);
                @include tracking(50);
                @include padding(15, 15, "top");
                @include padding(15, 15, "bottom");
                @include padding(5, 15, "left");
                @include padding(5, 15, "right");

                display: inline-flex;
                color: $dark-cream;
                position: relative;
                outline: none;
                text-transform: uppercase;
                transform: translateZ(0);
                white-space: nowrap;

                @include mq(bp-992) {
                    @include padding(10, 15, "top");
                    @include padding(10, 15, "bottom");
                    @include fontr(13px, 15px, 900);
                }

                @include mq(bp-1280) {
                    @include fontr(14px, 16px, 900);
                }

                @include mq(bp-1366) {
                    @include padding(10, 30, "left");
                    @include padding(10, 30, "right");
                }

                &::before {
                    color: $black;
                    position: absolute;
                    content: attr(data-hover);
                    transform: translateZ(0);
                    transition: transform 0.3s, opacity 0.3s;
                }

                &:hover,
                &:focus {
                    outline: none;
                }

                &:hover::before,
                &:focus::before {
                    transform: scale(0.9);
                    opacity: 0;
                }
            }
        }
    }

    // HAMBURGER
    .hamburger {
        padding: $hamburger-padding-y $hamburger-padding-x;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        outline: none;
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        height: 40px !important;
        width: 40px !important;
        position: relative;

        // Normalize (<button>)
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        overflow: visible;

        &::after {
            @include pseudo();

            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../../images/svg/hamburger-bg.svg");
            background-size: cover;
            z-index: 2;
        }

        &:hover {
            @if $hamburger-hover-use-filter==true {
                filter: $hamburger-hover-filter;
            } @else {
                opacity: $hamburger-hover-opacity;
            }
        }

        &.is-active {
            &:hover {
                @if $hamburger-hover-use-filter==true {
                    filter: $hamburger-active-hover-filter;
                } @else {
                    opacity: $hamburger-active-hover-opacity;
                }
            }

            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                background-color: $hamburger-active-layer-color;
            }

            .hamburger-inner {
                width: $hamburger-layer-width / 1.5;
            }
        }

        &-box {
            width: $hamburger-layer-width;
            height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 3;
        }

        &-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            top: 50%;
            // margin-top: $hamburger-layer-height / -2;
            width: $hamburger-layer-width;
            height: $hamburger-layer-height;
            background-color: $hamburger-layer-color;
            border-radius: $hamburger-layer-border-radius;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;

            &::before,
            &::after {
                width: $hamburger-layer-width;
                height: $hamburger-layer-height;
                background-color: $hamburger-layer-color;
                border-radius: $hamburger-layer-border-radius;
                position: absolute;
                transition-property: transform;
                transition-duration: 0.15s;
                transition-timing-function: ease;
            }

            &::before,
            &::after {
                content: "";
                display: block;
            }

            &::before {
                width: $hamburger-layer-width / 1.5;
                top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
            }

            &::after {
                bottom: ($hamburger-layer-spacing + $hamburger-layer-height) *
                    -1;
            }
        }
    }
}

@import "../hamburger/slider";
