.footer {
  @include padding(50, 60, 'top');
  @include padding(50, 60, 'bottom');

  background-color: $blue;

  @include mq(bp-992, max) {
    padding-bottom: 30px;
  }

  &__container {
    @include flex-column;
    @include padding(15, 140, 'left');
    @include padding(15, 140, 'right');

    justify-content: space-between;
  }

  &__column {
    @include mq(bp-992, max) {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__row-top {
    @include margin(50, 150, 'bottom');

    @include mq(bp-992, max) {
      margin-bottom: 30px;
    }
  }

  &__contact {
    @include margin(50, 60, 'bottom');

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include mq(bp-992, max) {
      align-items: center;
    }

    &-title {
      @include fontr(24px, 32px, 700);
      @include margin(10, 15, 'bottom');

      font-style: italic;
      line-height: 1.2;
      color: $white;
      text-align: left;
    }

    &-phone,
    &-email {
      @include fontr(18px, 20px, 300);

      color: $light-cream;
      transition: color 0.3s;

      &:hover {
        color: $cream;
      }
    }

    &-phone {
      @include margin(5, 8, 'bottom');
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    margin-bottom: 30px;

    @include mq(bp-992) {
      max-width: 310px;
      margin-bottom: 0;
    }
  }

  &__nav {
    @include flex-column-center;

    position: relative;
    z-index: 1;

    @include mq(bp-992, max) {
      display: inline-flex;
    }

    &-item {
      @include fontr(16px, 16px, 400);
      @include padding(11, 10, 'top');
      @include padding(11, 10, 'bottom');

      display: inline-flex;
      color: $white;
      transition: color 0.3s;

      &:hover {
        color: $cream;
      }
    }
  }

  &-bg {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(55%, 10%);
    // width: 60%;
    max-width: 300px;
    pointer-events: none;

    @include mq(bp-992) {
      transform: translate(30%, 10%);
    }

    @include mq(bp-1920) {
      transform: translate(10%, 10%);
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__sm {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include mq(bp-992, max) {
      justify-content: center;
      margin-top: 50px;
    }

    li {
      margin: 0 7.5px;

      @include mq(bp-992) {
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }

    &-item {
      @include flex-center;

      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $light-cream;
      transition: background-color 0.3s;

      &:hover {
        background-color: $cream;
      }
    }
  }

  &__row-bottom {
  }

  .copyright-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include mq(bp-992, max) {
      align-items: flex-end;
    }
  }

  .copyright {
    @include flex-center;

    justify-content: flex-start;
    white-space: nowrap;

    @include mq(bp-768, max) {
      transform: rotate(-90deg) translateY(100%);
      transform-origin: bottom left;
    }

    &__left {
      @include fontr(12px, 14px, 300);

      font-family: $second-font;
      color: $light-cream;
      opacity: 0.6;
    }

    &__right {
      @include fontr(12px, 14px, 700);
      @include tracking(50);

      color: $light-cream;
      padding-left: 15px;
    }
  }

  &__bottom-nav {
    @include flex-center;

    color: $cream;

    @include mq(bp-992, max) {
      @include flex-column-center;
    }

    &-item {
      @include fontr(14px, 14px, 400);

      display: inline-flex;
      padding: 15px;
      opacity: 0.7;
      transition: opacity 0.3s;
      font-family: $second-font;
      text-align: center;

      @include mq(bp-992, max) {
        padding: 5px;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .created-column {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include mq(bp-992, max) {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  &-created {
    @include flex-center;

    justify-content: flex-end;
    white-space: nowrap;

    @include mq(bp-768, max) {
      transform-origin: bottom right;
      transform: rotate(-90deg) translateX(100%);
    }

    &:hover {
      .footer-created__left {
        opacity: 1;
        color: $cream;
      }

      svg {
        * {
          fill: $cream;
        }
      }
    }

    svg {
      * {
        transition: fill 0.3s;
      }
    }

    &__left {
      @include fontr(12px, 14px, 300);

      font-family: $second-font;
      color: $light-cream;
      opacity: 0.6;
      margin-right: 10px;
      transition: opacity 0.3s, color 0.3s;
    }
  }
}
