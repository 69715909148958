$speed: 2s;

.preloader {
  z-index: 1041;
  background-color: $white;
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1s;

  svg {
    position: absolute;
    height: 0;
    width: 0;
  }

  &__wrapper {
    width: 100%;
    position: relative;
  }

  &__box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: show ease-in-out $speed infinite alternate;

    &:after {
      content: '';
      clear: both;
    }

    span {
      @include fontr(45px, 72px, 600);

      color: #e6bca5;
      display: block;
      float: left;

      &:nth-child(3) {
        animation: blob-anim-left3 ease-in-out $speed infinite 0.05s alternate;
      }
      &:nth-child(2) {
        animation: blob-anim-left2 ease-in-out $speed infinite 0.025s alternate;
      }
      &:nth-child(1) {
        animation: blob-anim-left1 ease-in-out $speed infinite alternate;
      }

      // Only safari
      @supports (-webkit-backdrop-filter: blur(1px)) {
        &:nth-child(4) {
          animation: blob-anim-left4 ease-in-out $speed infinite alternate;
        }
      }
      &:nth-child(5) {
        animation: blob-anim-left5 ease-in-out $speed infinite 0.05s alternate;
      }
      &:nth-child(6) {
        animation: blob-anim-left6 ease-in-out $speed infinite 0.025s alternate;
      }
      &:nth-child(7) {
        animation: blob-anim-left7 ease-in-out $speed infinite alternate;
      }
    }
  }

  @keyframes show {
    0% {
      filter: none;
    }
    41% {
      filter: none;
    }
    42% {
      filter: url(#goo);
    }
    100% {
      filter: url(#goo);
    }
  }

  @keyframes blob-anim-left7 {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(10px);
    }
    50% {
      transform: translateX(-230%) translateX(-50px);
    }
    65% {
      transform: translateX(-230%);
    }
    75% {
      transform: translateX(-230%) translateX(5px);
    }
    85% {
      transform: translateX(-230%);
    }
    100% {
      transform: translateX(-230%);
      opacity: 0;
    }
  }
  @keyframes blob-anim-left6 {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(10px);
    }
    50% {
      transform: translateX(-110%) translateX(-50px);
    }
    65% {
      transform: translateX(-110%);
    }
    75% {
      transform: translateX(-110%) translateX(5px);
    }
    85% {
      transform: translateX(-110%);
    }
    100% {
      transform: translateX(-110%);
      opacity: 0;
    }
  }
  @keyframes blob-anim-left5 {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(10px);
    }
    50% {
      transform: translateX(-100%) translateX(-50px);
    }
    65% {
      transform: translateX(-100%);
    }
    75% {
      transform: translateX(-100%) translateX(5px);
    }
    85% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
  @keyframes blob-anim-left1 {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(305%) translateX(20px);
    }
    65% {
      transform: translateX(305%);
    }
    75% {
      transform: translateX(305%) translateX(-5px);
    }
    85% {
      transform: translateX(305%);
    }
    100% {
      transform: translateX(305%);
      opacity: 0;
    }
  }
  @keyframes blob-anim-left2 {
    0% {
      transform: translateX(0%);
    }
    25% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(190%) translateX(50px);
    }
    65% {
      transform: translateX(190%);
    }
    75% {
      transform: translateX(190%) translateX(-5px);
    }
    85% {
      transform: translateX(190%);
    }
    100% {
      transform: translateX(190%);
      opacity: 0;
    }
  }
  @keyframes blob-anim-left3 {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(100%) translateX(50px);
    }
    65% {
      transform: translateX(100%);
    }
    75% {
      transform: translateX(100%) translateX(-5px);
    }
    85% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(100%);
      opacity: 0;
    }
  }

  @keyframes blob-anim-left4 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 0;
    }
  }
}
