.media {
  &__text {
    @include flex-column-center;
  }

  &__row {
    @include margin(20, 60, 'bottom');

    display: flex;

    @include mq(bp-992, max) {
      flex-direction: column;
      max-width: 600px;
      margin: 0 auto;
    }

    @include mq(bp-414, max) {
      margin-right: -20px;
      margin-left: -20px;
    }
  }

  // Video
  &-video {
    &__content {
      display: flex;
      position: relative;
      margin-bottom: 20px;
      height: 300px;

      @include mq(bp-992) {
        height: auto;
        margin: 10px;
      }

      @include mq(bp-1366) {
        margin: 15px;
      }

      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__play-btn {
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 3;
      height: 100%;
      transition: transform 0.9s, opacity 0.6s;
      cursor: pointer;
      overflow: hidden;

      &.is-disable {
        opacity: 0;
        pointer-events: none;
      }

      &::before {
        @include pseudo();

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: rgba(240, 203, 187, 0.73);
      }

      &:hover {
        .media-video__play-bg {
          filter: blur(2px);
        }
        .media-video__play-icon {
          opacity: 0.7;

          svg {
            .cls-1 {
              stroke: $black;
            }
            .cls-2 {
              fill: $black;
            }
          }
        }
      }
    }

    &__play-icon {
      @include img(50px, 55px, height);
      @include img(50px, 55px, width);
      @include center('both');
      @include flex-center;

      position: absolute;
      z-index: 3;

      svg {
        .cls-1 {
          transition: stroke 0.3s;
        }
        .cls-2 {
          transition: fill 0.3s;
        }
      }
    }

    &__play-bg {
      display: flex;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: filter 0.3s;
    }
  }

  &__col {
    &-center {
      display: grid;
      order: -1;

      @include mq(bp-992, max) {
        margin-bottom: 20px;
      }

      @include mq(bp-992) {
        grid-template-rows: repeat(2, 1fr);
        width: 50%;
        order: initial;
      }
    }

    &-left,
    &-right {
      @include mq(bp-992, max) {
        margin-bottom: 20px;
      }

      @include mq(bp-992) {
        width: 25%;
      }
    }

    &-left {
      display: grid;
      grid-template-columns: 0.6fr 0.4fr;
      grid-template-rows: 200px;
      grid-column-gap: 20px;

      @include mq(bp-992) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(6, 80px);
        grid-column-gap: initial;
      }

      @include mq(bp-1366) {
        grid-template-rows: repeat(6, 95px);
      }

      @include mq(bp-1536) {
        grid-template-rows: repeat(6, 110px);
      }
    }
  }

  &__bg {
    display: flex;

    @include mq(bp-992) {
      padding: 10px;
    }

    @include mq(bp-1366) {
      padding: 15px;
    }

    &-img {
      object-fit: cover;
      width: 100%;
    }

    // Small
    &-smallest {
      @include mq(bp-992) {
        grid-row: 2 / 3;
        grid-column: 3 / 4;
      }
    }

    // Small
    &-small {
      @include mq(bp-992) {
        grid-row: 3 / 6;
        grid-column: 1 / 4;
      }
    }

    // Medium
    &-medium {
      @include mq(bp-992) {
        grid-row: 1 / 4;
      }
    }

    // Big
    &-big {
      @include mq(bp-992) {
        grid-row: 1 / 3;
      }
    }

    // Large
    &-large {
      height: 100%;

      @include mq(bp-992, max) {
        max-height: 350px;
      }
    }
  }

  &__box {
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    grid-template-rows: 200px;
    grid-column-gap: 20px;

    @include mq(bp-992) {
      grid-template-rows: repeat(3, 80px);
      grid-column-gap: initial;
    }

    @include mq(bp-1366) {
      grid-template-rows: repeat(3, 95px);
    }

    @include mq(bp-1536) {
      grid-template-rows: repeat(3, 110px);
    }
  }

  &__texts {
    display: grid;
    grid-template-columns: 1fr;

    @include mq(bp-992, max) {
      max-width: 470px;
      margin: 0 auto;
    }

    @include mq(bp-992) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
    }
  }

  &__desc {
    @include mq(bp-992, max) {
      text-align: center;
    }

    &-left {
      @include fontr(18px, 24px);

      line-height: 1.4;
    }
  }
}
