/******************/
/* ~*~ Config ~*~ */
/******************/
@import "./base/core";
@import "./base/reset";
/******************/
/* ~*~ NPM ~*~ */
/******************/
@import "~tippy.js/dist/tippy.css";
@import "~mapbox-gl/dist/mapbox-gl.css";
@import "~swiper/swiper-bundle.css";
@import "~aos/dist/aos.css";
/******************/
/* ~*~ Global ~*~ */
/******************/
@import "./global/colors";
@import "./global/keyframes";

/******************/
/* ~*~ Mixins ~*~ */
/******************/
@import "./base/rest";
@import "./base/responsive";
@import "./base/grid";
@import "./base/flex";
@import "./base/font";
@import "./base/retina";
@import "./base/global";
@import "./global/form";

/**********************/
/* ~*~ Components ~*~ */
/**********************/
@import "./components/icons";
@import "./components/nav";
@import "./components/texts";
@import "./components/buttons";
@import "./components/cookies";
@import "./components/footer";
@import "./components/preloader";

/******************/
/* ~*~ Shared ~*~ */
/******************/
@import "./pages/shared/offer";
@import "./pages/shared/slider";
@import "./pages/shared/small-header";
@import "./pages/shared/who-treatment";
@import "./pages/shared/media";
@import "./pages/shared/picture-header";
@import "./pages/shared/text-page";
@import "./pages/shared/form";
@import "./pages/shared/recomend";

/******************/
/* ~*~ Pages ~*~ */
/******************/
@import "./pages/homepage";
@import "./pages/about";
@import "./pages/sale";
@import "./pages/team";
@import "./pages/offer-tax";
@import "./pages/single-offer";
@import "./pages/single-training";
@import "./pages/training";
@import "./pages/knowledge";
@import "./pages/price";
@import "./pages/contact";
@import "./pages/error";

body {
    font-family: $base-font;
    min-width: 320px;
    overflow-x: hidden;
    position: relative;
}

#content {
    position: relative;
}
@import "/wp-includes/css/dist/block-library/style.css";
