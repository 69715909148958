.offer {
  &__container {
    @include flex-column-center;
    // margin-bottom: 200px;
  }

  &__text {
    @include flex-column-center;
  }

  &__list {
    display: grid;
    // display: flex;
    width: 100%;
    // grid-template-columns: repeat(3, 1fr);
    // flex-wrap: wrap;
    // grid-row-gap: 90px;
    grid-row-gap: 60px;

    @include mq(bp-768) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 50px;
      grid-row-gap: initial;
    }

    @include mq(bp-992) {
      grid-template-columns: repeat(3, 1fr);
      margin-top: -100px;
    }
  }

  &__box {
    @include flex-column-center;
    // margin-top: 150px;

    &:nth-child(2n + 2) {
      @include mq(bp-768) {
        margin-top: 100px;
        margin-bottom: -50px;
      }

      @include mq(bp-992) {
        margin-top: 50px;
      }
    }

    @include mq(bp-992) {
      &:nth-child(3n + 2) {
        margin-top: 200px;
        margin-bottom: -100px;
      }
    }
    // &:nth-child(2) {
    //   margin-top: 150px;
    // }

    &:hover {
      .offer__box-bg-color::after {
        transform: translateY(-10px);
      }
      .offer__box-title {
        transform: translateX(-10px);
      }

      .offer__box-desc {
        transform: translateX(10px);
      }
    }

    &-bg {
      @include flex-column-center;

      position: relative;

      &-color {
        @include flex-center;

        position: relative;

        @include mq(bp-414, max) {
          max-width: 180px;
        }

        svg * {
          fill: var(--bg-color);
        }

        &::after {
          @include pseudo();

          width: 1px;
          height: 50px;
          bottom: -25px;
          left: 50%;
          transform: translateX(50%);
          background-image: url('../../../images/svg/dotted-line.svg');
          background-size: cover;
          transition: transform 0.3s;
        }
      }

      &-img {
        position: absolute;
        z-index: 1;
        pointer-events: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg * {
          fill: transparent;
        }
      }
    }

    &-texts {
      @include flex-column-center;
    }

    &-title {
      margin-top: 35px;
      max-width: 320px;
      transform: translateX(0);
      transition: transform 0.3s;
    }

    &-desc {
      margin-top: 10px;
      text-align: center;
      max-width: 360px;
      transform: translateX(0);
      transition: transform 0.3s;
    }
  }

  &__boxes {
    @include margin(70, 200, 'top');

    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    grid-gap: 15px;

    @include mq(bp-992) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__item {
    display: flex;
    justify-content: flex-end;

    @include mq(bp-414, max) {
      flex-direction: column;
      margin-bottom: 70px;
    }

    @include mq(bp-414) {
      overflow: hidden;
    }

    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }

    &:nth-of-type(even) {
      justify-content: flex-start;

      .offer__item-bg {
        order: -1;

        @include mq(bp-414) {
          margin-left: 0;
          margin-right: 15px;
        }

        @include mq(bp-768) {
          margin-right: 30px;
        }
      }

      .offer__item-texts {
        @include mq(bp-414) {
          align-items: flex-start;
        }
      }

      .offer__item-title {
        @include mq(bp-414) {
          text-align: left;
        }
      }
    }

    &-bg {
      position: relative;
      display: flex;
      order: -1;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 30px;

      @include mq(bp-414) {
        margin-left: 15px;
        width: 50%;
        order: initial;
        margin-bottom: 0;
      }

      @include mq(bp-768) {
        margin-left: 30px;
      }

      @include mq(bp-992) {
        width: 60%;
        max-width: 380px;
      }

      @include mq(bp-1920) {
        width: 65%;
      }

      &::after {
        @include pseudo();

        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(174, 196, 212, 0.19);
      }

      &-img {
        object-fit: cover;
        width: 100%;
      }
    }

    &-texts {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include mq(bp-414) {
        width: 50%;
        align-items: flex-end;
      }

      @include mq(bp-992) {
        width: 40%;
      }

      @include mq(bp-1920) {
        width: 35%;
      }
    }

    &-title {
      text-align: center;

      @include mq(bp-414) {
        text-align: right;
      }
    }
  }
}

.item__clippath,
.item__deco,
.item__img {
  // transform-origin: 50% 50%;
}
.item__svg {
  position: relative;
  display: block;
  // width: 80%;
  height: auto;
  // min-height: 500px;
  margin: 0 auto;
  overflow: inherit;
}
