@import "./shared/video";

/***********************/
/* ~*~ Two columns ~*~ */
/**********************/
.two-columns {
    @include margin(30, 50, "top");

    &__container {
        @include flex-column-center;
    }

    &__row {
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 70px;

        @include mq(bp-992) {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 60px;
            margin-bottom: 100px;

            &:nth-of-type(even) {
                .two-columns__column:nth-of-type(1) {
                    order: 1;
                }
            }
        }
    }

    &__column {
        @include mq(bp-992, max) {
            &:nth-of-type(2) {
                margin-right: -20px;
                margin-left: -20px;
                order: -1;
                margin-bottom: 30px;
            }
        }

        &-texts {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include mq(bp-992) {
                align-items: start;
                justify-content: flex-start;
            }
        }

        &-desc,
        &-txt {
            text-align: center;

            @include mq(bp-992) {
                text-align: left;
            }
        }

        &-picture {
            display: flex;
            width: 100%;
            height: 100%;
            max-height: 300px;

            @include mq(bp-414) {
                max-height: 400px;
            }

            @include mq(bp-992) {
                max-height: 600px;
            }
        }

        &-img {
            object-fit: cover;
            width: 100%;
        }
    }
}

/********************/
/* ~*~ Gallery ~*~ */
/*******************/
.gallery {
    &__container {
        @include flex-column-center;
    }

    &__description {
        max-width: 800px;
    }

    &__wrapper {
        @include margin(20, 60, "top");

        display: grid;
        grid-template-rows: repeat(8, minmax(100px, 130px));
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        margin-left: -20px;
        margin-right: -20px;

        @include mq(bp-414) {
            grid-template-rows: repeat(8, minmax(120px, 150px));
            margin-left: auto;
            margin-right: auto;
        }

        @include mq(bp-768) {
            grid-template-rows: repeat(4, minmax(100px, 130px));
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 15px;
        }

        @include mq(bp-992) {
            grid-template-rows: repeat(4, minmax(180px, 200px));
            grid-gap: 20px;
        }

        @include mq(bp-1440) {
            grid-template-rows: repeat(4, minmax(200px, 250px));
            grid-gap: 40px;
        }
    }

    &__picture {
        display: flex;

        &:nth-child(1) {
            grid-area: 1 / 1 / 3 / 3;

            @include mq(bp-768) {
                grid-area: 1 / 1 / 3 / 3;
            }
        }

        &:nth-child(2) {
            grid-area: 3 / 1 / 4 / 3;

            @include mq(bp-768) {
                grid-area: 1 / 3 / 2 / 5;
            }
        }

        &:nth-child(3) {
            grid-area: 4 / 1 / 5 / 3;

            @include mq(bp-768) {
                grid-area: 2 / 3 / 3/ 5;
            }
        }

        &:nth-child(4) {
            grid-area: 5 / 1 / 7 / 3;

            @include mq(bp-768) {
                grid-area: 3 / 1 / 5 / 3;
            }
        }

        &:nth-child(5) {
            grid-area: 7 / 1 / 9 / 2;

            @include mq(bp-768) {
                grid-area: 3 / 3 / 5 / 4;
            }
        }

        &:nth-child(6) {
            grid-area: 7 / 2 / 9 / 3;

            @include mq(bp-768) {
                grid-area: 3 / 4 / 5 / 5;
            }
        }
    }

    &__img {
        object-fit: cover;
        width: 100%;
    }
}
