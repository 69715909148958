@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@mixin flex-center-v {
  display: flex;
  align-items: center;
}

@mixin flex-center-h {
  display: flex;
  justify-content: center;
}
