.who-treatment {
  &__text {
    @include flex-column-center;
  }

  &__row {
    @include margin(20, 30, 'top');

    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
    padding-right: 30px;
    padding-left: 30px;

    @include mq(bp-768, max) {
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;
    }

    @include mq(bp-414) {
      padding: 0;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
    }

    @include mq(bp-768) {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 40px;
    }

    @include mq(bp-992) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include mq(bp-1280) {
      grid-column-gap: 7.5%;
    }
  }

  &__box {
    // padding: 20px 100px;

    &:hover {
      &:nth-child(odd) {
        .who-treatment__box-bg {
          transform: scale(1.08) translateZ(0) perspective(2px) rotate(-2deg);
        }
      }
      &:nth-child(even) {
        .who-treatment__box-bg {
          transform: scale(1.08) translateZ(0) perspective(2px) rotate(2deg);
        }
      }
    }

    &-img {
      max-height: 260px;
      overflow: hidden;
      margin-bottom: 15px;
    }

    &-bg {
      width: 100%;
      max-height: 260px;
      object-fit: cover;
      backface-visibility: hidden;
      transition: transform 0.7s;
    }

    &-title {
      @include fontr(14px, 16px, 700);

      color: $black;
      font-style: italic;
    }

    &-name {
      @include fontr(18px, 24px, 700);

      color: $black;
      font-style: italic;
      margin-top: 3px;
      margin-bottom: 10px;
    }

    &-position {
      @include fontr(13px, 15px, 500);

      font-family: $second-font;
      font-style: italic;
      color: $text-gray;
    }
  }
}
