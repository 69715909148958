.team {
  &__container {
    @include flex-column-center;
    @include margin(30, 50, 'top');

    @include mq(bp-414, max) {
      // margin-left: -20px;
      // margin-right: -20px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__categories {
    display: flex;
    align-items: center;
    font-family: $second-font;

    &-txt {
      @include fontr(15px, 15px, 700);

      color: $black;
      opacity: 0.5;
      margin-right: 25px;
    }
  }

  &__category {
    @include fontr(15px, 15px, 700);
    @include tracking(50);

    padding: 8px 20px;
    color: $black;
    cursor: pointer;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;

    &.mixitup-control-active {
      color: $white;
      background-color: $cream;
    }
  }

  &__list {
    @include margin(30, 40, 'top');

    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    justify-content: center;
    width: 100%;
    position: relative;
    // gap: 60px;

    @include mq(bp-992, max) {
      max-width: 550px;
      justify-content: space-between;
    }

    &-item {
      width: 100%;

      @include mq(bp-414) {
        width: 50%;
      }

      @include mq(bp-992) {
        width: 33%;
      }
    }
  }

  &__box {
    cursor: pointer;
    margin: 20px 70px;
    transition: transform 0.7s;
    position: relative;
    z-index: 2;

    @include mq(bp-414) {
      margin: 20px;
    }

    @include mq(bp-768) {
      margin: 20px 40px;
    }

    @include mq(bp-1280) {
      margin: 20px 60px;
    }

    @include mq(bp-1366) {
      margin: 20px 100px;
    }

    &.is-active {
      @include mq(bp-414) {
        transform: translateY(180px);
      }

      .team__box-name,
      .team__box-position {
        color: $white;
      }

      .team__box-title {
        color: $white;
      }
    }

    &-bg {
      max-height: 260px;
      width: 100%;
      object-fit: cover;
    }

    &-title {
      @include fontr(14px, 16px, 700);

      display: flex;
      color: $black;
      transition: color 0.3s;
      margin-top: 15px;
    }

    &-name {
      @include fontr(18px, 24px, 700);

      color: $black;
      margin-top: 3px;
      margin-bottom: 10px;
      transition: color 0.3s;
    }

    &-position {
      @include fontr(13px, 15px, 500);

      display: flex;
      font-family: $second-font;
      font-style: italic;
      color: $text-gray;
      transition: color 0.3s;
    }
  }

  &__hide {
    color: $white;
    background: $dark-gray;

    @include mq(bp-414, max) {
      will-change: margin;
      transition: margin 0.8s;
    }

    &.is-active {
      @include mq(bp-414, max) {
        margin-top: -180px;
      }

      .team__hide-big-txt,
      .team__hide-small-txt {
        opacity: 1;
      }
    }

    &-container {
      padding: 190px 25px 50px;
    }

    &-big-txt,
    &-small-txt {
      font-family: $second-font;
      line-height: 1.4;
      opacity: 0;
      transition: 0.5s;
      transition-delay: 0.5s;
      white-space: pre-wrap;
    }

    &-big-txt {
      @include fontr(14px, 17px);

      margin-bottom: 20px;
    }

    &-small-txt {
      @include fontr(13px, 15px);
    }
  }
}
