.title {
    @include fontr(33px, 46px, 700);
    @include margin(15, 25, "bottom");

    font-family: $base-font;
    font-style: italic;
    line-height: 1.2;
    color: $black;
    text-align: left;

    &--lg {
        @include fontr(43px, 60px, 700);
        @include margin(30, 45, "bottom");

        font-style: normal;
        line-height: 1.05;
    }

    &--center {
        text-align: center;
        margin-left: 0;
        margin-right: 0;
    }

    &--right {
        text-align: right;
    }

    &--margin {
        @include margin(15, 20, "bottom");
    }
}

.subtitle {
    @include fontr(20px, 24px, 700);

    font-family: $base-font;
    color: $black;
    text-align: center;
    line-height: 1.2;

    &--italic {
        font-style: italic;
    }
}

.heading {
    @include fontr(14px, 16px, 900);
    @include tracking(250);

    color: $black;
    font-family: $second-font;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 5px;

    &--center {
        text-align: center;
    }

    &--cream {
        color: $dark-cream;
    }

    &--big {
        @include fontr(18px, 20px, 900);
    }
}

.description {
    @include fontr(14px, 14px, 400);

    color: $text-gray;
    font-family: $second-font;
    line-height: 1.4;

    &--center {
        text-align: center;
    }

    &--big {
        @include fontr(15px, 20px, 400);
    }
}
