/*********************/
/* ~*~ Pricelist ~*~ */
/********************/
.tariff {
  &__title {
    @include margin(30, 50, 'top');

    text-align: center;
  }

  .search {
    @include flex-center;

    max-width: 650px;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    &-input {
      @include padding(14, 17, 'top');
      @include padding(14, 17, 'bottom');
      @include padding(18, 20, 'right');
      @include padding(18, 20, 'left');

      @include fontr(14px, 15px, 400);
      @include tracking(50);

      width: 100%;
      color: $black;
      font-family: $second-font;
      background-color: $white;
      border-color: $black;
      margin-right: -5px;
      height: 100%;
    }

    &-container {
      @include flex-center;

      position: relative;
      align-self: stretch;
      background-color: $black;
      padding: 15px;

      &.is-active {
        .close-icon {
          transform: translate(50%, -50%);
        }

        .search-icon {
          transform: translateY(200%);
        }
      }
    }

    .close-icon {
      position: absolute;
      right: 50%;
      top: 50%;
      cursor: pointer;
      transform: translate(50%, -200%);
      transition: transform 0.6s;
    }

    .search-icon {
      transition: transform 0.6s;
    }
  }

  &__filters {
    @include flex-center;

    margin-bottom: 25px;

    &-title {
      @include fontr(14px, 15px, 400);
      @include tracking(50);

      font-family: $second-font;
      color: $black;
      opacity: 0.5;
      margin-right: 15px;
    }
  }

  &__options {
    @include flex-center;
  }

  &__filter {
    @include fontr(14px, 15px, 700);
    @include tracking(50);

    font-family: $second-font;
    color: $black;
    padding: 7px 17px;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    cursor: pointer;

    &.mixitup-control-active {
      font-weight: 500;
      color: $white;
      background-color: $cream;
    }
  }

  &__list {
    background-color: $white;

    @include mq(bp-768, max) {
      margin-right: -20px;
      margin-left: -20px;
    }
  }

  &__item {
    margin-bottom: 3px;

    &--light-gray {
      .tariff__item-display {
        background-color: $light-gray;
      }

      .tariff__subitem {
        border-bottom: 1px solid rgba(247, 249, 250, 0.6);
      }
    }

    &--dark-cream {
      .tariff__item-display {
        background-color: $cream;
      }

      .tariff__subitem {
        border-bottom: 1px solid rgba(240, 203, 187, 0.6);
      }
    }

    &--light-cream {
      .tariff__item-display {
        background-color: $light-cream;
      }

      .tariff__subitem {
        border-bottom: 1px solid rgba(255, 243, 237, 0.6);
      }
    }

    &--gray {
      .tariff__item-display {
        background-color: $gray;
      }

      .tariff__subitem {
        border-bottom: 1px solid rgba(221, 230, 237, 0.6);
      }
    }

    &-display {
      @include padding(15, 25, 'top');
      @include padding(15, 25, 'bottom');
      @include padding(15, 40, 'right');
      @include padding(15, 60, 'left');

      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      cursor: pointer;
      position: relative;
      z-index: 2;
      // border-bottom: 3px solid $white;

      &--disable {
        cursor: default;
      }

      &.is-active {
        .tariff__item-plus {
          opacity: 0;
        }

        .tariff__item-minus {
          opacity: 1;
        }
      }
    }

    &-title {
      @include fontr(14px, 20px, 700);
      @include tracking(23);

      color: $black;
      padding-right: 15px;
      text-align: left;
      line-height: 1.4;
    }

    &-btn {
      @include flex-center;

      width: 25px;
      height: 25px;
      position: relative;

      @include mq(bp-768, max) {
        width: 18px;
        height: 18px;
      }
    }

    &-plus {
      opacity: 1;
      transition: opacity 0.4s;
    }

    &-minus {
      position: absolute;
      top: 50%;
      right: 50%;
      opacity: 0;
      transform: translate(50%, -50%);
      transition: opacity 0.4s;
    }
  }

  &__subitem {
    @include padding(10, 15, 'top');
    @include padding(10, 15, 'bottom');
    @include padding(15, 40, 'right');
    @include padding(15, 60, 'left');

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: $white;

    &:nth-last-child(1) {
      border: 0;
    }

    &--category {
      @include padding(20, 100, 'left');
    }

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @include mq(bp-768, max) {
        align-items: flex-start;
      }
    }

    &-left {
      @include flex-center;

      @include mq(bp-768, max) {
        justify-content: flex-start;
        width: 60%;
      }
    }

    &-title {
      @include fontr(13px, 16px, 700);
      @include tracking(23);

      color: $black;
      padding-right: 15px;
    }

    &-desc {
      @include flex-center;

      width: 24px;
      height: 24px;
      background-color: $black;
      position: relative;
      cursor: pointer;
      transition: opacity 0.3s;

      &.is-active {
        .tariff__subitem-plus {
          opacity: 0;
        }

        .tariff__subitem-minus {
          opacity: 1;
        }
      }

      &:hover {
        opacity: 0.7;
      }

      @include mq(bp-768, max) {
        order: -1;
        margin-right: 10px;
      }
    }

    &-plus {
      opacity: 1;
      transition: opacity 0.3s;
    }

    &-minus {
      position: absolute;
      top: 50%;
      left: 50%;
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: opacity 0.3s;
    }

    &-right {
      @include mq(bp-768) {
        @include flex-center;
      }

      @include mq(bp-768, max) {
        display: grid;
        grid-template-columns: 35px max-content;
        width: 40%;
        justify-content: flex-end;
      }
    }

    &-promotional {
      @include fontr(10px, 14px, 500);

      font-family: $second-font;
      color: $white;
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      padding: 7px 20px;
      background-color: $cream;
      margin-right: 20px;

      @include mq(bp-768, max) {
        margin-right: 0;
        margin-top: 5px;
        grid-column: 2/ 3;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-info {
      @include fontr(10px, 14px, 500);
      @include flex-center;

      color: $white;
      font-family: $second-font;
      border-radius: 50%;
      background-color: $cream;
      padding: 5px;
      width: 22px;
      height: 22px;
      cursor: pointer;
      margin-right: 20px;

      @include mq(bp-768, max) {
        order: -2;
      }

      @include mq(bp-992) {
        width: 28px;
        height: 28px;
      }
    }

    &-tooltip {
      @include fontr(11px, 15px);

      font-family: $second-font;
      padding: 7px;
      max-width: 250px;
    }

    &-box {
      @include flex-center;

      @include mq(bp-768, max) {
        order: -1;
        grid-column: 2 / 3;
        justify-content: flex-end;
      }
    }

    &-lower-price {
      @include fontr(13px, 16px, 700);
      @include tracking(25);

      color: $black;
      text-decoration: line-through;
      margin-right: 15px;
      white-space: nowrap;
    }

    &-price {
      @include fontr(13px, 16px, 700);
      @include tracking(25);

      color: $black;
      white-space: nowrap;
    }

    &-description {
      @include mq(bp-768) {
        max-width: 90%;
      }
    }

    &-text {
      @include padding(15, 25, 'top');
      @include padding(15, 25, 'bottom');
      @include fontr(13px, 15px, 400);

      line-height: 1.3;
      font-family: $second-font;

      @include mq(bp-414, max) {
        text-align: center;
      }
    }
  }
}

/********************/
/* ~*~ Payment ~*~ */
/*******************/
.pay {
  &__title {
    text-align: center;
  }

  &__list {
    @include margin(30, 60, 'top');

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 50px;
    max-width: 750px;
    margin-right: auto;
    margin-left: auto;

    @include mq(bp-414) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 30px;
    }

    @include mq(bp-768) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 40px;
    }
  }

  &__box {
    @include flex-column-center;

    position: relative;
    justify-content: flex-start;

    &-bg {
      &-color {
        @include flex-center;
        @include margin(15, 25, 'bottom');

        position: relative;
        max-height: 180px;
        max-width: 180px;

        @include mq(bp-1440) {
          max-height: 200px;
          max-width: 200px;
        }

        @include mq(bp-414, max) {
          max-width: 150px;
          max-height: 150px;
        }

        svg {
          width: 100%;
          height: auto;
          display: block;

          * {
            fill: $dark-gray;
          }
        }
      }

      &-img {
        display: block;
        position: absolute;
        z-index: 1;
        pointer-events: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
          max-height: 160px;
          max-width: 160px;

          @include mq(bp-1440) {
            max-height: 180px;
            max-width: 180px;
          }

          @include mq(bp-414, max) {
            max-width: 130px;
            max-height: 130px;
          }
        }

        svg * {
          fill: transparent;
        }
      }
    }
  }
}
