.regular-text {
  // background-color: $base-white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  pre,
  ul,
  ol,
  img,
  .wp-block-quote,
  .wp-block-columns,
  .wp-block-group {
    @extend .container;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // color: $navyblue;
    font-size: initial;
    margin-bottom: 15px;

    @include mq(bp-992, max) {
      text-align: center !important;
    }

    @include mq(bp-992) {
      margin-bottom: 30px;
    }
  }

  h1 {
    @include fontr(44px, 80px);
  }

  h2 {
    @include fontr(35px, 48px);
  }

  h3 {
    @include fontr(24px, 35px);
  }

  h4 {
    @include fontr(18px, 25px);
  }

  h5 {
    @include fontr(16px, 18px);
  }

  h6 {
    @include fontr(14px, 16px);
  }

  p:not([class]),
  pre {
    @include fontr(13px, 15px, 400);

    color: $text-gray;
    line-height: 1.4;
    font-style: normal;
    font-family: $second-font;

    @include mq(bp-992, max) {
      text-align: center !important;
    }
  }

  .wp-block-column {
    margin-right: 40px;

    @include mq(bp-768, max) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  .wp-block-quote {
    @include flex-column-center;

    position: relative;
    padding-top: 130px !important;
    padding-bottom: 100px !important;
    // background-color: $darkblue;

    @include mq(bp-992, max) {
      padding-top: 100px !important;
      padding-bottom: 50px !important;
    }

    @include mq(bp-414, max) {
      margin-right: -15px;
      margin-left: -15px;
      padding-right: 15px;
      padding-left: 15px;
    }

    p {
      @include fontr(18px, 24px, 600);

      // color: $base-white;
      font-family: $base-font;
      color: $cream;
      font-style: initial !important;
      max-width: 600px;
      line-height: 1.3 !important;
      text-align: center;

      &:nth-child(1) {
        position: relative;

        &::after {
          @include pseudo();

          width: 55px;
          height: 55px;
          top: -60px;
          left: -40px;
          background-image: url('../../../images/svg/quotate.svg');
          background-size: contain;

          @include mq(bp-992, max) {
            top: -70px;
            left: 50%;
            transform: translateX(-50%);
          }

          @include mq(bp-1536) {
            top: -70px;
            left: -70px;
            width: 80px;
            height: 80px;
          }
        }
      }
    }

    cite {
      @include fontr(13px, 15px);

      position: relative;
      z-index: 1;
      font-style: initial;
      font-family: $second-font;
      color: $text-gray;
      max-width: 600px;
      width: 100%;
      text-align: right;

      @include mq(bp-992, max) {
        text-align: center;
      }
    }
  }
  ol,
  ul {
    margin-left: 10px;

    @include mq(bp-992) {
      margin-left: 15px;
    }
  }

  // div {
  //   @include margin(20, 30, 'top');
  //   @include margin(10, 20, 'bottom');
  // }
  // li {
  //   max-width: 600px;
  // }

  // p {
  //   max-width: 1000px;
  // }

  .wp-block-media-text__content {
    padding-left: 0 !important;
  }
}
