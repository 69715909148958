/* Cookie */
.cookie-box {
  position: fixed;
  z-index: 1040;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $dark-gray;
  color: $white;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translateY(0px);

  @include mq(bp-768, max) {
    width: 100%;
  }

  @include mq(bp-768) {
    flex-direction: row;
    max-width: 1230px;
  }

  .cookie__column-left {
    @include fontr(12px, 13px, 400);

    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $second-font;
    padding: 20px;
    line-height: 1.3;
    max-width: 550px;

    @include mq(bp-768) {
      justify-content: flex-start;
    }

    @include mq(bp-992) {
      padding: 15px 0px 15px 40px;
    }

    span {
      @include mq(bp-768, max) {
        text-align: center;
      }
    }
  }

  .cookie__column-right {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq(bp-768, max) {
      margin-bottom: 25px;
    }

    .btn {
      @include mq(bp-768) {
        transform: translateX(50%);
      }
    }
  }

  &--hide {
    opacity: 0;
    transform: translateY(100%);
  }
}
