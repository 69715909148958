// Contianers
.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    padding-right: 50px;
    padding-left: 50px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1336px) {
    max-width: 1200px;
  }

  @media (min-width: 1440px) {
    max-width: 1366px;
  }

  @media (min-width: 1900px) {
    max-width: 1400px;
  }
}

.container-xs {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    padding-right: 50px;
    padding-left: 50px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 1336px) {
    max-width: 1000px;
  }

  @media (min-width: 1440px) {
    max-width: 1100px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1336px) {
    max-width: 1280px;
  }

  @media (min-width: 1440px) {
    max-width: 1380px;
  }

  @media (min-width: 1900px) {
    max-width: 1640px;
  }
}

// Padding
.padding-xl {
  @include padding(80, 170, 'bottom');
}

.padding-md {
  @include padding(70, 140, 'bottom');
}

.padding-xs {
  @include padding(50, 110, 'bottom');
}

// /* ----- */
// /* List  */
// /* ----- */
.global-list {
  ul {
    text-align: left;
    li {
      @include fontr(13px, 15px, 400);
      @include margin(5, 10, 'bottom');
      @include padding(16, 18, 'left');

      line-height: 1.4;
      position: relative;
      color: $text-gray;
      font-family: $second-font;
    }
  }

  ul {
    li::before {
      @include pseudo();

      top: 0.4em;
      left: 0;
      width: 9px;
      height: 9px;
      background: url('../../images/svg/list-icon.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

/* ----- */
/* Bradcrumbs  */
/* ----- */
.main-breadcrumbs {
  @include tracking(50);

  font-size: 13px;
  text-align: center;
  color: $black;
  z-index: 101;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: $second-font;

  @include mq(bp-992, max) {
    display: none;
  }

  a {
    display: inline-flex;
    align-items: center;
    margin-right: 15px;
    transition: color 0.3s;
    // white-space: nowrap;

    &:hover {
      color: $dark-gray;
    }

    &::after {
      content: '';

      display: inline-flex;
      width: 10px;
      height: 10px;
      margin-left: 15px;
      background-image: url('../../images/svg/separator.svg');
      background-size: contain;
      background-position: center;
    }
  }

  .current-item {
    opacity: 0.7;
  }
}

/* ------------ */
/* Global title */
/* ----------- */
.global__title {
  @include margin(30, 50, 'top');

  text-align: center !important;
  display: flex;
  justify-content: center;
}
