/* ----- */
/* Sale */
/* ----- */
.home-sale {
  &__container {
    @include flex-column-center;
    @include padding(40, 50, 'top');
    // @include padding(60, 70, 'bottom');

    position: relative;

    @include mq(bp-414, max) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__text {
    @include flex-column-center;

    position: relative;
    z-index: 1;
  }

  &__bg {
    display: flex;
    width: 100%;
    height: 350px;

    @include mq(bp-768) {
      height: 50vw;
    }

    @include mq(bp-992) {
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      height: 100%;
    }

    &-img {
      object-fit: cover;
      width: 100%;
      display: flex;
    }
  }

  &__row {
    @include margin(40, 55, 'bottom');

    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    grid-gap: 10px;
    justify-content: flex-start;
    width: 100%;
    z-index: 1;

    @include mq(bp-414) {
      @include margin(40, 55, 'top');

      grid-gap: 15px;
      grid-template-columns: minmax(1fr, 400px);
    }

    @include mq(bp-768) {
      grid-template-columns: repeat(auto-fit, calc(50% - 10px));
    }

    @include mq(bp-992) {
      grid-template-columns: repeat(auto-fit, calc(28% - 15px));
      margin-right: -12.5%;
    }

    @include mq(bp-1366) {
      grid-template-columns: repeat(auto-fit, calc(25% - 15px));
    }
  }

  &__box {
    @include padding(45, 50, 'left');
    @include padding(45, 50, 'right');
    @include flex-column;

    justify-content: space-between;
    align-items: center;
    flex-basis: 25%;
    padding-top: 60px;
    padding-bottom: 60px;

    @include mq(bp-414) {
      @include padding(35, 50, 'top');
      @include padding(35, 50, 'bottom');
    }

    &--gray {
      background-color: $gray;

      .home-sale__box-desc {
        color: $text-gray;
      }
    }

    &--cream {
      background-color: $cream;

      .home-sale__box-btn::before {
        background-color: $gray;
      }
    }

    &--dark-gray {
      background-color: $dark-gray;
    }

    &-title {
      @include fontr(12px, 13px, 900);

      flex-basis: 40%;
      text-align: center;
      margin-bottom: 10px;
    }

    &-desc {
      @include fontr(33px, 40px, 700);

      color: $white;
      font-style: italic;
      text-align: center;
      flex-basis: 30%;
      margin-bottom: 30px;

      p {
        margin-bottom: 0;
      }
    }

    &-link {
      flex-basis: 30%;
      width: 100%;
    }

    &-btn {
      width: 100%;
    }
  }

  &__btn {
    position: relative;
    z-index: 1;
  }
}

/* ---------- */
/* Instagram */
/* --------- */
.instagram {
  &__container {
    padding: 15px;

    @include mq(bp-414, max) {
      @include margin(-6, -20, 'right');
      @include margin(-6, -20, 'left');

      padding: 0;
    }

    @include mq(bp-992) {
      @include padding(15, 140, 'left');
      @include padding(15, 140, 'right');
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;

    @include mq(bp-992, max) {
      flex-wrap: wrap;
      max-width: 500px;
      padding: 0;
    }
  }

  &__column {
    @include padding(6, 20, 'right');
    @include padding(6, 20, 'left');
    @include padding(6, 20, 'top');
    @include padding(6, 20, 'bottom');

    @include mq(bp-992) {
      padding-bottom: 0;
      padding-top: 0;

      &:nth-of-type(1) {
        padding-left: 0;
      }

      &:nth-last-of-type(1) {
        padding-right: 0;
      }
    }
  }

  &__bg-img {
    object-fit: cover;
    width: 100%;
  }

  &__box {
    position: relative;

    &-btn {
      @include center('both');

      position: absolute;

      @include mq(bp-414, max) {
        left: 0;
        transform: translateX(50%);
      }
    }
  }

  // Small
  &__column--small {
    width: 30%;
    align-self: flex-end;

    @include mq(bp-992) {
      width: 22%;
    }
  }

  // Big
  &__column--big {
    width: 70%;

    @include mq(bp-992) {
      width: 40%;
    }
  }

  // Medium
  &__column--medium {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    min-height: 100%;
    width: 40%;

    @include mq(bp-992) {
      width: 28%;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 25%;

    &--desktop {
      @include mq(bp-992, max) {
        display: none;
      }
    }

    &--mobile {
      margin-bottom: 25px;

      @include mq(bp-992) {
        display: none;
      }
    }
  }

  &__title {
    margin-bottom: 0;
  }

  &__bg-medium {
    display: flex;
    min-height: 75%;
  }

  // Smaller
  &__column--smaller {
    width: 20%;

    @include mq(bp-992) {
      width: 10%;
    }
  }

  &__bg-smaller {
    display: flex;
    height: 25%;

    @include mq(bp-992) {
      transform: translateX(-50%);
    }
  }
}

/* ------- */
/* Famous */
/* ------ */
.famous {
  @include padding(60, 85, 'top');
  @include padding(45, 140, 'bottom');

  background-color: $light-cream;

  @include mq(bp-414, max) {
    padding-top: 90px;
  }

  &__container {
    @include mq(bp-414, max) {
      padding: 0;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include mq(bp-768, max) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  &__column {
    @include mq(bp-768, max) {
      @include flex-column-center;
    }

    @include mq(bp-414, max) {
      &-left {
        width: 100%;
      }

      &-right {
        padding-right: 20px;
        padding-left: 20px;
        width: 100%;
      }
    }

    @include mq(bp-768) {
      width: 50%;

      &-left {
        padding-right: 50px;
      }

      &-right {
        padding-left: 50px;
      }
    }
  }

  &__txt {
    @include mq(bp-414, max) {
      position: relative;
    }
  }

  &__subtitle {
    display: inline-flex;
    align-items: flex-end;

    @include mq(bp-414, max) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::after {
      content: '';

      width: 45px;
      height: 45px;
      margin-left: 5px;
      background-image: url('../../images/svg/star.svg');
      background-size: contain;
      transform: translateY(30%);

      @include mq(bp-414, max) {
        width: 40px;
        height: 40px;
        margin-left: 0;
        position: absolute;
        top: -40px;
        right: -10px;
        transform: none;
      }
    }
  }

  &__widget {
    @include padding(15, 35, 'left');
    @include padding(15, 35, 'right');

    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 500px;
    background-color: $blue;

    @include mq(bp-768, max) {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    @include mq(bp-414, max) {
      width: 100%;
      margin-bottom: 90px;
    }

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
    }

    &-percent {
      @include tracking(50);

      font-family: $second-font;
      font-size: 40px;
      font-weight: 700;
      color: $white;
    }

    &-btn {
      @include mq(bp-414) {
        transform: translateX(50%);
      }
    }

    &-desc {
      @include fontr(14px, 20px, 400);
      @include tracking(50);

      color: $white;
      font-family: $second-font;
    }

    &-line {
      margin: 15px 0;
      border: none;
      outline: none;
      height: 1px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }

    &-logo {
      display: flex;
      margin-left: auto;
      width: 145px;
    }
  }

  &__patient {
    @include mq(bp-414, max) {
      @include flex-column-center;

      position: relative;
    }
  }

  &__patient-subtitle {
    display: inline-flex;
    align-items: flex-end;

    @include mq(bp-414, max) {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &::after {
      content: '';

      width: 60px;
      height: 45px;
      margin-left: 15px;
      background-image: url('../../images/svg/quotate-brown.svg');
      background-size: contain;
      transform: translateY(30%);

      @include mq(bp-414, max) {
        width: 43px;
        height: 33px;
        margin-left: 0;
        position: absolute;
        top: -40px;
        right: -10px;
        transform: none;
      }
    }
  }

  // .swiper-slide-active {
  //   opacity: 1;
  //   transition: opacity 0.5s;
  // }

  &__swiper {
    @include mq(bp-768, max) {
      width: 100%;
    }
  }

  &__slide {
    // opacity: 0;
    // transition: opacity 0.5s;

    @include mq(bp-768, max) {
      @include flex-column-center;
    }

    &-desc {
      @include fontr(14px, 18px, 400);
      @include margin(10, 30, 'bottom');

      line-height: 1.4;
      color: $black;

      @include mq(bp-768, max) {
        margin-right: 25px;
        margin-left: 25px;
        text-align: center;
      }
    }

    &-name {
      @include fontr(13px, 16px, 500);

      color: #d0c9c2;
      font-family: $second-font;

      @include mq(bp-768, max) {
        text-align: center;
      }
    }
  }

  &__opinions {
    position: relative;
    max-width: 560px;
    // border: 1px solid red;

    @include mq(bp-768, max) {
      @include flex-column-center;
      width: 100%;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    margin-top: 30px;
  }

  &-pagination,
  &-button-next,
  &-button-prev {
    position: relative;
  }

  &-button-next,
  &-button-prev {
    width: 40px;
    right: initial;
    left: initial;
    height: 16px;
    margin-top: 0;
  }

  &-pagination {
    margin-right: 30px;
    margin-left: 30px;

    .swiper-pagination-bullet {
      width: 13px;
      height: 13px;

      &::after {
        width: 7px;
        height: 7px;
      }
    }
  }
}
