.picture-header {
  &__container {
    @include margin(30, 50, 'top');
  }

  &__title {
    text-align: center;
  }

  &__row {
    display: flex;
    position: relative;
    flex-direction: column;

    @include mq(bp-768) {
      flex-direction: row;
    }
  }

  &__box {
    @include flex-column-center;

    padding-top: 30px;
    position: relative;
    z-index: 1;
    align-items: center;

    @include mq(bp-768) {
      @include padding(70, 100, 'top');
      @include padding(110, 160, 'bottom');

      align-items: flex-start;
    }

    @include mq(bp-1280) {
      align-items: center;
      background: linear-gradient(90deg, #fff 50%, transparent 50%);
    }
  }

  &__name {
    max-width: 530px;
    margin-bottom: 0;

    @include mq(bp-768, max) {
      text-align: center;
    }
  }

  &__btn {
    @include margin(30, 45, 'top');
  }

  &__picture {
    display: flex;

    @include mq(bp-768, max) {
      order: -1;
      max-height: 360px;
      max-width: 500px;
      margin: 0 auto;
    }

    @include mq(bp-414, max) {
      margin-right: -20px;
      margin-left: -20px;
    }

    @include mq(bp-768) {
      position: absolute;
      right: 0;
      top: 0;
      width: 85%;
      height: 100%;
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;
  }
}
