@import "./shared/pricelist";

.sale {
    &__container {
        @include margin(30, 50, "top");
        @include flex-column-center;
    }

    &__description {
        text-align: center;
        max-width: 800px;
    }

    &__list {
        @include margin(60, 80, "top");

        display: grid;
        grid-template-columns: minmax(1fr, 400px);
        grid-auto-rows: 1fr;
        grid-gap: 15px;
        justify-content: center;
        width: 100%;

        @include mq(bp-768) {
            grid-template-columns: repeat(auto-fit, calc(50% - 15px));
        }

        @include mq(bp-992) {
            grid-template-columns: repeat(auto-fit, calc(33.33% - 15px));
        }

        @include mq(bp-1366) {
            grid-template-columns: repeat(auto-fit, calc(25% - 15px));
        }
    }

    &__box {
        @include padding(45, 50, "left");
        @include padding(45, 50, "right");
        @include padding(35, 50, "top");
        @include padding(35, 50, "bottom");
        @include flex-column;

        justify-content: space-between;
        align-items: center;
        flex-basis: 25%;

        &--gray {
            background-color: $gray;

            .sale__box-desc {
                color: $text-gray;
            }
        }

        &--cream {
            background-color: $cream;

            .sale__box-btn::before {
                background-color: $gray;
            }
        }

        &--dark-gray {
            background-color: $dark-gray;
        }

        &-title {
            @include fontr(12px, 13px, 900);

            flex-basis: 40%;
            text-align: center;
            margin-bottom: 10px;
        }

        &-desc {
            @include fontr(33px, 40px, 700);

            color: $white;
            font-style: italic;
            text-align: center;
            flex-basis: 30%;
            margin-bottom: 30px;

            p {
                margin-bottom: 0;
            }
        }

        &-link {
            flex-basis: 30%;
            width: 100%;
        }

        &-btn {
            width: 100%;
        }
    }
}
