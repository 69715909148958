/***************/
/* ~*~ Map ~*~ */
/***************/
.map {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include mq(bp-768) {
    height: 100vh;
  }

  &__wrapper {
    position: relative;

    @include mq(bp-768, max) {
      display: flex;
      flex-direction: column;
    }

    @include mq(bp-768) {
      height: 100%;
    }
  }

  &__title {
    @include margin(30, 50, 'top');

    text-align: center;
  }

  &__container {
    position: relative;
    z-index: 2 !important;
    pointer-events: none;

    @include mq(bp-768) {
      height: 100%;
    }
  }

  &__content {
    @include padding(30, 60, 'right');
    @include padding(30, 60, 'left');
    @include padding(40, 70, 'top');
    @include padding(40, 70, 'bottom');

    pointer-events: initial;
    z-index: 2;
    background-color: $white;

    @include mq(bp-768, max) {
      @include flex-column-center;
    }

    @include mq(bp-768) {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &-logo {
      @include mq(bp-768, max) {
        display: none;
      }
    }

    &-text {
      @include fontr(16px, 21px, 700);
      @include margin(15, 25, 'top');

      color: $black;
      line-height: 1.4;

      @include mq(bp-768, max) {
        text-align: center;
      }

      a {
        transition: color 0.3s;

        &:hover {
          color: $dark-cream;
        }
      }
    }

    &-sm {
      display: flex;

      &-item {
        @include flex-center;

        margin-right: 15px;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        background-color: $black;
        transition: opacity 0.3s;

        svg path {
          fill: $white;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    &-btn {
      @include mq(bp-992, max) {
        margin-top: 20px;
      }

      @include mq(bp-992) {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
      }
    }
  }

  .map__box {
    width: 100%;

    @include mq(bp-768, max) {
      height: 100%;
      order: -1;
      min-height: 450px;
    }

    @include mq(bp-768) {
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }

  .mapboxgl-canvas:focus {
    outline: none;
  }

  .mapboxgl-marker {
    background-image: url('../../images/svg/location-marker.svg');
    background-size: cover;
    width: 40px;
    height: 40px;
    opacity: 0;
    animation: fadein 2s forwards;
    animation-delay: 2s;

    svg {
      display: none;
    }
  }

  &__coords {
    display: none;
  }
}

/*****************/
/* ~*~ Hours ~*~ */
/*****************/
.hours {
  @include padding(50, 130, 'top');
  @include padding(50, 130, 'bottom');

  margin-bottom: 30px;
  background-color: $cream;
  position: relative;

  @include mq(bp-992) {
    margin-bottom: 40px;
  }

  &__row {
    @include padding(20, 40, 'left');
    @include padding(20, 40, 'right');

    display: grid;
    align-items: flex-start;
    position: relative;

    @include mq(bp-1280) {
      grid-template-columns: 0.3fr 0.7fr;
      grid-column-gap: 7%;
    }

    @include mq(bp-1366) {
      grid-template-columns: 0.35fr 0.65fr;
    }
  }

  &__bg {
    position: absolute;
    left: 0;
    bottom: -5px;

    @include mq(bp-1536, max) {
      max-width: 350px;
      left: -100px;
    }

    @include mq(bp-1280, max) {
      left: -90px;
    }

    @include mq(bp-992, max) {
      max-width: 300px;
      left: -90px;
    }

    @include mq(bp-768, max) {
      display: none;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__col {
    &-right {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include mq(bp-768, max) {
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &__title {
    font-style: normal;
    margin-bottom: 30px;
    text-align: center;

    @include mq(bp-1280) {
      margin-bottom: 0;
      text-align: left;
    }
  }

  &__box {
    @include flex-column-center;

    @include mq(bp-768, max) {
      margin-bottom: 50px;
    }

    @include mq(bp-768) {
      @include margin(15, 25, 'left');
      @include margin(15, 25, 'right');
    }

    &:nth-child(1) {
      margin-left: 0;
    }

    &:nth-last-child(1) {
      margin-right: 0;

      @include mq(bp-768, max) {
        margin-bottom: 0;
      }
    }

    &-time {
      @include fontr(28px, 38px, 700);
      @include margin(12, 20, 'bottom');

      line-height: 1.2;
      color: $black;
      text-align: center;
    }

    &-days {
      @include fontr(18px, 20px, 700);
      @include tracking(250);

      display: flex;
      color: $text-gray;
      font-family: $second-font;
      text-align: center;
    }
  }
}
