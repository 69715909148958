/********************/
/* ~*~ Details ~*~ */
/*******************/
.training-details {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr;

    @include mq(bp-992) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__box {
    @include mq(bp-992, max) {
      @include flex-column-center();
    }

    &--time,
    &--level,
    &--price {
      @include margin(50, 70, 'bottom');
    }

    &-level {
      display: flex;
      align-items: center;
    }

    &--desc {
      @include mq(bp-992, max) {
        @include margin(50, 70, 'bottom');

        order: -1;
      }
    }
  }

  &__balls {
    @include flex-center;

    padding: 9px 18px;
    margin-right: 20px;
    background-color: $cream;

    @include mq(bp-992) {
      margin-right: 25px;
      padding: 11px 22px;
    }
  }

  &__time {
    @include mq(bp-992, max) {
      @include flex-column-center;
    }

    &-hours {
      @include fontr(18px, 30px, 900);
      @include tracking(20);

      color: $text-gray;
      font-family: $second-font;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    &-subtitle {
      font-weight: 300;

      @include mq(bp-992, max) {
        text-align: center;
      }
    }
  }

  &__level {
    text-transform: capitalize;
  }

  &__price {
    @include fontr(18px, 30px, 900);
    @include tracking(20);

    color: $text-gray;
    font-family: $second-font;
    margin-bottom: 10px;
  }

  &__desc {
    max-width: 630px;

    @include mq(bp-992, max) {
      text-align: center;
      max-width: 500px;
    }
  }

  &__program {
    @include margin(50, 70, 'bottom');

    width: 100%;
    max-width: 500px;

    @include mq(bp-992) {
      column-count: 2;
      column-gap: 30px;
      max-width: initial;
    }
  }

  &__methods {
    max-width: 630px;
    padding-bottom: 50px;

    @include mq(bp-992, max) {
      text-align: center;
      padding-bottom: 70px;
      max-width: 500px;
    }
  }

  &__bg {
    display: flex;
    justify-content: center;

    @include mq(bp-992, max) {
      display: none;
    }

    svg {
      margin-top: 50px;
      margin-bottom: -50px;
      max-width: 350px;
      width: 18vw;
      height: auto;
    }
  }
}
