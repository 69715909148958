$grid-columns: 12 !default;

.row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;

  &-reverse {
    flex-direction: row-reverse;
  }
}

.col {
  flex: 0 0 auto;
}

@for $i from 1 through $grid-columns {
  .col-xs-#{$i} {
    flex-basis: (100 / ($grid-columns / $i)) * 1%;
    max-width: (100 / ($grid-columns / $i)) * 1%;
  }
}

@media only screen and (min-width: 576px) {
  @for $i from 1 through $grid-columns {
    .col-sm-#{$i} {
      flex-basis: (100 / ($grid-columns / $i)) * 1%;
      max-width: (100 / ($grid-columns / $i)) * 1%;
    }
  }
}

@media only screen and (min-width: 768px) {
  @for $i from 1 through $grid-columns {
    .col-md-#{$i} {
      flex-basis: (100 / ($grid-columns / $i)) * 1%;
      max-width: (100 / ($grid-columns / $i)) * 1%;
    }
  }
}

@media only screen and (min-width: 992px) {
  @for $i from 1 through $grid-columns {
    .col-lg-#{$i} {
      flex-basis: (100 / ($grid-columns / $i)) * 1%;
      max-width: (100 / ($grid-columns / $i)) * 1%;
    }
  }
}

@media only screen and (min-width: 1366px) {
  @for $i from 1 through $grid-columns {
    .col-xl-#{$i} {
      flex-basis: (100 / ($grid-columns / $i)) * 1%;
      max-width: (100 / ($grid-columns / $i)) * 1%;
    }
  }
}
