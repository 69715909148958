/* ----- */
/* Slider */
/* ----- */
.header {
  &__container {
    position: relative;

    @include mq(bp-414) {
      margin-top: 30px;
    }
  }

  &__title {
    text-align: center;
    margin-top: 30px;
  }

  &__bg {
    position: absolute;
    bottom: -250px;
    right: 50%;
    transform: translateX(50%);
    max-width: 350px;
    width: 20vw;
    min-width: 200px;
    display: flex;

    @include mq(bp-1280, max) {
      display: none;
    }

    @include mq(bp-2560) {
      transform: translateX(25%);
    }
  }

  &__slide {
    display: flex;
    overflow: hidden;
    padding: 0 110px;

    @include mq(bp-992, max) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    @include mq(bp-1280) {
      padding: 0 90px;
    }

    @include mq(bp-1440) {
      padding: 0 150px;
    }

    @include mq(bp-1920) {
      padding: 0;
    }
  }

  .swiper-slide-active {
    .slide__content {
      opacity: 1;
    }
    .slide__above-title {
      opacity: 1;
      transform: none;
      transition-delay: 0.2s;
    }

    .slide__title {
      opacity: 1;
      transform: none;
      transition-delay: 0.3s;
    }

    .slide__desc {
      opacity: 1;
      transform: none;
      transition-delay: 0.4s;
    }

    .slide__btn {
      @include mq(bp-1280, max) {
        opacity: 1;
        transform: none;
        transition-delay: 0.5s;
      }
    }
  }

  .swiper-homepage {
    @include mq(bp-414, max) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .slide__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;

    @include mq(bp-414, max) {
      padding: 0 20px;
    }

    @include mq(bp-768) {
      width: 60%;
    }

    @include mq(bp-992) {
      align-items: flex-end;
      margin-top: 0;
      margin-right: 50px;
    }

    @include mq(bp-1280) {
      margin-right: 0;
      flex-direction: row;
      align-items: center;
    }
  }

  .slide__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 550px;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s;

    @include mq(bp-992) {
      align-items: flex-end;
    }
  }

  .slide__picture {
    width: 33vw;
    height: 30vw;
    max-width: 700px;
    max-height: 600px;
    display: flex;

    @include mq(bp-992, max) {
      order: -1;
      height: 350px;
      width: 100%;
    }

    @include mq(bp-414, max) {
      height: 300px;
    }
  }

  .slide__image {
    width: 1100%;
    object-fit: cover;
  }

  .slide__btn {
    z-index: 2;
    transform: translateY(85px) translateZ(0);

    @include mq(bp-1280, max) {
      opacity: 0;
      transition: transform 0.9s, opacity 0.4s;
    }

    @include mq(bp-992) {
      transform: translateX(-105px) translateZ(0);
    }

    @include mq(bp-1280) {
      transform: translateX(50%);
      margin-left: -50px;
    }

    @include mq(bp-1920) {
      margin-right: -100px;
    }

    @include mq(bp-2560) {
      margin-right: 0;
    }
  }

  .slide__above-title {
    @include fontr(13px, 14px, 900);
    @include tracking(250);

    color: $dark-cream;

    font-family: $second-font;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 5px;
    text-align: right;
    transform: translateY(25px);

    @include mq(bp-992) {
      transform: translateX(-45px);
    }
  }

  .slide__title {
    text-align: center;
    transform: translateY(45px);

    @include mq(bp-992) {
      text-align: right;
      transform: translateX(-65px);
    }
  }

  .slide__desc {
    text-align: center;
    max-width: 400px;
    transform: translateY(65px);
    margin-bottom: 20px;

    @include mq(bp-992) {
      transform: translateX(-85px);
      text-align: right;
    }

    @include mq(bp-1280) {
      margin-bottom: 0;
    }
  }

  .slide__above-title,
  .slide__title,
  .slide__desc {
    opacity: 0;
    transition: transform 0.9s, opacity 0.4s;
  }

  &-button-next,
  &-button-prev {
    margin-top: 0;
    width: 70px;

    @include mq(bp-992, max) {
      display: none;
    }

    @include mq(bp-992) {
      transform: translateY(-50%);
    }

    @include mq(bp-1280) {
      width: 90px;
    }

    @include mq(bp-1920) {
      width: 110px;
    }
  }

  &-button-next {
    right: 30px;

    @include mq(bp-1920) {
      right: 50px;
    }
  }

  &-button-prev {
    left: 30px;

    @include mq(bp-1920) {
      left: 50px;
    }

    &::before {
      @include pseudo();

      width: 10vw;
      max-width: 240px;
      min-width: 130px;
      height: 15vw;
      min-height: 200px;
      max-height: 360px;
      max-width: 240px;
      left: -30px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('../../../images/svg/header-gray-blob.svg');
      background-size: contain;

      @include mq(bp-992, max) {
        display: none;
      }

      @include mq(bp-1920) {
        left: -50px;
      }
    }
  }

  &-pagination {
    right: 50%;
    left: initial !important;
    bottom: initial !important;
    top: 270px;
    width: auto !important;
    transform: translateX(50%);

    @include mq(bp-414) {
      top: 320px;
    }

    @include mq(bp-992) {
      top: 50%;
      right: 130px;
      transform: translateY(-50%);
    }

    @include mq(bp-1280) {
      right: 150px;
    }

    @include mq(bp-1440) {
      right: 170px;
    }

    @include mq(bp-1536) {
      right: 200px;
    }
  }
}
