/*******************/
/* ~*~ Header ~*~ */
/******************/
.cat-header {
  &__container {
    @include margin(30, 50, 'top');
    @include flex-column-center;
  }

  &__row {
    @include flex-column-center;

    @include mq(bp-768) {
      @include flex-center;
    }
  }

  &__col {
    @include mq(bp-768) {
      width: 50%;
    }

    &-left {
      @include flex-column-center;

      order: 1;
      margin-top: 20px;

      @include mq(bp-768) {
        @include padding(50, 150, 'right');

        justify-content: flex-start;
        order: initial;
      }
    }

    &-right {
      @include mq(bp-414, max) {
        margin-left: -20px;
        margin-right: -20px;
      }
    }
  }

  &__desc,
  &__name {
    @include mq(bp-768, max) {
      text-align: center;
    }
  }

  &__picture {
    display: flex;
    max-width: 400px;

    @include mq(bp-768) {
      max-width: 650px;
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;
  }
}

/***********************/
/* ~*~ Categories ~*~ */
/**********************/
.cat-list {
  &__text {
    @include flex-column-center;
  }

  &__row {
    @include margin(30, 60, 'top');

    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 15px;

    @include mq(bp-992, max) {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      padding-right: 25px;
    }

    @include mq(bp-414, max) {
      margin-left: -20px;
      grid-row-gap: 5px;
      padding-right: 30px;
    }

    @include mq(bp-992) {
      grid-template-columns: 1fr 1fr;
      padding-right: 60px;
      grid-column-gap: 15%;
      grid-row-gap: 25px;
    }

    @include mq(bp-1366) {
      grid-row-gap: 35px;
    }
  }

  &__btn {
    text-align: left;
    justify-content: flex-start;
    white-space: initial;
    width: 100%;
  }
}
