.knowledge {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;

    @include mq(bp-992) {
      margin-top: 150px;
    }
  }

  &__box {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;

    @include mq(bp-414, max) {
      flex-direction: column;
    }
  }

  &__item {
    margin-bottom: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include mq(bp-992) {
      @include margin(60, 80, 'bottom');

      width: 50%;
    }

    &:nth-child(even) {
      @include mq(bp-992) {
        @include padding(20, 80, 'left');
      }
    }

    &:nth-child(odd) {
      margin-top: -80px;

      @include mq(bp-992, max) {
        margin-top: 0;
      }

      @include mq(bp-992) {
        @include padding(20, 80, 'right');

        margin-top: -100px;
      }
    }

    &-btn {
      margin-top: 10px;
    }

    &-info {
      @include padding(20, 90, 'top');
      @include padding(20, 90, 'bottom');

      @include flex-column-center;

      z-index: 1;
      position: relative;

      @include mq(bp-992) {
        background: linear-gradient(90deg, transparent 50%, #fff 50%);
      }
    }

    &-title {
      @include fontr(30px, 40px, 700);
      @include margin(15, 25, 'bottom');

      font-family: $base-font;
      line-height: 1.2;
      color: $black;
      text-align: center;
      max-width: 380px;
    }

    &-picture {
      display: flex;

      @include mq(bp-414, max) {
        max-height: 300px;
      }

      @include mq(bp-414) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &-image {
      object-fit: cover;

      @include mq(bp-414) {
        width: 70%;
      }
    }
  }
}
