.btn {
  @include fontr(14px, 16px, 700);
  @include padding(13, 15, 'top');
  @include padding(13, 15, 'bottom');
  @include padding(20, 32, 'left');
  @include padding(20, 32, 'right');

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  font-family: $base-font;

  &--big-font {
    @include fontr(15px, 24px, 700);
  }

  &--big {
    @include padding(14, 16, 'top');
    @include padding(14, 16, 'bottom');
    @include padding(30, 70, 'left');
    @include padding(30, 70, 'right');
  }

  &--black {
    color: $white;
    background-color: $black;

    &::after {
      box-shadow: 0px 0px 30px $black;
    }
  }

  &--white {
    color: $black;
    background-color: $white;

    &::after {
      box-shadow: 0px 0px 30px $white;
    }
  }

  &--cream {
    color: $black;
    background-color: $light-cream;
  }

  &--white,
  &--cream,
  &--black {
    transform: translateZ(0);
    transition: all 0.6s, color 0.3s 0.3s;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      width: 100%;
      background-color: $dark-cream;
      transform: translateX(-100%) translateZ(0);
      opacity: 0;
      z-index: -1;
      transition: all 0.5s;
    }

    &:hover::before {
      opacity: 1;
      transform: translateX(0) translateZ(0);
    }
  }

  &__outline {
    @include padding(13, 15, 'top');
    @include padding(13, 15, 'bottom');
    @include padding(40, 60, 'left');
    @include padding(40, 60, 'right');
    @include tracking(50);

    color: $white;
    border: 1px solid $cream;
    overflow: hidden;
    z-index: 1;
    transform: translateZ(0);
    outline: 1px solid transparent;
    transition: color 0.3s;

    &::before {
      @include pseudo();

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      background-color: $cream;
      transform: translateX(-100%);
      transition: transform 0.5s, opacity 0.5s;
    }

    &:hover {
      color: $blue;
      transition-delay: 0.2s;

      &::before {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &__with-icon {
    color: $white;
    background-color: $black;
    transition: color 0.4s;

    svg {
      @include margin(15, 25, 'left');

      width: 16px;
      height: 16px;
      transition: transform 0.5s;

      * {
        transition: fill 0.5s;
      }
    }

    &:hover {
      color: $cream;

      svg {
        transform: translateX(5px);

        @include mq(bp-768) {
          transform: translateX(8px);
        }

        * {
          fill: $cream;
        }
      }
    }
  }

  &__black-square {
    @include padding(20, 30, 'top');
    @include padding(20, 30, 'bottom');
    @include padding(25, 36, 'left');
    @include padding(25, 46, 'right');
    @include tracking(20);

    color: $black;
    transition: padding 0.3s;

    &--dark-cream {
      background-color: $dark-cream;
    }

    &--white {
      background-color: $white;
    }

    &--gray {
      background-color: $light-gray;
    }

    span {
      @include flex-center;

      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);
      padding: 15px 25px;
      background-color: $black;
      transition: transform 0.4s, opacity 0.5s;
    }

    &:hover {
      @include padding(30, 40, 'left');
      @include padding(20, 42, 'right');

      span {
        opacity: 0;
        transform: translate(100%, -50%);
      }
    }
  }
}
