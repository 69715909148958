.video {
  &__container {
    @include flex-column-center;
  }

  &__content {
    margin-top: 10px;
    display: flex;
    position: relative;
    width: calc(100% + 40px);
    height: 200px;
    margin-left: -20px;
    margin-right: -20px;

    @include mq(bp-414) {
      height: 37vw;
      max-height: 750px;
      min-height: 300px;
      width: 100%;
    }

    @include mq(bp-768) {
      min-height: 500px;
    }
  }

  &__play-btn {
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    height: 100%;
    transition: transform 0.9s, opacity 0.6s;
    cursor: pointer;
    overflow: hidden;

    &.is-disable {
      opacity: 0;
      pointer-events: none;
    }

    &::before {
      @include pseudo();

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(174, 196, 212, 0.73);
    }

    &:hover {
      .video__play-bg {
        filter: blur(2px);
      }
      .video__play-icon {
        opacity: 0.7;

        svg {
          .cls-1 {
            stroke: $cream;
          }
          .cls-2 {
            fill: $cream;
          }
        }
      }
    }
  }

  &__play-icon {
    @include img(40px, 100px, height);
    @include img(40px, 100px, width);
    @include center('both');
    @include flex-center;

    position: absolute;
    z-index: 3;

    svg {
      .cls-1 {
        transition: stroke 0.3s;
      }
      .cls-2 {
        transition: fill 0.3s;
      }
    }
  }

  &__play-bg {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.3s;
  }

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
