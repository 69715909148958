/************************/
/* ~*~ Offer detail ~*~ */
/***********************/
.offer-detail {
    &__row {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 50px;

        @include mq(bp-992) {
            grid-template-columns: 0.4fr 0.6fr;
            grid-gap: 30px;
        }

        @include mq(bp-1366) {
            grid-gap: 40px;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__box {
        @include mq(bp-992, max) {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__name {
        @include mq(bp-992, max) {
            text-align: center;
        }
    }

    &__desc {
        @include mq(bp-992, max) {
            text-align: center;
            max-width: 550px;
        }

        ul li,
        ol li {
            @include fontr(14px, 14px, 400);
            @include margin(10, 20, "bottom");

            padding-left: 18px;
        }

        ul li::before {
            width: 11px;
            height: 11px;
        }
    }

    &__bg {
        display: flex;
        position: relative;
        margin-top: 50px;

        @include mq(bp-992, max) {
            display: none;
        }

        svg {
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

/****************/
/* ~*~ Help~*~ */
/***************/
.help {
    &__text {
        @include flex-column-center;
    }

    &__row {
        @include margin(20, 30, "top");

        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 50px;

        @include mq(bp-414) {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 30px;
        }

        @include mq(bp-768) {
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 40px;
        }

        @include mq(bp-992) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include mq(bp-1280) {
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 50px;
        }
    }

    &__box {
        @include flex-column-center;

        position: relative;
        justify-content: flex-start;

        &-bg {
            &-color {
                @include flex-center;
                @include margin(15, 25, "bottom");

                position: relative;
                max-height: 180px;
                max-width: 180px;

                @include mq(bp-1440) {
                    max-height: 200px;
                    max-width: 200px;
                }

                @include mq(bp-414, max) {
                    max-width: 150px;
                    max-height: 150px;
                }

                svg {
                    width: 100%;
                    height: auto;
                    display: block;

                    * {
                        fill: var(--bg-color);
                    }
                }
            }

            &-img {
                display: block;
                position: absolute;
                z-index: 1;
                pointer-events: none;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                svg {
                    max-height: 160px;
                    max-width: 160px;

                    @include mq(bp-1440) {
                        max-height: 180px;
                        max-width: 180px;
                    }

                    @include mq(bp-414, max) {
                        max-width: 130px;
                        max-height: 130px;
                    }
                }

                svg * {
                    fill: transparent;
                }
            }
        }

        &-title {
            @include fontr(18px, 20px, 700);

            display: flex;
            justify-content: center;
            text-align: center;
            max-width: 180px;
        }
    }
}
