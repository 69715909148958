.pricelist {
  @include padding(30, 95, 'top');
  @include padding(30, 95, 'bottom');
  @include flex-center;

  position: relative;

  &--sm {
    @include padding(10, 15, 'top');
    @include padding(10, 15, 'bottom');
  }

  &--cream {
    background-color: $cream;

    .pricelist__btn::before {
      background-color: $dark-gray;
    }
  }

  &--gray {
    background-color: $gray;
  }

  &-bg {
    &--mobile {
      display: flex;

      @include mq(bp-992) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include mq(bp-992) {
        display: flex;
      }
    }

    svg {
      width: 100%;
    }
  }

  &__btn {
    @include center('both');

    position: absolute;
  }
}
