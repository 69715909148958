.small-header {
  &__container {
    @include flex-column-center;

    @include mq(bp-992, max) {
      @include margin(30, 50, 'top');
    }
  }

  &__row {
    @include padding(40, 80, 'top');
    @include padding(40, 80, 'bottom');
    @include padding(10, 75, 'left');
    @include padding(10, 75, 'right');
    @include flex-column-center;

    background-color: $dark-gray;

    @include mq(bp-414, max) {
      margin-right: -20px;
      margin-left: -20px;
    }

    @include mq(bp-992) {
      @include flex-center;

      padding-right: 0;
      margin-right: 50px;
    }
  }

  &__name {
    color: $white;
    font-style: initial;

    @include mq(bp-992, max) {
      text-align: center;
    }
  }

  &__desc {
    color: $white;
    max-width: 500px;

    @include mq(bp-992, max) {
      margin-bottom: 30px;
      text-align: center;
    }

    @include mq(bp-992) {
      @include margin(30, 75, 'left');
      @include margin(30, 75, 'right');
    }
  }

  &__btn {
    @include mq(bp-992) {
      margin-right: -50px;
    }
  }
}
