.error-page {
  position: relative;
  height: 100vh;

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__img {
    object-fit: cover;
    width: 100%;
  }

  &__wrapper {
    @include flex-column-center;

    position: absolute;
    z-index: 1;
    bottom: 15%;
    right: 10%;

    @include mq(bp-992, max) {
      right: 50%;
      width: 100%;
      bottom: 10%;
      padding-right: 20px;
      padding-left: 20px;
      transform: translateX(50%);
    }
  }

  &__texts {
    @include flex-column-center;
  }

  &__title,
  &__text {
    text-align: center;
  }
}
