/* ------ */
/* Header */
/* ----- */
.training-header {
  &__container {
    @include flex-column-center;
    @include margin(30, 50, 'top');

    position: relative;
  }

  &__title {
    text-align: center;
  }

  &__row {
    @include padding(40, 80, 'top');
    @include padding(10, 75, 'left');
    @include padding(10, 125, 'right');
    @include flex-column-center;

    padding-bottom: 80px;
    background-color: $dark-gray;

    @include mq(bp-414, max) {
      margin-right: -20px;
      margin-left: -20px;
    }

    @include mq(bp-992) {
      @include padding(40, 80, 'bottom');

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  &__name {
    color: $white;
    font-style: initial;

    @include mq(bp-992, max) {
      text-align: center;
    }
  }

  &__desc {
    color: $white;
    max-width: 500px;

    @include mq(bp-992, max) {
      margin-bottom: 30px;
      text-align: center;
    }

    @include mq(bp-992) {
      @include margin(30, 75, 'left');
      @include margin(30, 75, 'right');
    }
  }

  &__bg {
    position: absolute;
    bottom: -120px;
    right: 50%;
    max-width: 120px;
    display: flex;
    transform: translateX(50%);

    @include mq(bp-992) {
      max-width: 11vw;
      bottom: -25%;
      right: 0;
    }

    svg {
      width: 100%;
    }
  }
}

/* ----- */
/* List */
/* ----- */
.training {
  &__text {
    @include flex-column-center;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;

    @include mq(bp-992) {
      margin-top: 120px;
    }
  }

  &__box {
    @include mq(bp-992, max) {
      @include flex-column-center;

      margin: 0 auto;
    }

    @include mq(bp-414, max) {
      width: 100%;
    }
  }

  &__content {
    position: relative;
    display: inline-flex;

    @include mq(bp-1280, max) {
      flex-direction: column;
    }

    @include mq(bp-414, max) {
      width: 100%;
    }
  }

  &__item {
    @include margin(60, 150, 'bottom');

    width: 100%;
    display: flex;
    flex-direction: column;

    @include mq(bp-992) {
      width: 50%;
    }

    &:nth-child(even) {
      @include mq(bp-1280, max) {
        padding-right: 20px;
      }

      @include mq(bp-992, max) {
        padding-right: 0;
      }

      @include mq(bp-992) {
        padding-left: 20px;
        align-items: flex-end;
      }

      .training__content {
        display: flex;
      }
    }

    &:nth-child(odd) {
      @include mq(bp-1280, max) {
        padding-left: 20px;
        margin-top: -80px;
      }

      @include mq(bp-992, max) {
        padding-left: 0;
        margin-top: 0;
      }

      @include mq(bp-992) {
        margin-top: -100px;
        padding-right: 20px;
      }
    }

    &-picture {
      display: inline-flex;
      max-width: 350px;
      max-height: 350px;
      position: relative;

      @include mq(bp-1280, max) {
        order: -1;
      }

      @include mq(bp-414, max) {
        margin-left: -20px;
        margin-right: -20px;
        max-height: 260px;
        max-width: initial;
      }

      @include mq(bp-1920) {
        max-width: 400px;
        max-height: 400px;
      }

      // &::before {
      //   @include pseudo();

      //   width: 100%;
      //   height: 100%;
      //   background-color: #e6bca5;
      //   opacity: 0.1;
      // }
    }

    &-image {
      width: 100%;
      object-fit: cover;
    }

    &-info {
      @include margin(-45, -50, 'bottom');

      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      @include mq(bp-1280, max) {
        margin-bottom: 20px;
        margin-top: 15px;
        align-items: center;
      }
    }

    &-level {
      @include fontr(13px, 15px, 700);

      display: inline-flex;
      padding: 10px 25px;
      color: $black;
      font-family: $second-font;
      background-color: $dark-gray;
      transform: translateY(-50%);

      @include mq(bp-768) {
        padding: 10px 35px;
      }

      @include mq(bp-1280, max) {
        transform: initial;
        position: relative;
      }
    }

    &-title {
      @include fontr(28px, 30px, 700);

      display: inline-flex;
      color: $black;
      top: 50%;
      right: 0;
      text-align: center;
      position: absolute;
      max-width: 300px;
      z-index: 2;
      transform: translate(50%, -50%);

      @include mq(bp-1280, max) {
        justify-content: flex-start;
        position: relative;
        transform: initial;
        top: initial;
        right: initial;
        margin-top: 15px;
        text-align: left;
      }

      @include mq(bp-992, max) {
        justify-content: center;
        text-align: center;
      }
    }

    &-desc {
      @include mq(bp-414, max) {
        text-align: center;
      }
    }

    &-buttons {
      display: inline-flex;
      padding-top: 10px;

      @include mq(bp-414, max) {
        flex-direction: column;
        margin: 0 auto;
      }
    }

    &-btn {
      @include mq(bp-414, max) {
        width: 100%;
      }
    }

    &-price {
      @include fontr(15px, 15px, 700);
      @include padding(13, 15, 'top');
      @include padding(13, 15, 'bottom');

      color: $black;
      font-family: $second-font;
      display: flex;
      align-items: center;
      min-height: 100%;
      padding-right: 35px;
      padding-left: 35px;
      margin-right: 15px;
      border: 1px solid $cream;

      @include mq(bp-414, max) {
        margin-bottom: 15px;
        margin-right: 0;
        min-height: initial;
      }
    }
  }
}
